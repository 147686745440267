import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';
import SVG from '../../utils/svg';
import { toggleClass } from '../../utils/dom-helper';
import {
  smallMobileBreakpoint,
  tabletBreakpoint,
} from '../../utils/responsive-breakpoints';
import ShowingsService from '../../services/showings-service';
import Theater from '../../models/Theater';
import TheaterShowtimesContainer from './theater-showtimes-container';
import TheaterAccordion from './theater-accordion';
import AmenitiesModal from './amenities-modal';

const chevronHeight = 36;
const mobileChevronHeight = 17;
const chevronColor = '#4a4a4a';

class TheaterAccordionContainer extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    hideOnToggle: PropTypes.bool,
    isInTheatersPage: PropTypes.bool,
    onToggleFavoriteStatus: PropTypes.func,
    onTrackProviderClick: PropTypes.func,
    onTrackTheaterClick: PropTypes.func,
    onTrackTheaterFavorite: PropTypes.func,
    setTheaterTrailer: PropTypes.func,
    showtimeAmenities: PropTypes.object.isRequired,
    theater: PropTypes.instanceOf(Theater).isRequired,
    theaterAmenities: PropTypes.object.isRequired,
    theaterIndex: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.theaterAccordionContainerRefs = [];
    this.theaterAccordionSegmentRefs = [];

    this.state = {
      openModal: false,
      providedAmenities: [],
      displayTheaterAmenities: false,
    };

    this.handleToggleFavoriteStatus = this.handleToggleFavoriteStatus.bind(
      this
    );
  }

  openAmenitiesModal = (event, providedAmenities, displayTheaterAmenities) => {
    event.stopPropagation();
    this.setState({
      openModal: true,
      providedAmenities,
      displayTheaterAmenities,
    });
  };

  closeAmenitiesModal = event => {
    event.stopPropagation();
    this.setState({ openModal: false, providedAmenities: [] });
  };

  handleToggleFavoriteStatus() {
    if (this.props.onToggleFavoriteStatus) {
      this.props.onToggleFavoriteStatus(this.props.theater);
    }
  }

  handleVisibility = theaterIndex => {
    const theaterAccordionContainer = this.theaterAccordionContainerRefs[
      theaterIndex
    ];
    const theaterAccordionSegment = this.theaterAccordionSegmentRefs[
      theaterIndex
    ];

    toggleClass(theaterAccordionSegment, 'closed');
    toggleClass(theaterAccordionContainer, 'closed');
  };

  handleTrackProviderClick = providerFormatId => {
    this.props.onTrackProviderClick(this.props.theater.id, providerFormatId);
  };

  isOnlineTicketingAvailable = () => {
    const {
      theater: { movies },
    } = this.props;

    if (movies.length === 0) {
      return true;
    }

    for (let i = 0; i < movies.length; ++i) {
      const { showtimes } = movies[i];
      for (let j = 0; j < showtimes.length; ++j) {
        const { showings } = showtimes[j];
        if (ShowingsService.hasAvailableShowing(showings)) {
          return true;
        }
      }
    }
    return false;
  };

  renderNoTicketingMessage = () => {
    return !this.isOnlineTicketingAvailable() ? (
      <div className="no-ticketing-message">Online Ticketing Unavailable</div>
    ) : null;
  };

  render() {
    const {
      theater,
      theaterIndex,
      setTheaterTrailer,
      isInTheatersPage,
      onTrackTheaterClick,
      onTrackTheaterFavorite,
      showtimeAmenities,
      theaterAmenities,
    } = this.props;
    const {
      openModal,
      providedAmenities,
      displayTheaterAmenities,
    } = this.state;

    const containerClass = this.props.expanded
      ? 'theater-container'
      : 'theater-container closed';
    const accordionClass = this.props.expanded
      ? 'theater-accordion-container'
      : 'theater-accordion-container closed';

    return (
      <div
        className={containerClass}
        ref={elem => (this.theaterAccordionSegmentRefs[theaterIndex] = elem)}
      >
        <div
          className={accordionClass}
          ref={el => (this.theaterAccordionContainerRefs[theaterIndex] = el)}
          onClick={() => this.handleVisibility(theaterIndex)}
        >
          <TheaterAccordion
            theaterAmenities={theaterAmenities}
            theater={theater}
            renderNoTicketingMessage={this.renderNoTicketingMessage}
            onToggleFavoriteStatus={this.handleToggleFavoriteStatus}
            openAmenitiesModal={this.openAmenitiesModal}
            onTrackTheaterClick={onTrackTheaterClick}
            onTrackTheaterFavorite={onTrackTheaterFavorite}
          />
          <Responsive
            minWidth={smallMobileBreakpoint}
            maxWidth={tabletBreakpoint - 1}
          >
            <div className="chevron">
              {SVG.chevron(mobileChevronHeight, chevronColor)}
            </div>
          </Responsive>
          <Responsive minWidth={tabletBreakpoint}>
            <div className="chevron">
              {SVG.chevron(chevronHeight, chevronColor)}
            </div>
          </Responsive>
        </div>
        <div className="theater-movies-container">
          <TheaterShowtimesContainer
            theaterTimeZone={theater.timeZone}
            movies={theater.movies}
            setTheaterTrailer={setTheaterTrailer}
            showtimeAmenities={showtimeAmenities}
            theaterName={theater.name}
            openAmenitiesModal={this.openAmenitiesModal}
            isInTheatersPage={isInTheatersPage}
            onTrackProviderClick={this.handleTrackProviderClick}
          />
        </div>
        <AmenitiesModal
          providedAmenities={providedAmenities}
          openModal={openModal}
          closeModal={this.closeAmenitiesModal}
          theaterName={theater.name}
          displayTheaterAmenities={displayTheaterAmenities}
          theaterAmenities={theaterAmenities}
          showtimeAmenities={showtimeAmenities}
        />
      </div>
    );
  }
}

export default TheaterAccordionContainer;
