import { throttle } from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import {
  bigTabletBreakpoint,
  smallMobileBreakpoint,
} from '../utils/responsive-breakpoints';
import SVG from '../utils/svg';

const SimpleDateSelector = ({ isDisabled, numberOfDates, onDateClick }) => {
  const arrowColor = '#4a4a4a';
  const defaultArrowHeight = 18;
  const disabledArrowColor = '#CCCCCC';

  const addDatesToDatesArray = () => {
    const newDatesArray = [];
    const startingDate = datesArray[datesArray.length - 1];

    for (let i = 1; i <= numberOfDates; i++) {
      newDatesArray.push(
        moment(startingDate)
          .add(i, 'days')
          .format('YYYY-MM-DD')
      );
    }

    setDatesArray(prevDatesArray => [...prevDatesArray, ...newDatesArray]);
  };

  const initialDatesArray = () => {
    const today = moment().format('YYYY-MM-DD');
    const datesArray = [today];

    for (let i = 1; i < numberOfDates; i++) {
      datesArray.push(
        moment(today)
          .add(i, 'days')
          .format('YYYY-MM-DD')
      );
    }

    return datesArray;
  };

  const [datesArray, setDatesArray] = useState(initialDatesArray);
  const [firstDateIndex, setFirstDateIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(datesArray[0]);

  const throttledHandleScrollFunction = throttle(
    event => {
      const scrollWidthLimit = event.target.scrollWidth;
      const viewScrollWidth = event.target.clientWidth;
      const scrollLeft = event.target.scrollLeft;

      if (scrollWidthLimit - viewScrollWidth - scrollLeft < 100) {
        addDatesToDatesArray();
      }
    },
    250,
    { trailing: true }
  );

  const handleArrowClick = direction => () => {
    let index = firstDateIndex;

    if (direction === 'left') {
      if (firstDateIndex < numberOfDates) {
        index -= 1;
      } else {
        index -= numberOfDates;
      }
    } else {
      index += numberOfDates;

      if (index + numberOfDates > datesArray.length - 1) {
        addDatesToDatesArray();
      }
    }

    setFirstDateIndex(index);
  };

  const handleDateClick = date => {
    setSelectedDate(date);
    onDateClick(date);
  };

  const handleMobileScroll = event => {
    event.persist();

    throttledHandleScrollFunction(event);
  };

  const renderDate = date => {
    const className = date === selectedDate ? 'date-tile active' : 'date-tile';
    const day =
      date !== moment().format('YYYY-MM-DD')
        ? moment(date).format('ddd')
        : 'Today';
    const cursorStyle = { cursor: isDisabled ? 'default' : 'pointer' };
    const handleClick = isDisabled ? null : () => handleDateClick(date);

    return (
      <div
        key={date}
        className={className}
        onClick={handleClick}
        style={cursorStyle}
      >
        <div className="date-container">
          <div className="month">
            {moment(date)
              .format('MMM')
              .toUpperCase()}
          </div>
          <div className="day">{moment(date).format('D')}</div>
          <div className="day-of-week">{day}</div>
        </div>
      </div>
    );
  };

  const renderDatesContainer = () => {
    const lastDateIndex = firstDateIndex + numberOfDates;

    return (
      <div className="date-tiles-container">
        {renderLeftArrow()}
        {datesArray.slice(firstDateIndex, lastDateIndex).map(renderDate)}
        {renderRightArrow()}
      </div>
    );
  };

  const renderLeftArrow = () => {
    const inactive = firstDateIndex === 0;
    const cursorStyle = {
      cursor: inactive || isDisabled ? 'default' : 'pointer',
    };
    const handleClick =
      inactive || isDisabled ? null : handleArrowClick('left');

    return (
      <div className="left-arrow" style={cursorStyle} onClick={handleClick}>
        {inactive || isDisabled
          ? SVG.leftArrow(defaultArrowHeight, disabledArrowColor)
          : SVG.leftArrow(defaultArrowHeight, arrowColor)}
      </div>
    );
  };

  const renderRightArrow = () => {
    const cursorStyle = { cursor: isDisabled ? 'default' : 'pointer' };
    const handleClick = isDisabled ? null : handleArrowClick('right');

    return (
      <div className="right-arrow" style={cursorStyle} onClick={handleClick}>
        {isDisabled
          ? SVG.rightArrow(defaultArrowHeight, disabledArrowColor)
          : SVG.rightArrow(defaultArrowHeight, arrowColor)}
      </div>
    );
  };

  const renderMobileDatesContainer = () => {
    const handleScroll = isDisabled ? null : handleMobileScroll;
    const className = isDisabled ? 'disabled-scroll' : 'scroll';

    return (
      <div className="date-tiles-container scroll-wrapper">
        <div className={className} onScroll={handleScroll}>
          {datesArray.map(renderDate)}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Responsive
        minWidth={smallMobileBreakpoint}
        maxWidth={bigTabletBreakpoint - 1}
      >
        <div className="simple-date-selector">
          {renderMobileDatesContainer()}
        </div>
      </Responsive>
      <Responsive minWidth={bigTabletBreakpoint}>
        <div className="simple-date-selector">{renderDatesContainer()}</div>
      </Responsive>
    </Fragment>
  );
};

SimpleDateSelector.propTypes = {
  isDisabled: PropTypes.bool,
  numberOfDates: PropTypes.number,
  onDateClick: PropTypes.func.isRequired,
};

SimpleDateSelector.defaultProps = {
  isDisabled: false,
  numberOfDates: 7,
};

export default SimpleDateSelector;
