const development = {};

const production = {};

const common = {
  noovieAPIKey: process.env.REACT_APP_NOOVIE_API_KEY,
  noovieAPIHost: process.env.REACT_APP_NOOVIE_API_HOST,
  noovieAppKey: process.env.REACT_APP_NOOVIE_APP_KEY,
  noovieConnectHost: process.env.REACT_APP_NOOVIE_CONNECT_HOST,
  noovieCookieDomain: process.env.REACT_APP_NOOVIE_COOKIE_DOMAIN,
  noovieArcadeUrl: process.env.REACT_APP_NOOVIE_ARCADE_URL,
  noovieFMLUrl: process.env.REACT_APP_NOOVIE_FML_URL,
  noovieShuffleUrl: process.env.REACT_APP_NOOVIE_SHUFFLE_URL,
  nNoovie: process.env.REACT_APP_N_NOOVIE,
  slytrunkAPIHost: process.env.REACT_APP_SLYTRUNK_API_HOST,
  gtmId: process.env.REACT_APP_GTM_ID,
  gowatchitAPIHost: process.env.REACT_APP_GOWATCHIT_API_HOST,
  gowatchitAPIKey: process.env.REACT_APP_GOWATCHIT_API_KEY,
  videoAdManifestUrl: process.env.REACT_APP_VIDEO_AD_MANIFEST_URL,
  gowatchitApiHost: process.env.REACT_APP_GOWATCHIT_API_HOST,
};

export default {
  ...(process.env.NODE_ENV === 'production' ? production : development),
  ...common,
};
