import Deparam from 'jquery-deparam';

export default class Browser {
  static getDocument() {
    return document;
  }

  static getWindow() {
    return window;
  }
}

export function getHashParams() {
  return Deparam(window.location.hash.substring(1));
}

export function getQueryParams() {
  return Deparam(window.location.search.substring(1));
}
