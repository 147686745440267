export default class VideoPreviewImage {
  constructor(options) {
    this._aspect_ratio = options.aspect_ratio;
    this._width = options.width;
    this._height = options.height;
    this._format = options.format;
    this._url = options.url;
  }

  get aspectRatio() {
    return this._aspect_ratio;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get format() {
    return this._format;
  }

  get url() {
    return this._url;
  }
}
