import React from 'react';
import PropTypes from 'prop-types';

import SVG from '../../utils/svg';

const CallToAction = ({ callToAction, fill, href, handleClick }) => {
  if (href && handleClick) {
    return (
      <a href={href} onClick={handleClick}>
        <div className="call-to-action">
          {callToAction}
          {SVG.rightArrow(18, fill)}
        </div>
      </a>
    );
  }

  return (
    <div className="call-to-action">
      {callToAction}
      {SVG.rightArrow(18, fill)}
    </div>
  );
};

CallToAction.propTypes = {
  callToAction: PropTypes.string,
  fill: PropTypes.string,
  href: PropTypes.string,
  handleClick: PropTypes.func,
};

export default CallToAction;
