export default class ShowingsService {
  static isAvailableShowing(showing) {
    return showing.urls.length > 0;
  }

  static hasAvailableShowing(showings) {
    for (let i = 0; i < showings.length; ++i) {
      const showing = showings[i];
      return this.isAvailableShowing(showing);
    }

    return false;
  }
}
