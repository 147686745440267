import Movie from './Movie';
import VideoPreviewImage from './VideoPreviewImage';
import VideoVersion from './VideoVersion';
import defaultPreviewImage from '../assets/img/video-poster-no-poster.png';

export default class Video {
  constructor(options = {}) {
    this._id = options.id;
    this._title = options.title;
    this._type = options.type;
    this._position = options.position;
    this._rating = options.rating;
    this._hidden = options.hidden;
    this._image_location_url = options.image_location_url;
    this._listable_type = options.listable_type;
    this._movie = options.movie;

    if (options.preview_images && options.preview_images.length > 0) {
      this._preview_images = options.preview_images.map(
        preview_image => new VideoPreviewImage(preview_image)
      );
    } else {
      this._preview_images = [];
    }

    if (options.versions && options.versions.length > 0) {
      this._versions = options.versions.map(
        version => new VideoVersion(version)
      );
    } else {
      this._versions = [];
    }
  }

  static fromListItem(item) {
    const options = {
      ...item,
      id: item.listable_id,
      movie: new Movie(item.movie),
    };

    return new Video(options);
  }

  isRedband() {
    return this._rating === 'REDBAND';
  }

  isHidden() {
    return this._hidden;
  }

  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get type() {
    return this._type;
  }

  get position() {
    return this._position;
  }

  get rating() {
    return this._rating;
  }

  get previewImages() {
    return this._preview_images;
  }

  get previewImageURL() {
    return this.previewImages.length
      ? this.previewImages[0].url
      : defaultPreviewImage;
  }

  get versions() {
    return this._versions;
  }

  get imageLocationUrl() {
    return this._image_location_url;
  }

  get listableType() {
    return this._listable_type;
  }

  get movie() {
    return this._movie;
  }
}
