import GeoService from './GeoService';
import GeocoderService from './geocoder_service';
import LocalStore from './LocalStore';

export function getLocalStorageLocationParams() {
  const params = {};
  const coordinates = LocalStore.getStoredCoordinates();
  const zipcode = LocalStore.getStoredAddress();

  if (coordinates) {
    params.lat = coordinates.lat;
    params.lng = coordinates.lng;
  } else if (zipcode) {
    params.zipcode = zipcode;
  }

  return params;
}

export function hasLocalStorageLocationParams() {
  const params = getLocalStorageLocationParams();

  return (params.lat && params.lng) || params.zipcode;
}

export function getBrowserLocationParams() {
  // NOTE: geolocation.getCurrentPosition will always fail
  // on non-secure origins, including localhost
  return new Promise((resolve, reject) => {
    GeoService.findCoordinates().then(({ latitude, longitude }) => {
      const params = { lat: latitude, lng: longitude };

      LocalStore.setStoredCoordinates(params);

      GeocoderService.getZipcode(params.lat, params.lng).then(response => {
        if (response.zipcode) {
          const zipcode = response.zipcode;
          LocalStore.setStoredAddress(zipcode);
        } else {
          LocalStore.removeStoredAddress();
        }
      });

      resolve(params);
    }, reject);
  });
}

export function getLocationParams(location = null) {
  return new Promise((resolve, reject) => {
    const params = {};

    if (location) {
      const isZipcode = GeocoderService.checkForZipcode(location);

      if (isZipcode) {
        LocalStore.removeStoredCoordinates();
        LocalStore.setStoredAddress(location);

        params.zipcode = location;

        resolve(params);
      } else {
        GeocoderService.getCoordinates(location).then(data => {
          const { statusDescription, resourceSets } = data;
          const hasResults =
            statusDescription === 'OK' && !!resourceSets[0].resources.length;

          if (hasResults) {
            const { coordinates } = resourceSets[0].resources[0].point;
            const lat = coordinates[0];
            const lng = coordinates[1];

            LocalStore.setStoredCoordinates({ lat, lng });

            GeocoderService.getZipcode(lat, lng).then(response => {
              if (response.zipcode) {
                const zipcode = response.zipcode;
                LocalStore.setStoredAddress(zipcode);
              } else {
                LocalStore.removeStoredAddress();
              }
            });

            params.lat = lat;
            params.lng = lng;

            resolve(params);
          } else {
            LocalStore.removeStoredAddress();
            LocalStore.removeStoredCoordinates();

            reject(new Error(statusDescription));
          }
        });
      }
    } else {
      resolve(getLocalStorageLocationParams());
    }
  });
}
