export default class AmenityService {
  static translateAmenities(amenitiesList, amenityNames) {
    let amenities = amenityNames.map(name => {
      const correspondingAmenity = amenitiesList[name];

      if (!correspondingAmenity) {
        console.warn(`[Amenity] Unable to locate amenity ${name}`);
      }

      return correspondingAmenity;
    });

    amenities = amenities.filter(a => !!a);

    return amenities;
  }
}
