import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';
import classNames from 'classnames';

import List from '../../models/List';
import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';

import ListRowContainer from './ListRowContainer';
import PosterTile from '../cards/PosterTile';

function getPosterRowMinHeight(
  selectedPosterRow,
  posterRowEl,
  setPosterRowMinHeight
) {
  if (selectedPosterRow) {
    setPosterRowMinHeight(posterRowEl.current.clientHeight);
  }
}

function getSectionedTabs(posterRows, setSectionedTabs) {
  const sectionedTabs = [[]];

  for (let i = 0; i < posterRows.items.length; i += 1) {
    const section = sectionedTabs[sectionedTabs.length - 1];
    const posterRow = posterRows.items[i];

    if (section.length < 4) {
      section.push(posterRow);
    } else {
      sectionedTabs.push([posterRow]);
    }
  }

  setSectionedTabs(sectionedTabs);
}

function renderTab(row, selectedRow, onSelect) {
  // TODO: Implement label when API ready
  const isLabeled = false;
  const tabClass = classNames('poster-tab', {
    'selected-tab': selectedRow && selectedRow.id === row.id,
    'labeled-tab': isLabeled,
  });

  return (
    <div key={row.id} className={tabClass} onClick={() => onSelect(row.id)}>
      {isLabeled && <p className="tab-label">{row.annotation}</p>}
      <p className="tab-name">{row.name}</p>
    </div>
  );
}

function renderTabRowDefault(sectionedTabs, selectedRow, onSelect) {
  if (sectionedTabs.length === 0) return null;

  const renderSectionedTabs = section => (
    <div key={section[0].id} className="poster-tab-cell-selector">
      {section.map(row => renderTab(row, selectedRow, onSelect))}
    </div>
  );

  return (
    <ListRowContainer
      cellSelectorClass="poster-tab-cell-selector"
      items={sectionedTabs}
      renderItem={renderSectionedTabs}
    />
  );
}

function renderTabRowMobile(rows, selectedRow, onSelect) {
  const renderSingleTab = row => (
    <div key={row.id} className="poster-tab-cell-selector">
      {renderTab(row, selectedRow, onSelect)}
    </div>
  );

  return (
    <ListRowContainer
      cellSelectorClass="poster-tab-cell-selector"
      items={rows.items}
      renderItem={renderSingleTab}
    />
  );
}

export default function HomePosterRows(props) {
  const {
    posterRows,
    selectedPosterRow,
    onSelect,
    onTrackScroll,
    onTrackClick,
  } = props;
  const [sectionedTabs, setSectionedTabs] = useState([]);
  const [posterRowMinHeight, setPosterRowMinHeight] = useState(0);
  const posterRowEl = useRef(null);
  const minHeight = { minHeight: `${posterRowMinHeight}px` };

  useEffect(() => {
    getPosterRowMinHeight(
      selectedPosterRow,
      posterRowEl,
      setPosterRowMinHeight
    );
  }, [selectedPosterRow]);

  useEffect(() => {
    getSectionedTabs(posterRows, setSectionedTabs);
  }, [posterRows]);

  return (
    <div className="poster-rows-container">
      <Responsive className="poster-row-tabs" minWidth={bigTabletBreakpoint}>
        {renderTabRowDefault(sectionedTabs, selectedPosterRow, onSelect)}
      </Responsive>

      <Responsive
        className="poster-row-tabs"
        maxWidth={bigTabletBreakpoint - 1}
      >
        {renderTabRowMobile(posterRows, selectedPosterRow, onSelect)}
      </Responsive>

      <div ref={posterRowEl} className="poster-row-movies" style={minHeight}>
        {selectedPosterRow && !!selectedPosterRow.movies.length && (
          <ListRowContainer
            cellSelectorClass="poster-movie-cell-selector"
            items={selectedPosterRow.movies || []}
            renderItem={movie => PosterTile(movie, onTrackClick)}
            onChange={onTrackScroll}
          />
        )}
      </div>
    </div>
  );
}

HomePosterRows.propTypes = {
  posterRows: PropTypes.instanceOf(List).isRequired,
  selectedPosterRow: PropTypes.instanceOf(List),
  onSelect: PropTypes.func.isRequired,
  onTrackScroll: PropTypes.func.isRequired,
  onTrackClick: PropTypes.func.isRequired,
};

HomePosterRows.defaultProps = {
  selectedPosterRow: null,
};
