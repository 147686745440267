import Links from './Links';

export default class Pagination {
  constructor(options = {}) {
    this._total_count = options.total_count;
    this._links = new Links(options.links);
  }

  get totalCount() {
    return this._total_count;
  }

  get links() {
    return this._links;
  }
}
