import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Responsive } from 'semantic-ui-react';
import _ from 'lodash';

import SVG from '../../utils/svg';
import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';
import { findElements, addClass, removeClass } from '../../utils/dom-helper';
import AmenityService from '../../services/amenity-service';

const closeIconHeight = 20;
const closeIconFill = '#000000';

class AmenitiesModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    displayTheaterAmenities: PropTypes.bool.isRequired,
    openModal: PropTypes.bool.isRequired,
    providedAmenities: PropTypes.array.isRequired,
    showtimeAmenities: PropTypes.object.isRequired,
    theaterAmenities: PropTypes.object.isRequired,
    theaterName: PropTypes.string.isRequired,
  };

  mainWrapper = findElements('.main-wrapper')[0];

  onMount = () => {
    addClass(document.body, 'amenities-modal-open');
  };

  onUnmount = () => {
    removeClass(document.body, 'amenities-modal-open');
  };

  renderAmenitiesListContainer = providedAmenityNames => {
    const {
      theaterAmenities,
      showtimeAmenities,
      displayTheaterAmenities,
    } = this.props;
    const amenities = displayTheaterAmenities
      ? theaterAmenities
      : showtimeAmenities;
    const providedAmenitiesInfo = AmenityService.translateAmenities(
      amenities,
      providedAmenityNames
    );

    if (_.isEmpty(theaterAmenities)) {
      return null;
    }

    return (
      <div className="amenities-container">
        {providedAmenitiesInfo.map(providedAmenity => (
          <div
            key={providedAmenity.systemName}
            className="amenity-information-container"
          >
            <h1 className="amenity-name">{providedAmenity.displayName}</h1>
            <p className="amenity-description">{providedAmenity.description}</p>
          </div>
        ))}
      </div>
    );
  };

  renderModalContent = () => {
    const { providedAmenities, theaterName } = this.props;

    return (
      <>
        <Modal.Header>
          Amenities
          <div className="amenities-meta-header">{theaterName}</div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {this.renderAmenitiesListContainer(providedAmenities)}
          </Modal.Description>
        </Modal.Content>
      </>
    );
  };

  render() {
    const { openModal, closeModal } = this.props;

    return (
      <>
        <Responsive maxWidth={bigTabletBreakpoint - 1}>
          <Modal
            className="amenities-modal"
            mountNode={this.mainWrapper}
            dimmer={'inverted'}
            closeIcon={SVG.closeIcon(closeIconHeight, closeIconFill)}
            centered
            open={openModal}
            onClose={closeModal}
            onMount={this.onMount}
            onUnmount={this.onUnmount}
          >
            {this.renderModalContent()}
          </Modal>
        </Responsive>

        <Responsive minWidth={bigTabletBreakpoint}>
          <Modal
            className="amenities-modal"
            closeIcon={SVG.closeIcon(closeIconHeight, closeIconFill)}
            centered
            open={openModal}
            onClose={closeModal}
          >
            {this.renderModalContent()}
          </Modal>
        </Responsive>
      </>
    );
  }
}

export default AmenitiesModal;
