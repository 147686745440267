import List from '../List';
import Meta from '../Meta';

export default class ListResponse {
  constructor(response) {
    this._list = new List(response.list);
    this._meta = new Meta(response.meta);
  }

  get list() {
    return this._list;
  }

  // TODO: This can be refactored using an AbstractResponse super class
  // https://github.com/ncmdev/noovie-web/pull/317#discussion_r240354618
  get meta() {
    return this._meta;
  }
}
