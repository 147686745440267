import React from 'react';
import { buildNNoovieLink } from '../../utils/nooviePath';

const PosterTile = (movie, onTrackClick) => {
  const { id, posterUrl, slug, title, genre, pgRating, year } = movie;

  const backgroundImage = { backgroundImage: `url("${posterUrl}")` };
  const redirectPath = buildNNoovieLink(`movies/${slug}`);

  let metaText = null;

  if (genre && pgRating) {
    metaText = `${pgRating} • ${genre}`;
  } else if (genre && !pgRating) {
    metaText = genre;
  } else if (!genre && pgRating) {
    metaText = pgRating;
  } else if (year) {
    metaText = year;
  }

  return (
    <div key={id} className="poster-movie-cell-selector">
      <a
        href={redirectPath}
        onClick={() => {
          onTrackClick(movie);
        }}
      >
        <div className="poster-container" style={backgroundImage} />
        <p className="poster-title">{title}</p>
        <p className="poster-meta">{metaText}</p>
      </a>
    </div>
  );
};

export default PosterTile;
