import ShowingUrl from './ShowingUrl';

export default class Showing {
  constructor(options) {
    this._date = options.date;
    this._time = options.time;

    if (options.urls && options.urls.length > 0) {
      this._urls = options.urls.map(url => new ShowingUrl(url));
    } else {
      this._urls = [];
    }
  }

  get date() {
    return this._date;
  }

  get time() {
    return this._time;
  }

  get urls() {
    return this._urls;
  }
}
