import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import UserService from '../../services/UserService';

import SVG from '../../utils/svg';
import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';
import SlytrunkAPI from '../../models/api/SlytrunkAPI';
import ENV from '../../config/env';
import NavbarContext from '../navigation/navbar-context';

const accountIconHeightDefault = 36;
const accountIconDefaultColor = '#ffffff';
const accountSignedInIconDefaultHeight = 36;

let containerRef = React.createRef();

const SignInRegisterButton = ({ user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loginUser, setLoginUser] = useState(null);
  const { isDarkTheme } = useContext(NavbarContext);

  const handleClickSignedInIcon = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getUserDetails = () => {
    const slytrunkAPI = new SlytrunkAPI(
      ENV.noovieAppKey,
      ENV.slytrunkAPIHost,
      UserService.getUser().accessToken
    );

    slytrunkAPI.getUserMe().then(response => {
      setLoginUser(response.loginUser);
    });
  };

  const onSignInOut = () => {
    if (loginUser) {
      UserService.logOut();
      setLoginUser(null);
    } else {
      UserService.showLogin(getUserDetails);
    }
  };

  const renderLoginIcon = () => (
    <Fragment>
      <Responsive minWidth={bigTabletBreakpoint}>
        <button type="button" className="account-icon" onClick={onSignInOut}>
          {isDarkTheme
            ? SVG.accountIcon(accountIconHeightDefault, accountIconDefaultColor)
            : SVG.accountIcon(accountIconHeightDefault, '#000000')}
          Login
        </button>
      </Responsive>
      <Responsive maxWidth={bigTabletBreakpoint - 1}>
        <button type="button" className="account-icon" onClick={onSignInOut}>
          {isDarkTheme
            ? SVG.accountIcon(accountIconHeightDefault, accountIconDefaultColor)
            : SVG.accountIcon(accountIconHeightDefault, '#000000')}
        </button>
      </Responsive>
    </Fragment>
  );

  const renderUserAccountContainer = () => (
    <Fragment>
      <div className="user-account-default-container">
        <div className="account-signed-in-icon">
          {SVG.accountSignedInIcon(accountSignedInIconDefaultHeight)}
        </div>
        <Responsive
          className="username-container"
          minWidth={bigTabletBreakpoint}
        >
          <div className="username">{loginUser && loginUser.screenName}</div>
        </Responsive>
      </div>
    </Fragment>
  );

  const renderUserAccountSelections = () => (
    <Fragment>
      <div className="user-account-settings">
        <a href={`${ENV.nNoovie}/profile`}>Settings</a>
      </div>
      <button
        type="button"
        className="user-account-log-out"
        onClick={onSignInOut}
      >
        Logout
      </button>
    </Fragment>
  );

  const renderUserAccountModalDropdown = () => (
    <div className="user-account-modal-dropdown">
      <div className="user-account-name-logo">
        <div className="username-container">
          <div className="username">{loginUser && loginUser.screenName}</div>
        </div>
        <div className="account-signed-in-icon">
          {SVG.accountSignedInIcon(accountSignedInIconDefaultHeight)}
        </div>
      </div>
      {renderUserAccountSelections()}
    </div>
  );

  useEffect(() => {
    const handleOutsideClick = e => {
      if (dropdownOpen) {
        if (containerRef && !containerRef.contains(e.target)) {
          setDropdownOpen(false);
        }
      }
    };

    window.addEventListener('mousedown', handleOutsideClick, false);

    if (user) {
      getUserDetails();
    }

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, [dropdownOpen, user]);

  if (!loginUser) {
    return renderLoginIcon();
  }
  return (
    <div
      ref={node => {
        containerRef = node;
      }}
      className={
        isDarkTheme
          ? 'sign-in-register-container'
          : 'sign-in-register-container-light'
      }
    >
      <button
        type="button"
        className="outer-dropdown-container"
        onClick={handleClickSignedInIcon}
      >
        {renderUserAccountContainer()}
      </button>
      {dropdownOpen && (
        <Fragment>
          <Responsive minWidth={bigTabletBreakpoint}>
            <div className="user-account-dropdown">
              {renderUserAccountSelections()}
            </div>
          </Responsive>
          <Responsive maxWidth={bigTabletBreakpoint - 1}>
            {renderUserAccountModalDropdown()}
          </Responsive>
        </Fragment>
      )}
    </div>
  );
};

SignInRegisterButton.propTypes = {
  user: PropTypes.object,
};

export default SignInRegisterButton;
