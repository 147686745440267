import Movie from './Movie';

export default class WeeklyBoxOfficeItem {
  constructor(options = {}) {
    this._movie = Movie.fromListItem(options);
    this._week_number = options.week_number;
    this._weekend_gross = options.weekend_gross;
    this._total_lifetime_gross = options.total_lifetime_gross;
    this._rank = options.rank;
    this._start_date = options.start_date;
    this._end_date = options.end_date;
  }

  get movie() {
    return this._movie;
  }

  get weekNumber() {
    return this._week_number;
  }

  get weekendGross() {
    return this._weekend_gross;
  }

  get totalLifetimeGross() {
    return this._total_lifetime_gross;
  }

  get rank() {
    return this._rank;
  }

  get startDate() {
    return this._start_date;
  }

  get endDate() {
    return this._end_date;
  }
}
