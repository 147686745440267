import React from 'react';
import PropTypes from 'prop-types';

import SectionTitle from '../SectionTitle';
import HomeSearch from '../search';
import TheaterMoviesContainer from './theater-movies-container';
import Theater from '../../models/Theater';

export default function FavoriteTheaters(props) {
  const {
    favoriteTheaters,
    onTrackTheaterClick,
    onTrackTheaterFavorite,
    onTrackProviderClick,
  } = props;

  const favoriteTheatersError = (
    <div className="favorite-theaters-error">
      <p>
        Personalize your Noovie experience by adding your favorite theaters!
      </p>

      <p>
        To add a theater to your favorites, just click the heart icon next to
        the theater name.
      </p>

      <div className="search-container">
        <HomeSearch isInTheatersPage />
      </div>
    </div>
  );

  return (
    <div className="favorite-theaters showtimes-container">
      <SectionTitle titleText="My Theaters" />

      {favoriteTheaters.length > 0 ? (
        <TheaterMoviesContainer
          customError={favoriteTheatersError}
          theaters={favoriteTheaters}
          onTrackTheaterClick={onTrackTheaterClick}
          onTrackTheaterFavorite={onTrackTheaterFavorite}
          onTrackProviderClick={onTrackProviderClick}
          isInTheatersPage
        />
      ) : (
        favoriteTheatersError
      )}
    </div>
  );
}

FavoriteTheaters.propTypes = {
  favoriteTheaters: PropTypes.arrayOf(PropTypes.instanceOf(Theater)).isRequired,
  onTrackTheaterClick: PropTypes.func,
  onTrackTheaterFavorite: PropTypes.func,
  onTrackProviderClick: PropTypes.func,
};

FavoriteTheaters.defaultProps = {
  onTrackTheaterClick: null,
  onTrackTheaterFavorite: null,
  onTrackProviderClick: null,
};
