import React from 'react';
import PropTypes from 'prop-types';

const EditorialModuleBanner = ({ text }) => (
  <div className="editorial-module-banner-container">
    <div className="editorial-module-banner">
      <p>{text}</p>
    </div>
  </div>
);

EditorialModuleBanner.propTypes = {
  text: PropTypes.string,
};

export default EditorialModuleBanner;
