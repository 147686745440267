import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import SVG from '../../utils/svg';
import { FILTER_TYPES, PROVIDER_FORMAT_LOGOS } from '../../utils/constants';
import PrimeVideo from './prime-video-rent-own.png';

const defaultShowCount = 9;

const FiltersModal = ({
  filters,
  filterOptions,
  bulkApplyFilters,
  selectedFilters,
  setSelectedFilters,
  isSelected,
  handleFilterSelect,
  handleClose,
  renderYearSlider,
}) => {
  const [open, setOpen] = useState(false);

  const [showAllPlatforms, setShowAllPlatforms] = useState(false);
  const [showAllGenres, setShowAllGenres] = useState(false);

  const filtersEnabled = () => {
    for (const filter in filters) {
      if (filters[filter].length) return true;
    }
    return false;
  };

  const handleApplyClick = () => {
    bulkApplyFilters(selectedFilters);

    setOpen(false);
  };

  const handleClearClick = () => {
    setSelectedFilters({
      platforms: [],
      genres: [],
      years: [],
      ratings: [],
    });
  };

  const handleCloseClick = () => {
    let shouldApplyClear = true;
    for (const filter in selectedFilters) {
      if (selectedFilters[filter].length) {
        shouldApplyClear = false;
        break;
      }
    }
    // If the filters have been cleared,
    // apply the clear after the modal is closed
    if (shouldApplyClear) {
      bulkApplyFilters(selectedFilters);
    } else {
      handleClearClick();
    }

    setOpen(false);
  };

  const renderPlatform = (platform, index) => {
    let platformClass = 'platform-button';
    if (
      isSelected(platform, FILTER_TYPES.PLATFORM) ||
      selectedFilters.platforms.length === 0
    ) {
      platformClass += ' selected';
    }

    const showCount = showAllPlatforms ? 999 : defaultShowCount;

    if (index < showCount) {
      // No svg available for Prime Video
      if (platform === 'Prime Video') {
        return (
          <button
            key={platform}
            className={platformClass}
            type="button"
            onClick={() => handleFilterSelect(platform, FILTER_TYPES.PLATFORM)}
          >
            <img src={PrimeVideo} alt="" />
          </button>
        );
      } else {
        return (
          <button
            key={platform}
            className={`platform-button ${platformClass}`}
            type="button"
            onClick={() => handleFilterSelect(platform, FILTER_TYPES.PLATFORM)}
          >
            {PROVIDER_FORMAT_LOGOS[platform]}
          </button>
        );
      }
    }
  };

  const renderPlatformSection = () => {
    if (!filterOptions.platforms.length) return null;
    return (
      <div className="modal-section">
        <header className="section-header">Platform</header>

        <div className="section-grid">
          {filterOptions.platforms.map(renderPlatform)}
        </div>

        {filterOptions.platforms.length > defaultShowCount && (
          <button
            className="show-more-less"
            type="button"
            onClick={() => setShowAllPlatforms(!showAllPlatforms)}
          >
            {showAllPlatforms ? 'Less' : 'More'} Platforms
          </button>
        )}
      </div>
    );
  };

  const renderGenre = (genre, index) => {
    let genreClass = 'genre-button';
    if (
      isSelected(genre, FILTER_TYPES.GENRE) ||
      selectedFilters.genres.length === 0
    ) {
      genreClass += ' selected';
    }

    const showCount = showAllGenres ? 999 : defaultShowCount;

    if (index < showCount)
      return (
        <button
          key={genre}
          className={genreClass}
          type="button"
          onClick={() => handleFilterSelect(genre, FILTER_TYPES.GENRE)}
        >
          {genre}
        </button>
      );
  };

  const renderGenreSection = () => {
    if (!filterOptions.genres.length) return null;
    return (
      <div className="modal-section">
        <header className="section-header">Genre</header>

        <div className="section-grid">
          {filterOptions.genres.map(renderGenre)}
        </div>

        {filterOptions.genres.length > defaultShowCount && (
          <button
            className="show-more-less"
            type="button"
            onClick={() => setShowAllGenres(!showAllGenres)}
          >
            {showAllGenres ? 'Less' : 'More'} Genres
          </button>
        )}
      </div>
    );
  };

  const renderYearSection = () => {
    if (!filterOptions.years.length) return null;
    return (
      <div className="modal-section">
        <header className="section-header">Release Year</header>

        {renderYearSlider()}
      </div>
    );
  };

  const renderRating = rating => {
    let ratingClass = 'rating-button';
    if (
      isSelected(rating, FILTER_TYPES.RATING) ||
      selectedFilters.ratings.length === 0
    ) {
      ratingClass += ' selected';
    }

    return (
      <button
        key={rating}
        className={ratingClass}
        type="button"
        onClick={() => handleFilterSelect(rating, FILTER_TYPES.RATING)}
      >
        {rating}
      </button>
    );
  };

  const renderRatingSection = () => {
    if (!filterOptions.ratings.length) return null;
    return (
      <div className="modal-section">
        <header className="section-header">MPAA Rating</header>

        <div className="section-grid">
          {filterOptions.ratings.map(renderRating)}
        </div>
      </div>
    );
  };

  const renderButtonsContainer = () => (
    <div className="buttons-container">
      <button className="apply-button" type="button" onClick={handleApplyClick}>
        Apply
      </button>
      <button className="clear-button" type="button" onClick={handleClearClick}>
        Clear
      </button>
    </div>
  );

  return (
    <Modal
      id="filters-modal"
      trigger={
        <button
          className="modal-trigger"
          type="button"
          onClick={() => setOpen(true)}
        >
          {filtersEnabled() && (
            <span className="filters-on">{SVG.onIcon()}</span>
          )}
          {SVG.filtersIcon()}
        </button>
      }
      open={open}
      closeIcon={
        <button className="close-icon" type="button">
          {SVG.modalCloseIcon()}
        </button>
      }
      onClose={handleCloseClick}
    >
      <Modal.Content>
        <header className="modal-header">Filter Movies</header>

        {renderPlatformSection()}
        {renderGenreSection()}
        {renderYearSlider() && renderYearSection()}
        {renderRatingSection()}

        {renderButtonsContainer()}
      </Modal.Content>
    </Modal>
  );
};

FiltersModal.propTypes = {
  filters: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  bulkApplyFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleFilterSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  renderYearSlider: PropTypes.func.isRequired,
};

export default FiltersModal;
