import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import MetaTruncate from '../meta-truncate';

import { buildNNoovieLink } from '../../utils/nooviePath';
import {
  bigTabletBreakpoint,
  tabletBreakpoint,
} from '../../utils/responsive-breakpoints';

const MovieCard = ({ movie, index, onTrackClick }) => {
  const redirectPath = buildNNoovieLink(movie);

  const formatInTheatersCard = movie => {
    const { pgRating, genre } = movie;
    let metaString = '';

    if (pgRating) {
      metaString += pgRating;
    }

    if (pgRating && genre) {
      metaString += ' ・ ';
    }

    if (genre) {
      metaString += genre;
    }
    return metaString;
  };

  const renderMovieCardImageContainer = movie => (
    <div
      className="movie-card-image-container"
      style={{ backgroundImage: `url("${movie.imageLocationUrl}")` }}
    />
  );

  const renderMovieCardDescription = movie => (
    <div className="movie-card-description-container">
      <MetaTruncate lines={3} className="movie-card-description">
        {movie.description}
      </MetaTruncate>
    </div>
  );

  const renderMovieCardDetailsContainer = movie => (
    <div className="movie-card-details-container">
      <MetaTruncate lines={2} className="movie-card-title">
        {movie.title}
      </MetaTruncate>

      <div className="movie-card-meta">{formatInTheatersCard(movie)}</div>

      {renderMovieCardDescription(movie)}
    </div>
  );

  const renderMobileMovieCardDetailsContainer = (movie, index) => {
    // For mobile designs we 'alternate' the alignment of these cards:
    const optionalClass = index % 2 === 0 ? 'left-aligned' : '';
    return (
      <div className={`movie-card-details-container ${optionalClass}`}>
        <MetaTruncate lines={2} className="movie-card-title">
          {movie.title}
        </MetaTruncate>

        <div className="movie-card-meta">{formatInTheatersCard(movie)}</div>
      </div>
    );
  };

  return (
    <a href={redirectPath} onClick={onTrackClick}>
      {/* 325 - 724px layout: */}
      {/* ------------- */}
      <Responsive className="movie-card" maxWidth={tabletBreakpoint - 1}>
        {index % 2 === 0 ? (
          <Fragment>
            <div className="movie-card-image-and-details-container">
              {renderMobileMovieCardDetailsContainer(movie, index)}
              {renderMovieCardImageContainer(movie)}
            </div>
            {renderMovieCardDescription(movie)}
          </Fragment>
        ) : (
          <Fragment>
            <div className="movie-card-image-and-details-container">
              {renderMovieCardImageContainer(movie)}
              {renderMobileMovieCardDetailsContainer(movie, index)}
            </div>
            {renderMovieCardDescription(movie)}
          </Fragment>
        )}
      </Responsive>

      {/* 725 - 1023px layout: */}
      {/* ------------- */}
      <Responsive
        className="movie-card"
        minWidth={tabletBreakpoint}
        maxWidth={bigTabletBreakpoint - 1}
      >
        {index % 2 !== 0 ? (
          <Fragment>
            {renderMovieCardImageContainer(movie)}
            {renderMovieCardDetailsContainer(movie)}
          </Fragment>
        ) : (
          <Fragment>
            {renderMovieCardDetailsContainer(movie)}
            {renderMovieCardImageContainer(movie)}
          </Fragment>
        )}
      </Responsive>

      {/* 1024px+ layout: */}
      {/* ------------- */}
      <Responsive className="movie-card" minWidth={bigTabletBreakpoint}>
        {renderMovieCardImageContainer(movie)}
        {renderMovieCardDetailsContainer(movie)}
      </Responsive>
    </a>
  );
};

MovieCard.propTypes = {
  index: PropTypes.number,
  onTrackClick: PropTypes.func,
  movie: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    pgRating: PropTypes.string,
    genre: PropTypes.string,
    imageLocationUrl: PropTypes.string,
  }),
};

export default MovieCard;
