export default class ShowingUrl {
  constructor(options) {
    this._url = options.url;
    this._provider_format_name = options.provider_format_name;
    this._provider_format_id = options.provider_format_id;
  }

  get url() {
    return this._url;
  }

  get providerFormatName() {
    return this._provider_format_name;
  }

  get providerFormatId() {
    return this._provider_format_id;
  }
}
