import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import InTheaters from './pages/InTheaters';
import Streaming from './pages/Streaming';
import MovieGrid from './pages/MovieGrid';
import NavbarAd from './components/navigation/NavbarAd';
import Navbar from './components/navigation/navbar';
import Footer from './components/Footer';

class MainRoutes extends Component {
  componentWillMount() {}

  render() {
    return (
      <Fragment>
        <NavbarAd />
        <div className="app-container">
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/index.html" component={Home} />
            <Route exact path="/in-theaters" component={InTheaters} />
            <Route path="/in-theaters/:id" component={MovieGrid} />
            <Route exact path="/streaming" component={Streaming} />
            <Route path="/streaming/:id" component={MovieGrid} />
          </Switch>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default MainRoutes;
