import Carousel from '../Carousel';

export default class CarouselResponse {
  constructor(response) {
    this._carousel = new Carousel(response.carousel);
  }

  get carousel() {
    return this._carousel;
  }
}
