import Cookies from 'js-cookie';

import User from '../models/User';
import ENV from '../config/env';

export default class UserService {
  static getUser() {
    const authToken = Cookies.get('auth_token');
    const accessToken = Cookies.get('access_token');
    const isLoggedIn = !!authToken && !!accessToken;

    if (!isLoggedIn) return null;

    const options = {
      auth_token: authToken,
      access_token: accessToken,
    };

    return new User(options);
  }

  static logOut() {
    Cookies.remove('auth_token', {
      domain: ENV.noovieCookieDomain,
    });
    Cookies.remove('access_token', {
      domain: ENV.noovieCookieDomain,
    });

    window.NoovieConnect.logout();
  }

  static showLogin = async callback => {
    return window.NoovieConnect.login(response => {
      Cookies.set('auth_token', response.auth_token, {
        expires: 1,
        domain: ENV.noovieCookieDomain,
      });
      Cookies.set('access_token', response.access_token, {
        expires: 1,
        domain: ENV.noovieCookieDomain,
      });
      callback();
    });
  };
}
