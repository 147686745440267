import { getQueryParams } from '../utils/browser';

const MEDIUM = 'website';
const UNSET_CONTEXT_TYPE = 'unset';
const UNSET_CONTEXT_ID = -1;

export default class TrackingService {
  static getReferringListId() {
    const queryParams = getQueryParams();
    return queryParams.gwi_list_id;
  }

  static defaultOptions() {
    let defaultOptions = {
      medium: MEDIUM,
    };

    const referringListId = this.getReferringListId();

    if (referringListId) {
      defaultOptions = {
        listId: referringListId,
      };
    }

    return defaultOptions;
  }

  static fireTransaction(event, options) {
    let parameters = this.defaultOptions();

    parameters = {
      ...parameters,
      ...options,
    };
    // eslint-disable-next-line
    gwi('send', event, parameters);
  }

  static trackProviderClick(
    contextType,
    contextId,
    providerFormatId,
    options = {}
  ) {
    const parameters = {
      contextType,
      contextId,
      providerFormatId,
      ...options,
    };

    this.fireTransaction('provider_click', parameters);
  }

  static trackCallToActionClick(contextType, contextId, options = {}) {
    const parameters = {
      contextType,
      contextId,
      ...options,
    };

    this.fireTransaction('cta_click', parameters);
  }

  static trackListImpression(contextId, options = {}) {
    const parameters = {
      contextType: 'List',
      contextId,
      listId: contextId,
      ...options,
    };

    this.fireTransaction('list_impression', parameters);
  }

  static trackListItemClick(contextId, listItemId, options = {}) {
    const parameters = {
      contextType: 'List',
      contextId,
      listId: contextId,
      listItemId,
      ...options,
    };

    this.fireTransaction('list_item_click', parameters);
  }

  static trackListScroll(contextId, options = {}) {
    const parameters = {
      contextType: 'List',
      contextId,
      listId: contextId,
      ...options,
    };

    this.fireTransaction('list_scroll', parameters);
  }

  static trackPageView(
    mediumContext,
    contextTypeParam,
    contextIdParam,
    options = {}
  ) {
    const contextType = contextTypeParam || UNSET_CONTEXT_TYPE;
    const contextId = contextIdParam || UNSET_CONTEXT_ID;

    const parameters = {
      contextType,
      contextId,
      mediumContext,
      ...options,
    };

    this.fireTransaction('page_view', parameters);
  }

  static trackSimpleDateSelectorInteraction(
    contextTypeParam,
    contextIdParam,
    options = {}
  ) {
    const contextType = contextTypeParam || UNSET_CONTEXT_TYPE;
    const contextId = contextIdParam || UNSET_CONTEXT_ID;

    const parameters = {
      contextType,
      contextId,
      ...options,
    };

    this.fireTransaction('simple_date_selector_interaction', parameters);
  }

  static trackTheaterClick(contextId, options = {}) {
    const parameters = {
      contextType: 'Theater',
      contextId,
      theaterId: contextId,
      ...options,
    };

    this.fireTransaction('theater_click', parameters);
  }

  static trackTheaterFavorite(contextId, options = {}) {
    const parameters = {
      contextType: 'Theater',
      contextId,
      theaterId: contextId,
      ...options,
    };

    this.fireTransaction('theater_favorite', parameters);
  }
}
