import Theater from '../Theater';

export default class TheatersDetailResponse {
  constructor(response) {
    this._available_dates = response.available_dates;
    this._next_page_url = response.meta
      ? response.meta.pagination.links.next_page_url
      : null;

    if (response.theaters && response.theaters.length > 0) {
      this._theaters = response.theaters.map(theater => new Theater(theater));
    } else {
      this._theaters = [];
    }
  }

  get availableDates() {
    return this._available_dates;
  }

  get nextPageUrl() {
    return this._next_page_url;
  }

  get theaters() {
    return this._theaters;
  }
}
