import Theater from '../Theater';

export default class TheatersListResponse {
  constructor(response) {
    if (response.theaters && response.theaters.length > 0) {
      this._theaters = response.theaters.map(theater => new Theater(theater));
    } else {
      this._theaters = [];
    }
  }

  get theaters() {
    return this._theaters;
  }
}
