import React, { Component } from 'react';
import NavbarContext from './navbar-context';

export default class NavbarProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isDarkTheme: true,
      // eslint-disable-next-line react/no-unused-state
      setDarkTheme: this.setDarkTheme,
    };
  }

  setDarkTheme = isDarkTheme => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      isDarkTheme,
    });
  };

  render() {
    const { children } = this.props;
    return (
      <NavbarContext.Provider value={this.state}>
        {children}
      </NavbarContext.Provider>
    );
  }
}
