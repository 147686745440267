import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { Responsive } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';

import Video from '../../models/Video';
import VideoExperienceService from '../../services/VideoExperienceService';
import {
  tabletBreakpoint,
  desktopBreakpoint,
  bigTabletBreakpoint,
} from '../../utils/responsive-breakpoints';
import SVG from '../../utils/svg';

import ListRowContainer from '../list-row/ListRowContainer';

const MEDIUM_CONTEXT = 'home_page';

const handleThumbnailClickMobile = videoId => {
  VideoExperienceService.openModalForVideo(videoId, MEDIUM_CONTEXT);
};

const handleThumbnailClick = (
  event,
  pointer,
  cellElement,
  cellIndex,
  videos
) => {
  if (cellIndex === undefined) return;
  const videoId = videos[cellIndex].id;
  VideoExperienceService.openModalForVideo(videoId, MEDIUM_CONTEXT);
};

const renderHotTrailersItem = item => {
  const backgroundImage = {
    backgroundImage: `url("${item.imageLocationUrl}")`,
  };
  const handleClick = isMobile
    ? () => handleThumbnailClickMobile(item.id)
    : null;

  return (
    <div
      key={item.id}
      className="hot-trailer-cell-selector"
      onClick={handleClick}
    >
      <div className="trailer-container" style={backgroundImage}>
        <div className="thumbnail-container">
          <div className="play-icon">
            <Responsive className="icon" minWidth={desktopBreakpoint}>
              {SVG.trailerTrayPlayIconXLarge()}
            </Responsive>

            <Responsive
              className="icon"
              minWidth={bigTabletBreakpoint}
              maxWidth={desktopBreakpoint - 1}
            >
              {SVG.trailerTrayPlayIconLarge()}
            </Responsive>

            <Responsive className="icon" maxWidth={bigTabletBreakpoint - 1}>
              {SVG.trailerTrayPlayIconMedium()}
            </Responsive>
          </div>
        </div>
      </div>

      <div className="trailer-context-container">
        <div>
          <h1>
            <Truncate lines={1}>{item.movie.title}</Truncate>
          </h1>

          <Responsive as="p" maxWidth={tabletBreakpoint - 1}>
            <Truncate lines={5}>{item.movie.shortDescription}</Truncate>
          </Responsive>

          <Responsive
            as="p"
            minWidth={tabletBreakpoint}
            maxWidth={desktopBreakpoint - 1}
          >
            <Truncate lines={4}>{item.movie.shortDescription}</Truncate>
          </Responsive>

          <Responsive as="p" minWidth={desktopBreakpoint}>
            <Truncate lines={3}>{item.movie.shortDescription}</Truncate>
          </Responsive>
        </div>
      </div>
    </div>
  );
};

const getCellGroups = () => {
  const initialBrowserWidth = window.innerWidth;
  if (initialBrowserWidth >= 1024) {
    return 3;
  }
  return 2;
};

const TrailerTray = ({ videos }) => (
  <div className="trailer-tray">
    <div className="trailer-tray-header">
      <h1>
        <span className="hot-bold">Hot </span>
        Trailers
      </h1>
      <div className="trailer-tray-divider" />
    </div>

    <ListRowContainer
      items={videos}
      cellSelectorClass="hot-trailer-cell-selector"
      groupCells={getCellGroups()}
      renderItem={renderHotTrailersItem}
      onStaticClick={(event, pointer, cellElement, cellIndex) => {
        return handleThumbnailClick(
          event,
          pointer,
          cellElement,
          cellIndex,
          videos
        );
      }}
    />
  </div>
);

TrailerTray.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.instanceOf(Video)).isRequired,
};

export default TrailerTray;
