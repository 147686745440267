import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';
import _ from 'lodash';

import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';
import SVG from '../../utils/svg';
import Theater from '../../models/Theater';

import FavoriteTheaterToggle from './favorite-theater-toggle';

const heartHeight = 24;
const mapMarkerHeight = 15;

export default class TheaterAccordion extends Component {
  static propTypes = {
    theaterAmenities: PropTypes.object,
    theater: PropTypes.instanceOf(Theater).isRequired,
    openAmenitiesModal: PropTypes.func.isRequired,
    renderNoTicketingMessage: PropTypes.func.isRequired,
    onToggleFavoriteStatus: PropTypes.func,
    isFavorite: PropTypes.bool,
    onTrackTheaterClick: PropTypes.func,
    onTrackTheaterFavorite: PropTypes.func,
  };

  static defaultProps = {
    isFavorite: false,
  };

  handleToggleFavoriteStatus = () => {
    if (this.props.onToggleFavoriteStatus) {
      this.props.onToggleFavoriteStatus(this.props.theater);
    }
  };

  handleTheaterClick = (e, theater) => {
    e.stopPropagation();

    this.props.onTrackTheaterClick(theater.id);
  };

  handleTrackTheaterFavorite = isFavorite => {
    this.props.onTrackTheaterFavorite(this.props.theater.id, isFavorite);
  };

  openGoogleMaps = (e, theater) => {
    e.stopPropagation();

    const queryParam = `${theater.name}, ${theater.address}, ${theater.city}, ${theater.state}`;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        queryParam
      )}`
    );
  };

  renderViewAmenities = providedAmenityNames => {
    const { openAmenitiesModal } = this.props;
    const displayTheaterAmenities = true;

    if (_.isEmpty(providedAmenityNames)) {
      return null;
    }

    return (
      <div
        className="view-amenities"
        onClick={event =>
          openAmenitiesModal(
            event,
            providedAmenityNames,
            displayTheaterAmenities
          )
        }
      >
        &nbsp; • &nbsp;View Amenities
      </div>
    );
  };

  render() {
    const { theater, renderNoTicketingMessage } = this.props;

    return (
      <div className="theater-accordion">
        <div className="theater-name-container">
          <a
            className="theater-name"
            href={theater.url}
            onClick={e => this.handleTheaterClick(e, theater)}
          >
            {theater.name}
          </a>

          <FavoriteTheaterToggle
            height={heartHeight}
            isFavorite={theater.isFavorite}
            theaterId={theater.id}
            onToggleFavoriteStatus={this.handleToggleFavoriteStatus}
            onTrackTheaterFavorite={this.handleTrackTheaterFavorite}
          />
        </div>

        <Responsive
          minWidth={bigTabletBreakpoint}
          className="theater-address-container"
        >
          <div className="theater-address">
            {`${theater.address}, ${theater.city}, ${theater.state} ${theater.zipcode}`}
            {theater.distance && <span>&nbsp; • &nbsp;</span>}
          </div>

          {theater.distance && (
            <div
              className="theater-distance-container"
              onClick={e => this.openGoogleMaps(e, theater)}
            >
              <div className="map-marker">{SVG.mapMarker(mapMarkerHeight)}</div>
              {`${theater.distance} mi`}
            </div>
          )}

          {this.renderViewAmenities(theater.amenities)}
        </Responsive>

        <Responsive
          maxWidth={bigTabletBreakpoint - 1}
          className="theater-address-container"
        >
          <div className="theater-address">
            {`${theater.address}, ${theater.city}, ${theater.state} ${theater.zipcode}`}
          </div>

          <div>
            {theater.distance && (
              <div
                className="theater-distance-container"
                onClick={e => this.openGoogleMaps(e, theater)}
              >
                <div className="map-marker">
                  {SVG.mapMarker(mapMarkerHeight)}
                </div>
                {`${theater.distance} mi`}
              </div>
            )}
            {this.renderViewAmenities(theater.amenities)}
          </div>
        </Responsive>

        {renderNoTicketingMessage()}
      </div>
    );
  }
}
