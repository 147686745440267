import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import moment from 'moment';

import { buildNNoovieLink } from '../../utils/nooviePath';

import CardLabel from './CardLabel';

// TODO: Figure out truncate logic

const SmallCard = ({ content }) => {
  const {
    description,
    type,
    image,
    publishedAt,
    name,
    title,
    updatedAt,
  } = content;
  const redirectPath = buildNNoovieLink(content);

  return (
    <a className="small-card" href={redirectPath}>
      <div
        className="small-card-image-container"
        style={{ backgroundImage: `url("${image}")` }}
      >
        <CardLabel label={type} />
      </div>

      <div className="small-card-details-container">
        <div className="small-card-published-date">
          {moment(publishedAt || updatedAt)
            .utc()
            .format('MMMM D, YYYY')}
        </div>

        <div className="small-card-title">
          <Truncate lines={4}>{name || title}</Truncate>
        </div>

        <div className="small-card-description">{description}</div>
      </div>
    </a>
  );
};

SmallCard.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    publishedAt: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

export default SmallCard;
