import React, { useEffect } from 'react';
import { parse } from 'query-string';
import { useParams } from 'react-router-dom';
import VideoExperienceService from '../services/VideoExperienceService';

const MEDIUM_CONTEXT = 'home_page';

const VideoDetail = () => {
  const { videoId } = useParams();

  const loadVideoExperienceModal = () => {
    if (window.GWIMason && window.GWIMason.openExperienceModal) {
      const parsed = parse(window.location.search);
      const redirectTo = parsed.redirect_to || '/';

      VideoExperienceService.openModalForVideo(videoId, MEDIUM_CONTEXT, {
        redirectTo,
      });
    } else {
      setTimeout(loadVideoExperienceModal, 100);
    }
  };

  useEffect(() => {
    loadVideoExperienceModal();
  });

  return <div />;
};

export default VideoDetail;
