import jquery from 'jquery';
import ENV from '../config/env';
import { getQueryParams } from './browser';
import { QUERY_PARAM_WHITELIST } from './constants';

const hasWhitelistQueryParams = queryParam => {
  for (let i = 0; i < QUERY_PARAM_WHITELIST.length; i++) {
    if (queryParam.indexOf(QUERY_PARAM_WHITELIST[i]) >= 0) {
      return true;
    }
  }

  return false;
};

export const nooviePath = (path, params = {}) => {
  const existingParams = getQueryParams();
  const whitelistParams = {};

  Object.keys(existingParams).forEach(key => {
    if (hasWhitelistQueryParams(key) && path.indexOf(key) < 0) {
      whitelistParams[key] = existingParams[key];
    }
  });

  const finalParams = { ...whitelistParams, ...params };

  if (Object.keys(finalParams).length) {
    const delimiter = path.indexOf('?') >= 0 ? '&' : '?';
    return `${path}${delimiter}${jquery.param(finalParams)}`;
  }

  return path;
};

export function buildNNoovieLink(content) {
  let nNoovieLink = ENV.nNoovie;
  let contentPath = content;
  let path = '';

  if (content.type === 'Movie') {
    contentPath = 'movies';
    path = content.slug;
  }

  if (content.type === 'Story') {
    contentPath = 'stories';
    path = content.slug ? `${content.slug}-${content.id}` : content.id;
  }

  if (content.type === 'Gallery') {
    contentPath = 'photo-galleries';
    path = content.slug ? `${content.slug}-${content.id}` : content.id;
  }

  if (content.type === 'List') {
    contentPath = 'lists';
    path = content.id;
  }

  if (content.type === 'Playlist') {
    contentPath = 'playlists';
    path = content.id;
  }

  if (contentPath) nNoovieLink += `/${contentPath}`;
  if (path) nNoovieLink += `/${path}`;

  return nNoovieLink;
}

// buildRedirect(posterRow)
// Used with the anchor tags on the "See More" CallToAction.
// Redirects user to appropriate Streaming Grid. Example:
// noovie.com/streaming/coming-soon-movies---movies-page-4
export const buildRedirect = posterRow => {
  if (posterRow) {
    const { name, id } = posterRow;
    const formattedName = name
      .toLowerCase()
      .split(' ')
      .join('-');

    const path = window.location.pathname;
    const queryParams = window.location.search;

    let formattedUrl = `${path}/${formattedName}-${id}`;

    if (queryParams) {
      formattedUrl += queryParams;
    }

    return formattedUrl;
  }
  return null;
};
