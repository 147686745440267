import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MovieService from '../../services/movie-service';
import ShowingsService from '../../services/showings-service';
import AmenityService from '../../services/amenity-service';
import Showtime from '../../models/Showtime';
import AvailableShowtimeContainer from './available-showtime-container';

export default class TheaterShowtimes extends Component {
  static propTypes = {
    showtimeAmenities: PropTypes.object.isRequired,
    showtimes: PropTypes.arrayOf(PropTypes.instanceOf(Showtime)).isRequired,
    openAmenitiesModal: PropTypes.func.isRequired,
    theaterTimeZone: PropTypes.string,
    onTrackProviderClick: PropTypes.func,
  };

  renderUnavailableShowtime = showing => (
    <div className="unavailable-showtime">
      {MovieService.formatMovieTime(showing.time)}
    </div>
  );

  renderAmenityIcon = (amenitySystemNames, selectedAmenity) => {
    const { openAmenitiesModal } = this.props;
    const displayTheaterAmenities = false;

    switch (selectedAmenity.systemName) {
      case 'RESERVED_SEATING':
        return (
          <div
            key={selectedAmenity.systemName}
            className="showtime-amenity"
            onClick={event =>
              openAmenitiesModal(
                event,
                amenitySystemNames,
                displayTheaterAmenities
              )
            }
          >
            {selectedAmenity.displayName}
          </div>
        );
      case 'WHEELCHAIR_ACCESSIBLE':
        return (
          <div
            key={selectedAmenity.systemName}
            className="showtime-amenity"
            onClick={event =>
              openAmenitiesModal(
                event,
                amenitySystemNames,
                displayTheaterAmenities
              )
            }
          >
            {selectedAmenity.displayName}
          </div>
        );
      case 'CLOSED_CAPTION':
        return (
          <div
            key={selectedAmenity.systemName}
            className="showtime-amenity"
            onClick={event =>
              openAmenitiesModal(
                event,
                amenitySystemNames,
                displayTheaterAmenities
              )
            }
          >
            {selectedAmenity.displayName}
          </div>
        );
      case 'LISTENING_DEVICES':
        return (
          <div
            key={selectedAmenity.systemName}
            className="showtime-amenity"
            onClick={event =>
              openAmenitiesModal(
                event,
                amenitySystemNames,
                displayTheaterAmenities
              )
            }
          >
            {selectedAmenity.displayName}
          </div>
        );
      default:
        return (
          <div
            key={selectedAmenity.systemName}
            className="showtime-amenity"
            onClick={event =>
              openAmenitiesModal(
                event,
                amenitySystemNames,
                displayTheaterAmenities
              )
            }
          >
            {selectedAmenity.displayName}
          </div>
        );
    }
  };

  renderShowtimeAmenities = showtime => {
    const { showtimeAmenities } = this.props;
    const { amenities } = showtime;
    const providedAmenities = AmenityService.translateAmenities(
      showtimeAmenities,
      amenities
    );

    if (_.isEmpty(showtimeAmenities)) {
      return null;
    }

    return (
      <>
        {providedAmenities.map((providedAmenity, index) => (
          <span key={providedAmenity.id} className="showtime-amenity-container">
            {this.renderAmenityIcon(amenities, providedAmenity)}
            {index !== providedAmenities.length - 1 && (
              <span className="dot">•</span>
            )}
          </span>
        ))}
      </>
    );
  };

  render() {
    const { showtimes, theaterTimeZone, onTrackProviderClick } = this.props;

    return (
      <>
        {showtimes &&
          showtimes.map((showtime, showtimeIndex) => {
            return (
              <div key={showtimeIndex} className="movie-showtime-container">
                <div className="showtime-detail-container">
                  {MovieService.displayExperiences(showtime.experiences)}

                  {showtime.amenities.length ? (
                    <span className="dot">•</span>
                  ) : null}
                  {showtime.amenities.length > 0
                    ? this.renderShowtimeAmenities(showtime)
                    : null}
                </div>
                <div className="showtime-row">
                  {showtime.showings &&
                    showtime.showings.map((showing, showingIndex) => {
                      return (
                        <div key={showingIndex} className="showtime-container">
                          {ShowingsService.isAvailableShowing(showing) ? (
                            MovieService.isPastTime(
                              showing,
                              theaterTimeZone
                            ) ? (
                              this.renderUnavailableShowtime(showing)
                            ) : (
                              <AvailableShowtimeContainer
                                showing={showing}
                                onTrackProviderClick={onTrackProviderClick}
                              />
                            )
                          ) : (
                            this.renderUnavailableShowtime(showing)
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </>
    );
  }
}
