import React from 'react';
import PropTypes from 'prop-types';

import EditorialModuleBanner from './EditorialModuleBanner';
import CallToAction from '../links/call-to-action';

const EditorialModule = ({ handleClick, item }) => (
  <a href={item.callToActionUrl} onClick={handleClick}>
    <div className="editorial-module">
      <div
        className="editorial-module-image-container"
        style={{ backgroundImage: `url("${item.imageUrl}")` }}
      >
        {item.bannerText && <EditorialModuleBanner text={item.bannerText} />}
      </div>

      <div className="title">{item.title}</div>

      {item.description && (
        <div className="description">{item.description}</div>
      )}

      {item.callToActionText && item.callToActionUrl && (
        <CallToAction callToAction={item.callToActionText} />
      )}
    </div>
  </a>
);

EditorialModule.propTypes = {
  handleClick: PropTypes.func,
  item: PropTypes.object,
};

export default EditorialModule;
