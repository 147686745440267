import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import MovieService from '../services/movie-service';
import CallToAction from './links/call-to-action';

import { buildNNoovieLink } from '../utils/nooviePath';
import SVG from '../utils/svg';
import VideoExperienceService from '../services/VideoExperienceService';

import {
  tabletBreakpoint,
  bigTabletBreakpoint,
} from '../utils/responsive-breakpoints';

// renderSpotlightHeader(spotlight)
// Builds and returns the section of the Spotlights
// that has Title, Movie name, genre, etc.:
function renderSpotlightHeader(spotlight) {
  const { movie } = spotlight;
  const spotlightTitle = spotlight.title.toUpperCase();

  const movieDetails = MovieService.formatMovieDetails(movie);

  return (
    <Fragment>
      <p className="spotlight-header">{`SPOTLIGHT: ${spotlightTitle}`}</p>
      <a href={buildNNoovieLink(movie)}>
        <h1 className="spotlight-movie-title">{movie.title}</h1>
      </a>
      <p className="spotlight-movie-info">{movieDetails}</p>
    </Fragment>
  );
}

// handleTrailerClick(movieId)
// Calls Masons when user clicks trailer:
function handleTrailerClick(movieId) {
  try {
    VideoExperienceService.openModalForMovie(movieId);
  } catch (error) {
    console.log(error);
  }
}

// renderSmallImage(movieId, imageUrl, isMobile)
// ** For 375px, 720px **
// Renders Iconic Image Url into a div's backgroundImage.
// Optional parameter 'isMobile' sets the className to either
// 'mobile-spotlight-photo' or 'spotlight-photo':
function renderSmallImage(movieId, imageUrl, isMobile = false) {
  const optionalClass = isMobile ? 'mobile-' : '';

  return (
    <div
      className={`${optionalClass}spotlight-photo`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="spotlight-overlay-items">
        <div className="spotlight-opacity-cover" />
        <div
          className="play-icon-container"
          onClick={() => handleTrailerClick(movieId)}
        >
          <div className="play-icon">{SVG.trailerTrayPlayIconXLarge(60)}</div>
          <p className="watch-trailer">Watch the Trailer</p>
        </div>
      </div>
    </div>
  );
}

// renderLargeImage(movieId, imageUrl, align)
// ** For 1024px, 1440px, and up **
// Renders Iconic Image Url into a div with
// the ability to alternate alignment left/right:
function renderLargeImage(movieId, imageUrl, align) {
  return (
    <div className={`spotlight-photo-holder-big-tablet ${align}-aligned`}>
      <div
        className="spotlight-photo-oversized"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className="spotlight-overlay-items">
          <div className="spotlight-opacity-cover" />
          <div
            className="play-icon-container"
            onClick={() => handleTrailerClick(movieId)}
          >
            <div className="play-icon">{SVG.trailerTrayPlayIconXLarge(96)}</div>
            <p className="watch-trailer">Watch the Trailer</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const SpotlightContainer = props => {
  const { align, callToActionText, onCallToActionClick, spotlight } = props;
  const { movie } = spotlight;

  // Spotlight API endpoint doesn't return Movie.type.
  // Needed for VideoService to open the VideoExp:
  if (movie) {
    movie.type = 'Movie';
  }

  return (
    <div className="spotlight-container">
      {/* 375px Spotlight: */}
      {/* ---------------------------------------------------------------------------- */}
      {movie && (
        <Responsive maxWidth={tabletBreakpoint - 1}>
          {renderSpotlightHeader(spotlight)}

          <div className="spotlight-photo-holder-tablet">
            {renderSmallImage(movie.id, movie.iconic_image_url, true)}
          </div>

          <a
            className="mobile-call-to-action-text"
            href={buildNNoovieLink(movie)}
            onClick={onCallToActionClick}
          >
            <CallToAction callToAction={callToActionText} fill="#ffffff" />
          </a>
        </Responsive>
      )}

      {/* 720px Spotlight: */}
      {/* ----------------------------------------------------------------------------*/}
      {movie && (
        <Responsive
          minWidth={tabletBreakpoint}
          maxWidth={bigTabletBreakpoint - 1}
          className="tablet-container"
        >
          <div className="spotlight-content-left">
            {renderSpotlightHeader(spotlight)}
            <a
              className="tablet-call-to-action-text"
              href={buildNNoovieLink(movie)}
              onClick={onCallToActionClick}
            >
              <CallToAction callToAction={callToActionText} fill="#ffffff" />
            </a>
          </div>
          <div className="spotlight-photo-holder-tablet">
            {renderSmallImage(movie.id, movie.iconic_image_url)}
          </div>
        </Responsive>
      )}

      {/* 1024px & 1440px+ Spotlight: */}
      {/* ----------------------------------------------------------------------------*/}
      {movie && (
        <Responsive
          minWidth={bigTabletBreakpoint}
          className={`big-tablet-container ${align}-aligned`}
        >
          {align === 'left' &&
            renderLargeImage(movie.id, movie.iconic_image_url, align)}
          <div className="spotlight-content-left">
            {renderSpotlightHeader(spotlight)}
            <a
              className="tablet-call-to-action-text"
              href={buildNNoovieLink(movie)}
              onClick={onCallToActionClick}
            >
              <CallToAction callToAction={callToActionText} fill="#ffffff" />
            </a>
          </div>
          {align === 'right' &&
            renderLargeImage(movie.id, movie.iconic_image_url, align)}
        </Responsive>
      )}
    </div>
  );
};

SpotlightContainer.propTypes = {
  align: PropTypes.string,
  callToActionText: PropTypes.string.isRequired,
  onCallToActionClick: PropTypes.func,
  spotlight: PropTypes.object.isRequired,
};

SpotlightContainer.defaultProps = {
  align: 'left',
  onCallToActionClick: null,
};

export default SpotlightContainer;
