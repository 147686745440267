import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import StreamingCard from '../cards/StreamingCard';
import SectionTitle from '../SectionTitle';

import { desktopBreakpoint } from '../../utils/responsive-breakpoints';

const renderHeroCard = movie => (
  <StreamingCard key={movie.id} movie={movie} isHeroCard />
);

const renderCard = movie => <StreamingCard key={movie.id} movie={movie} />;

// LargePosterRow(posterRowMovies):
// 375px, 720px, 1024px:
//          {hero card}
// {bottom card}  {bottom card}
// 1440+px:
//                 {hero card}
// {bottom card}  {bottom card} {bottom card}
const LargePosterRow = props => {
  const { posterRow } = props;
  const posterRowName = posterRow.name;
  const posterRowMovies = posterRow.movies;

  return (
    <Fragment>
      <SectionTitle titleText={posterRowName} />
      <div className="top-card">{renderHeroCard(posterRowMovies[0])}</div>

      {/* Render 2 "bottom cards" on mobile, tablet, and bigTablet LPRs */}
      <Responsive
        as="div"
        className="bottom-cards"
        maxWidth={desktopBreakpoint - 1}
      >
        {posterRowMovies.slice(1, 3).map(movie => renderCard(movie))}
      </Responsive>
      {/* Render 3 "bottom cards" on desktop LargePosterRows */}
      <Responsive
        as="div"
        className="bottom-cards"
        minWidth={desktopBreakpoint}
      >
        {posterRowMovies.slice(1, 4).map(movie => renderCard(movie))}
      </Responsive>
    </Fragment>
  );
};

LargePosterRow.propTypes = {
  posterRow: PropTypes.object.isRequired,
};

export default LargePosterRow;
