import Browser from './browser';

export function whenDocumentReady(func) {
  const doc = Browser.getDocument();

  if (doc.readyState !== 'loading') {
    func();
  } else {
    doc.addEventListener('DOMContentLoaded', func);
  }
}

export function findElements(selector) {
  const nodeList = Browser.getDocument().querySelectorAll(selector);

  // Since some browsers differ on what methods a nodeList supports,
  // always return an array instead of a nodeList.

  // A NodeList in Firefox also does not have a forEach method. Some browsers
  // don't have a map function. Convert it to an array first to ensure compatibility.
  return Array.prototype.slice.call(nodeList);
}

export function createElement(element) {
  return Browser.getDocument().createElement(element);
}

export function addAttribute(element, key, value) {
  element.setAttribute(key, value);
}

export function hasClass(element, className) {
  if (element.classList) {
    return element.classList.contains(className);
  } else {
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(
      element.className
    );
  }
}

export function addClass(element, className) {
  if (element.classList) {
    element.classList.add(className);
  } else {
    /* eslint-disable no-param-reassign */
    element.className += ` ${className}`;
    /* eslint-enable no-param-reassign */
  }
}

export function removeClass(element, className) {
  if (element.classList) {
    element.classList.remove(className);
  } else {
    element.className = element.className.replace(
      new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
      ' '
    );
  }
}

export function addEventListener(element, eventName, eventHandler) {
  element.addEventListener(eventName, eventHandler);
}

export function removeEventListener(element, eventName, eventHandler) {
  element.removeEventListener(eventName, eventHandler);
}

export function onMessage(element, eventHandler) {
  addEventListener(element, 'message', eventHandler);
}

export function getAbsoluteOffset(value, maxValue) {
  let absoluteValue;

  if (typeof value === 'string' && value.indexOf('%') !== -1) {
    absoluteValue = (maxValue * parseFloat(value)) / 100;
  } else {
    absoluteValue = parseInt(value, 10);
  }

  return (maxValue - absoluteValue) / 2;
}

export function getScrollTop() {
  const win = Browser.getWindow();
  const doc = Browser.getDocument();

  // http://stackoverflow.com/a/11193613
  return win.pageYOffset !== undefined
    ? win.pageYOffset
    : (doc.documentElement || doc.body.parentNode || doc.body).scrollTop;
}

export function getWindowSize() {
  const win = Browser.getWindow();

  return {
    width: win.innerWidth,
    height: win.innerHeight,
  };
}

export function addStyle(element, styleHash) {
  const keys = Object.keys(styleHash);

  keys.forEach(key => {
    const value = styleHash[key];

    /* eslint-disable no-param-reassign */
    element.style[key] = value;
    /* eslint-enable no-param-reassign */
  });
}

export function toggleClass(element, className) {
  if (hasClass(element, className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}
