import UserMeResponse from '../responses/UserMeResponse';
import AbstractAPI from './AbstractAPI';

export default class SlytrunkAPI extends AbstractAPI {
  constructor(appKey, apiHost, accessToken = null) {
    super();

    this.appKey = appKey;
    this.apiHost = apiHost;
    this.accessToken = accessToken;
  }

  getUserMe() {
    const params = {
      appKey: this.appKey,
      accessToken: this.accessToken,
    };

    return this.get('/v1/user/me', params).then(
      response => new UserMeResponse(response)
    );
  }

  get(url, params) {
    return this.query('GET', url, params);
  }

  query(method, url, params = {}) {
    const finalParams = { ...params };

    let finalUrl = `${this.apiHost}${url}`;

    if (method === 'GET' && !!Object.keys(finalParams).length) {
      finalUrl = SlytrunkAPI.appendQueryParams(finalUrl, finalParams);
    }

    const fetchOptions = {
      method,
    };

    return fetch(finalUrl, fetchOptions)
      .then(SlytrunkAPI.checkStatus)
      .then(SlytrunkAPI.parseJson);
  }
}
