import React from 'react';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

export default function MetaTruncate(props) {
  const { lines, onTruncate, children, className } = props;
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Truncate
        lines={lines}
        trimWhitespace
        onTruncate={onTruncate}
        className={className}
      >
        {children}
      </Truncate>
    </>
  );
}
MetaTruncate.defaultProps = {
  onTruncate: () => {},
  className: '',
  children: '',
};

MetaTruncate.propTypes = {
  lines: PropTypes.number.isRequired,
  onTruncate: PropTypes.func,
  children: PropTypes.string,
  className: PropTypes.string,
};
