import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Responsive, Sidebar, Menu, Icon } from 'semantic-ui-react';
import SVG from '../../utils/svg';
import {
  tabletBreakpoint,
  bigTabletBreakpoint,
  desktopBreakpoint,
} from '../../utils/responsive-breakpoints';
import NavbarAppsDropdown from './navbar-apps-dropdown';
import SignInRegisterButton from '../buttons/sign-in-register-button';
import SearchBar from '../search/search-bar';
import UserService from '../../services/UserService';
import { buildNNoovieLink } from '../../utils/nooviePath';
import NavbarContext from './navbar-context';

const iconDarkColor = '#000000';
const logoHeightDefault = 38;
const logoHeightMobile = 26;
const productIconHeightDefault = 36;
const productIconDefaultColor = '#ffffff';

const menuIconHeightMobile = 36;
const menuIconDefaultColor = '#ffffff';
const menuIconMobileColor = '#4a4a4a';
const searchIconHeightDefault = 36;
const searchIconColorDefault = '#ffffff';

const navbarContainerRef = React.createRef();

const NavbarContainer = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isAppsDropdown, setIsAppsDropdown] = useState(false);
  const { isDarkTheme } = useContext(NavbarContext);

  const closeMobileMenu = () => setIsMobileMenu(false);

  const openAppsDropdown = event => {
    event.stopPropagation();
    closeMobileMenu();
    setIsAppsDropdown(!isAppsDropdown);
  };
  const hideSearch = () => setIsSearching(false);

  const closeAppsDropdown = () => setIsAppsDropdown(false);

  const openMobileMenu = event => {
    event.stopPropagation();
    closeAppsDropdown();
    setIsMobileMenu(true);
  };

  const showSearch = event => {
    event.stopPropagation();
    setIsSearching(true);
    closeAppsDropdown();
  };

  const renderLogo = () => (
    <header className="logo">
      <a href="/">
        <Responsive minWidth={bigTabletBreakpoint}>
          {isDarkTheme
            ? SVG.whiteNoovieCom(logoHeightDefault)
            : SVG.whiteRedNoovieCom(logoHeightDefault)}
        </Responsive>

        <Responsive maxWidth={bigTabletBreakpoint - 1}>
          {isDarkTheme
            ? SVG.whiteNoovieCom(logoHeightMobile)
            : SVG.whiteRedNoovieCom(logoHeightMobile)}
        </Responsive>
      </a>
    </header>
  );

  const renderSearchBar = () => (
    <div className={isDarkTheme ? 'search-bar' : 'search-bar-light'}>
      <SearchBar hideSearch={hideSearch} isSearching={isSearching} />
    </div>
  );

  const renderDesktopNavbar = () => {
    const user = UserService.getUser();

    return (
      <Fragment>
        <div className="left-navigation-container">
          {renderLogo()}
          <div className="left-navigation-links">
            {process.env.NODE_ENV === 'development' ? (
              <a className="in-theaters-route /in-theaters" href="/in-theaters">
                In Theaters
              </a>
            ) : (
              <a
                className="in-theaters-route /movies"
                href={buildNNoovieLink('movies')}
              >
                Movies
              </a>
            )}
            <a className="streaming-route /streaming" href="/streaming">
              Streaming
            </a>
            <a
              className="showtimes-route /showtimes"
              href={buildNNoovieLink('showtimes')}
            >
              Showtimes
            </a>
            <a
              className="trailers-route /trailers"
              href={buildNNoovieLink('trailers')}
            >
              Trailers
            </a>
          </div>
        </div>

        <div className="menu-container">
          <Responsive
            className="right-navigation-container"
            minWidth={bigTabletBreakpoint}
            maxWidth={desktopBreakpoint - 1}
          >
            {!isSearching ? (
              <Fragment>
                <button
                  type="button"
                  className="search-button"
                  onClick={showSearch}
                >
                  {SVG.navbarSearchIcon(
                    searchIconHeightDefault,
                    isDarkTheme ? searchIconColorDefault : iconDarkColor
                  )}
                  Search
                </button>
                <div className="apps-icon-container" onClick={openAppsDropdown}>
                  <Icon className="apps-icon">
                    {SVG.productIcon(
                      productIconHeightDefault,
                      isDarkTheme ? productIconDefaultColor : iconDarkColor
                    )}
                    {isAppsDropdown ? <NavbarAppsDropdown /> : null}
                  </Icon>
                  Apps
                </div>
                <SignInRegisterButton user={user} />
              </Fragment>
            ) : (
              renderSearchBar()
            )}
          </Responsive>
          <Responsive
            className="right-navigation-container"
            minWidth={desktopBreakpoint}
          >
            {!isSearching ? (
              <Fragment>
                <button
                  type="button"
                  className="search-button"
                  onClick={showSearch}
                >
                  {SVG.navbarSearchIcon(
                    searchIconHeightDefault,
                    isDarkTheme ? searchIconColorDefault : iconDarkColor
                  )}
                  Search
                </button>
                <div className="apps-icon-container" onClick={openAppsDropdown}>
                  <Icon className="apps-icon">
                    {SVG.productIcon(
                      productIconHeightDefault,
                      isDarkTheme ? productIconDefaultColor : iconDarkColor
                    )}
                    {isAppsDropdown ? <NavbarAppsDropdown /> : null}
                  </Icon>
                  Apps
                </div>
                <SignInRegisterButton user={user} />
              </Fragment>
            ) : (
              <Fragment>
                {renderSearchBar()}
                <div className="apps-icon-container" onClick={openAppsDropdown}>
                  <Icon className="apps-icon">
                    {SVG.productIcon(
                      productIconHeightDefault,
                      isDarkTheme ? productIconDefaultColor : iconDarkColor
                    )}
                    {isAppsDropdown ? <NavbarAppsDropdown /> : null}
                  </Icon>
                  Apps
                </div>
                <SignInRegisterButton user={user} />
              </Fragment>
            )}
          </Responsive>
        </div>
      </Fragment>
    );
  };

  const renderMobileMenu = () => (
    <Sidebar
      className="mobile-side-nav"
      as={Menu}
      animation="push"
      visible={isMobileMenu}
      vertical
      secondary
    >
      <Menu.Header>
        <Menu.Item className="menu-icon" onClick={closeMobileMenu}>
          {SVG.menuIcon(menuIconHeightMobile, menuIconMobileColor)}
        </Menu.Item>
        <Menu.Item className="header-text">
          {SVG.noovieCom(logoHeightMobile)}
        </Menu.Item>
      </Menu.Header>

      <Menu.Menu>
        {process.env.NODE_ENV === 'development' ? (
          <Menu.Item className="/in-theaters" href="/in-theaters">
            In Theaters
          </Menu.Item>
        ) : (
          <Menu.Item className="/movies" href={buildNNoovieLink('movies')}>
            Movies
          </Menu.Item>
        )}
        <Menu.Item className="/streaming" href="/streaming">
          Streaming
        </Menu.Item>
        <Menu.Item className="/trailers" href={buildNNoovieLink('trailers')}>
          Trailers
        </Menu.Item>
        <Menu.Item className="/showtimes" href={buildNNoovieLink('showtimes')}>
          Showtimes
        </Menu.Item>
      </Menu.Menu>
    </Sidebar>
  );

  const renderMobileNavbar = () => {
    const user = UserService.getUser();

    return (
      <Fragment>
        <div className="mobile-left-nav-container">
          <button type="button" className="menu-icon" onClick={openMobileMenu}>
            {isDarkTheme
              ? SVG.menuIcon(menuIconHeightMobile, menuIconDefaultColor)
              : SVG.blackMenuIcon(menuIconHeightMobile, menuIconDefaultColor)}
          </button>
          {renderLogo()}
        </div>

        <Responsive
          className="mobile-right-nav-container"
          maxWidth={tabletBreakpoint - 1}
        >
          {!isSearching ? (
            <Fragment>
              <button
                type="button"
                className="search-button"
                onClick={showSearch}
              >
                {isDarkTheme
                  ? SVG.navbarSearchIcon(
                      searchIconHeightDefault,
                      searchIconColorDefault
                    )
                  : SVG.navbarSearchIcon(
                      searchIconHeightDefault,
                      iconDarkColor
                    )}
              </button>
              <div className="apps-icon-container" onClick={openAppsDropdown}>
                <Icon className="apps-icon">
                  {SVG.productIcon(
                    productIconHeightDefault,
                    isDarkTheme ? productIconDefaultColor : iconDarkColor
                  )}
                  {isAppsDropdown ? <NavbarAppsDropdown /> : null}
                </Icon>
              </div>
              <SignInRegisterButton user={user} />
            </Fragment>
          ) : (
            renderSearchBar()
          )}
        </Responsive>
        <Responsive
          className="mobile-right-nav-container"
          minWidth={tabletBreakpoint}
          maxWidth={bigTabletBreakpoint - 1}
        >
          {!isSearching ? (
            <Fragment>
              <button
                type="button"
                className="search-button"
                onClick={showSearch}
              >
                {SVG.navbarSearchIcon(
                  searchIconHeightDefault,
                  isDarkTheme ? searchIconColorDefault : iconDarkColor
                )}
              </button>
              <div className="apps-icon-container" onClick={openAppsDropdown}>
                <Icon className="apps-icon">
                  {SVG.productIcon(
                    productIconHeightDefault,
                    isDarkTheme ? productIconDefaultColor : iconDarkColor
                  )}
                  {isAppsDropdown ? <NavbarAppsDropdown /> : null}
                </Icon>
              </div>
              <SignInRegisterButton user={user} />
            </Fragment>
          ) : (
            <Fragment>
              {renderSearchBar()}
              <div className="apps-icon-container" onClick={openAppsDropdown}>
                <Icon className="apps-icon">
                  {SVG.productIcon(
                    productIconHeightDefault,
                    isDarkTheme ? productIconDefaultColor : iconDarkColor
                  )}
                  {isAppsDropdown ? <NavbarAppsDropdown /> : null}
                </Icon>
              </div>
              <SignInRegisterButton user={user} />
            </Fragment>
          )}
        </Responsive>
        {renderMobileMenu()}
      </Fragment>
    );
  };

  useEffect(() => {
    const closeAllDropdowns = () => {
      hideSearch();
      closeMobileMenu();
      closeAppsDropdown();
    };

    const handleOutsideClick = e => {
      if (isMobileMenu || isSearching || isAppsDropdown) {
        if (!navbarContainerRef.current.contains(e.target)) {
          closeAllDropdowns();
        }
      }
    };

    window.addEventListener('mousedown', handleOutsideClick, false);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, [isAppsDropdown, isMobileMenu, isSearching]);

  return (
    <nav
      ref={navbarContainerRef}
      className={isDarkTheme ? 'navbar-container' : 'navbar-container-light'}
    >
      <Responsive className="navbar" minWidth={bigTabletBreakpoint}>
        {renderDesktopNavbar()}
      </Responsive>
      <Responsive className="navbar" maxWidth={bigTabletBreakpoint - 1}>
        {renderMobileNavbar()}
      </Responsive>
    </nav>
  );
};

export default NavbarContainer;
