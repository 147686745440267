export default class FeaturedModule {
  constructor(options = {}) {
    this._id = options.id;
    this._title = options.title;
    this._description = options.description;
    this._image_url = options.image_url;
    this._banner_text = options.banner_text;
    this._call_to_action_text = options.call_to_action_text;
    this._call_to_action_url = options.call_to_action_url;
    this._updated_at = options.updated_at;
    this._type = options.type;
  }

  static fromListItem(item) {
    const options = {
      ...item,
      id: item.listable_id,
      description: item.annotation,
      image_url: item.image_location_url,
      call_to_action_url: item.url,
      updated_at: item.date,
      type: item.listable_type,
    };

    return new FeaturedModule(options);
  }

  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get imageUrl() {
    return this._image_url;
  }

  get bannerText() {
    return this._banner_text;
  }

  get callToActionText() {
    return this._call_to_action_text;
  }

  get callToActionUrl() {
    return this._call_to_action_url;
  }

  get updatedAt() {
    return this._updated_at;
  }

  get type() {
    return this._type;
  }
}
