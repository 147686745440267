import Owner from './Owner';
import Movie from './Movie';
import Playlist from './Playlist';
import Story from './Story';
import Gallery from './Gallery';
import Video from './Video';
import FeaturedModule from './FeaturedModule';

export default class List {
  constructor(options = {}) {
    this._id = options.id;
    this._list_id = options.list_id;
    this._list_item_id = options.list_item_id;
    this._name = options.name;
    this._description = options.description;
    this._image = options.image;
    this._movie_count = options.movie_count;
    this._published_at = options.published_at;
    this._updated_at = options.updated_at;
    this._owner = new Owner(options.owner);
    this._public = options.public;
    this._ranked = options.ranked;
    this._url = options.url;
    this._type = options.type;

    if (options.items && options.items.length > 0) {
      this._items = options.items.map(item => {
        switch (item.listable_type) {
          case 'Movie':
            return Movie.fromListItem(item);
          case 'List':
            return List.fromListItem(item);
          case 'Playlist':
            return Playlist.fromListItem(item);
          case 'Story':
            return Story.fromListItem(item);
          case 'Gallery':
            return Gallery.fromListItem(item);
          case 'Video':
            return Video.fromListItem(item);
          case 'FeaturedModule':
            return FeaturedModule.fromListItem(item);
          default:
            return Movie.fromListItem(item);
        }
      });
    } else {
      this._items = [];
    }

    if (options.movie_posters && options.movie_posters.length > 0) {
      this._movie_posters = options.movie_posters.map(
        poster => poster.poster_url
      );
    } else {
      this._movie_posters = [];
    }

    if (options.movies && options.movies.length > 0) {
      this._movies = options.movies.map(item => Movie.fromListItem(item));
    } else {
      this._movies = [];
    }
  }

  static fromListItem(item) {
    const options = {
      ...item,
      id: item.listable_id,
      list_item_id: item.id,
      image: item.image_location_url,
      name: item.title,
      description: item.annotation,
      updated_at: item.date,
      type: item.listable_type,
    };

    return new List(options);
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get movieCount() {
    return this._movie_count;
  }

  get moviePosters() {
    return this._movie_posters;
  }

  get publishedAt() {
    return this._published_at;
  }

  get updatedAt() {
    return this._updated_at;
  }

  get owner() {
    return this._owner;
  }

  get isPublic() {
    return this._public;
  }

  get isRanked() {
    return this._ranked;
  }

  get url() {
    return this._url;
  }

  get image() {
    return this._image;
  }

  get items() {
    return this._items;
  }

  set items(newItems) {
    this._items = newItems;
  }

  get type() {
    return this._type;
  }

  get listId() {
    return this._list_id;
  }

  get movies() {
    return this._movies;
  }
}
