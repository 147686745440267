import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import SectionTitle from '../SectionTitle';
import MovieCard from '../cards/MovieCard';
import CallToAction from '../links/call-to-action';

import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';
import { buildRedirect } from '../../utils/nooviePath';
import TrackingService from '../../services/tracking_service';

const MEDIUM_CONTEXT = 'in_theaters_page';
const defaultTrackingOptions = {
  mediumContext: MEDIUM_CONTEXT,
};

const MovieCardsRow = movieList => {
  const list = movieList.movieList;

  const trackListItemClick = (listId, listItem) => {
    TrackingService.trackListItemClick(
      listId,
      listItem.id,
      defaultTrackingOptions
    );
  };

  const renderMovieCards = items => (
    <Fragment>
      <MovieCard
        key={items[0].id}
        movie={items[0]}
        index={1}
        onTrackClick={() => trackListItemClick(list.id, items[0])}
      />
      <MovieCard
        key={items[1].id}
        movie={items[1]}
        index={2}
        onTrackClick={() => trackListItemClick(list.id, items[1])}
      />
      <MovieCard
        key={items[2].id}
        movie={items[2]}
        index={3}
        onTrackClick={() => trackListItemClick(list.id, items[2])}
      />
      {/* Only show fourth MovieCard for mobile and tablet */}
      <Responsive maxWidth={bigTabletBreakpoint - 1}>
        <MovieCard
          key={items[3].id}
          movie={items[3]}
          index={4}
          onTrackClick={() => trackListItemClick(list.id, items[3])}
        />
      </Responsive>
    </Fragment>
  );

  const renderCallToAction = () => (
    <Fragment>
      <a
        href={buildRedirect(list)}
        onClick={() => trackListItemClick(list.id, list.items)}
      >
        <CallToAction callToAction={`See More ${list.name}`} fill={'#d92e26'} />
      </a>
    </Fragment>
  );

  return (
    <div>
      <div className="list-header">
        <SectionTitle titleText={list.name} />
        <Responsive minWidth={bigTabletBreakpoint}>
          {renderCallToAction()}
        </Responsive>
      </div>

      <div className="movie-cards-container">
        {list.items && renderMovieCards(list.items)}
      </div>

      <Responsive maxWidth={bigTabletBreakpoint - 1}>
        {renderCallToAction()}
      </Responsive>
    </div>
  );
};

MovieCardsRow.propTypes = {
  movieList: PropTypes.object.isRequired,
};

export default MovieCardsRow;
