import { createContext } from 'react';
import { NO_ERROR } from './showtimes-filter-errors';

export const ShowtimesFilterContext = createContext({
  theaters: [],
  theaterFilter: {
    theaters: [],
    selectedTheater: null,
    setSelectedTheater: () => {},
    nextPageUrl: null,
    getMoreTheaters: () => {},
  },
  dateFilter: {
    availableDates: [],
    selectedDate: null,
    setSelectedDate: () => {},
  },
  locationFilter: {
    selectedLocation: {},
    setSelectedLocation: () => {},
    error: false,
  },
  movieFilter: {
    selectedMovieIds: [],
    setSelectedMovieIds: () => {},
  },
  amenities: {
    theaterAmenities: {},
    showtimeAmenities: {},
  },
  errorCode: NO_ERROR,
  isLoading: false,
  onShowtimeClick: () => {},
  isMovieDetail: false,
  isTheaterDetail: false,
});
