import Availability from '../Availability';

export default class AvailabilitiesResponse {
  constructor(response) {
    if (response.availabilities && response.availabilities.length > 0) {
      this._availabilities = response.availabilities.map(
        availability => new Availability(availability)
      );
    } else {
      this._availabilities = [];
    }
  }

  get availabilities() {
    return this._availabilities;
  }
}
