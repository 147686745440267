export default class LoginUser {
  constructor(options) {
    this._userId = options.userId;
    this._emailAddress = options.emailAddress;
    this._firstName = options.firstName;
    this._lastName = options.lastName;
    this._screenName = options.screenName;
    this._timezone = options.timezone;
    this._birthdate = options.birthdate;
    this._role = options.role;
    this._registeredTimestamp = options.registeredTimestamp;
    this._preferences = options.preferences;
  }

  get userId() {
    return this._userId;
  }

  get emailAddress() {
    return this._emailAddress;
  }

  get firstName() {
    return this._firstName;
  }

  get lastName() {
    return this._lastName;
  }

  get screenName() {
    return this._screenName;
  }

  get timezone() {
    return this._timezone;
  }

  get birthdate() {
    return this._birthdate;
  }

  get role() {
    return this._role;
  }

  get registeredTimestamp() {
    return this._registeredTimestamp;
  }

  get preferences() {
    return this._preferences;
  }
}
