import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MovieService from '../../services/movie-service';
import Showing from '../../models/Showing';

export default class AvailableShowtimeContainer extends Component {
  static propTypes = {
    showing: PropTypes.instanceOf(Showing).isRequired,
    onTrackProviderClick: PropTypes.func,
  };

  handleShowtimeClick = () => {
    const { showing, onTrackProviderClick } = this.props;
    const showingUrl = showing.urls[0];

    onTrackProviderClick(showingUrl.providerFormatId);
  };

  render() {
    const { showing } = this.props;

    return (
      <a
        className="available-showtime"
        href={showing.urls[0] && showing.urls[0].url}
        onClick={this.handleShowtimeClick}
        target={'_blank'}
        rel="noopener noreferrer"
      >
        {MovieService.formatMovieTime(showing.time)}
      </a>
    );
  }
}
