import ContentFeed from '../ContentFeed';
import Meta from '../Meta';

export default class ContentFeedResponse {
  constructor(response) {
    this._feed = new ContentFeed(response);
    this._meta = new Meta(response.meta);
  }

  get feed() {
    return this._feed;
  }

  get meta() {
    return this._meta;
  }
}
