import $ from 'jquery';

const MILLIS_TIMEOUT = 8000;

export default class GeoService {
  static findCoordinates(force = false) {
    let deferred = $.Deferred();

    navigator.geolocation.getCurrentPosition(
      position => {
        let coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        deferred.resolve(coordinates);
      },
      error => {
        deferred.reject(error);
      },
      { timeout: MILLIS_TIMEOUT }
    ); //this timeout refers to the time after clicking accept to getting position

    return deferred.promise();
  }
}
