export default class User {
  constructor(options) {
    this._auth_token = options.auth_token || options.authToken;
    this._access_token = options.access_token || options.accessToken;
  }

  get authToken() {
    return this._auth_token;
  }

  get accessToken() {
    return this._access_token;
  }
}
