export default class GowatchitMovieId {
  constructor(options) {
    this._id = options.watchable_id;
    this._tms_id = options.tms_id;
  }

  get id() {
    return this._id;
  }

  get tmsId() {
    return this._tms_id;
  }
}
