import CarouselItem from './CarouselItem';

export default class Carousel {
  constructor(options = {}) {
    this._id = options.id;
    this._system_name = options.system_name;
    this._display_name = options.display_name;
    this._call_to_action_text = options.call_to_action_text;

    if (options.carousel_items && options.carousel_items.length > 0) {
      this._carousel_items = options.carousel_items.map(
        item => new CarouselItem(item)
      );
    } else {
      this._carousel_items = [];
    }
  }

  get id() {
    return this._id;
  }

  get systemName() {
    return this._system_name;
  }

  get displayName() {
    return this._display_name;
  }

  get callToActionText() {
    return this._call_to_action_text;
  }

  get carouselItems() {
    return this._carousel_items;
  }
}
