import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import SVG from '../../utils/svg';
import { FILTER_TYPES, PROVIDER_FORMAT_LOGOS } from '../../utils/constants';
import PrimeVideo from './prime-video-rent-own.png';

const FiltersDropdowns = ({
  filters,
  filterOptions,
  applyFilters,
  selectedFilters,
  setSelectedFilters,
  isSelected,
  handleFilterSelect,
  handleClose,
  renderYearSlider,
}) => {
  const [platformOpen, setPlatformOpen] = useState(false);
  const [genreOpen, setGenreOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);

  const handleApplyClick = (e, filterType) => {
    applyFilters(selectedFilters[filterType], filterType);

    const dropdownEl = e.target.parentNode.parentNode.parentNode;
    dropdownEl.focus();
  };

  const handleClearClick = filterType => {
    const newSelectedFilters = { ...selectedFilters };
    newSelectedFilters[filterType] = [];

    setSelectedFilters(newSelectedFilters);
  };

  const renderDropdownIcon = (dropdownOpen, filterType) => {
    const filterTypeEnabled = filters[filterType].length;
    if (filterTypeEnabled)
      return <span className="filters-on">{SVG.onIcon()}</span>;
    if (dropdownOpen) return <span className="chevron">{SVG.chevronUp()}</span>;
    return <span className="chevron">{SVG.chevronDown()}</span>;
  };

  const renderButtonsContainer = filterType => (
    <>
      <Dropdown.Divider className="buttons-divider" />

      <div className="buttons-container">
        <button
          className="clear-button"
          type="button"
          onClick={() => handleClearClick(filterType)}
        >
          Clear
        </button>
        <button
          className="apply-button"
          type="button"
          onClick={e => handleApplyClick(e, filterType)}
        >
          Apply
        </button>
      </div>
    </>
  );

  const renderPlatform = platform => {
    let platformClass = '';
    if (
      isSelected(platform, FILTER_TYPES.PLATFORM) ||
      selectedFilters.platforms.length === 0
    ) {
      platformClass += 'selected';
    }

    // No svg available for Prime Video
    if (platform === 'Prime Video') {
      return (
        <Dropdown.Item
          key={platform}
          className={platformClass}
          image={PrimeVideo}
          onClick={() => handleFilterSelect(platform, FILTER_TYPES.PLATFORM)}
        />
      );
    } else {
      return (
        <Dropdown.Item
          key={platform}
          className={platformClass}
          image={PROVIDER_FORMAT_LOGOS[platform]}
          onClick={() => handleFilterSelect(platform, FILTER_TYPES.PLATFORM)}
        />
      );
    }
  };

  const renderPlatformDropdown = () => {
    if (!filterOptions.platforms.length) return null;
    return (
      <Dropdown
        className="platform-dropdown"
        trigger={
          <button
            className="dropdown-trigger"
            type="button"
            onClick={() => setPlatformOpen(!platformOpen)}
          >
            Platform
            {renderDropdownIcon(platformOpen, FILTER_TYPES.PLATFORM)}
          </button>
        }
        icon={null}
        open={platformOpen}
        onBlur={() => handleClose(setPlatformOpen)}
        upward={false}
      >
        <Dropdown.Menu>
          {filterOptions.platforms.map(renderPlatform)}

          {renderButtonsContainer(FILTER_TYPES.PLATFORM)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderGenre = (genre, index) => (
    <Fragment key={genre}>
      {index > 0 && <Dropdown.Divider />}

      <Dropdown.Item>
        <input
          className="checkbox"
          type="checkbox"
          checked={isSelected(genre, FILTER_TYPES.GENRE)}
          onChange={() => handleFilterSelect(genre, FILTER_TYPES.GENRE)}
        />
        {genre}
      </Dropdown.Item>
    </Fragment>
  );

  const renderGenreDropdown = () => {
    if (!filterOptions.genres.length) return null;
    return (
      <Dropdown
        className="genre-dropdown"
        trigger={
          <button
            className="dropdown-trigger"
            type="button"
            onClick={() => setGenreOpen(!genreOpen)}
          >
            Genre
            {renderDropdownIcon(genreOpen, FILTER_TYPES.GENRE)}
          </button>
        }
        icon={null}
        open={genreOpen}
        onBlur={() => handleClose(setGenreOpen)}
        upward={false}
      >
        <Dropdown.Menu>
          <div className="genre-scroll-container">
            {filterOptions.genres.map(renderGenre)}
          </div>

          {renderButtonsContainer(FILTER_TYPES.GENRE)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderYearDropdown = () => {
    if (!filterOptions.years.length) return null;
    return (
      <Dropdown
        className="year-dropdown"
        trigger={
          <button
            className="dropdown-trigger"
            type="button"
            onClick={() => setYearOpen(!yearOpen)}
          >
            Year
            {renderDropdownIcon(yearOpen, FILTER_TYPES.YEAR)}
          </button>
        }
        icon={null}
        open={yearOpen}
        onBlur={() => handleClose(setYearOpen)}
        direction="left"
        upward={false}
      >
        <Dropdown.Menu>
          {renderYearSlider()}

          {renderButtonsContainer(FILTER_TYPES.YEAR)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderRating = (rating, index) => (
    <Fragment key={rating}>
      {index > 0 && <Dropdown.Divider />}

      <Dropdown.Item>
        <input
          className="checkbox"
          type="checkbox"
          checked={isSelected(rating, FILTER_TYPES.RATING)}
          onChange={() => handleFilterSelect(rating, FILTER_TYPES.RATING)}
        />
        {rating}
      </Dropdown.Item>
    </Fragment>
  );

  const renderRatingDropdown = () => {
    if (!filterOptions.years.length) return null;
    return (
      <Dropdown
        className="rating-dropdown"
        trigger={
          <button
            className="dropdown-trigger"
            type="button"
            onClick={() => setRatingOpen(!ratingOpen)}
          >
            Rating
            {renderDropdownIcon(ratingOpen, FILTER_TYPES.RATING)}
          </button>
        }
        icon={null}
        open={ratingOpen}
        onBlur={() => handleClose(setRatingOpen)}
        direction="left"
        upward={false}
      >
        <Dropdown.Menu>
          {filterOptions.ratings.map(renderRating)}

          {renderButtonsContainer(FILTER_TYPES.RATING)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="filters-dropdowns">
      {renderPlatformDropdown()}
      {renderGenreDropdown()}
      {renderYearSlider() && renderYearDropdown()}
      {renderRatingDropdown()}
    </div>
  );
};

FiltersDropdowns.propTypes = {
  filters: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  applyFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleFilterSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  renderYearSlider: PropTypes.func.isRequired,
};

export default FiltersDropdowns;
