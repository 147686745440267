import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import moment from 'moment';

import { Helmet } from 'react-helmet';
import NoovieAPI from '../models/api/NoovieAPI';
import List from '../models/List';
import {
  bigTabletBreakpoint,
  desktopBreakpoint,
} from '../utils/responsive-breakpoints';
import SVG from '../utils/svg';
import Carousel from '../components/Carousel';
import HomeSearch from '../components/search';
import AdContainer, { mid1, mid2, top } from '../components/AdContainer';

import HomePosterRows from '../components/list-row/HomePosterRows';
import LargeCard from '../components/cards/LargeCard';
import MediumCard from '../components/cards/MediumCard';
import SmallCard from '../components/cards/SmallCard';
import EditorialModule from '../components/sidebar-modules/EditorialModule';
import TrackingService from '../services/tracking_service';
import SpotlightModule from '../components/sidebar-modules/SpotlightModule';
import TrailerTray from '../components/trailer-tray/TrailerTray';
import WeeklyBoxOffice from '../components/sidebar-modules/WeeklyBoxOffice';

const MEDIUM_CONTEXT = 'home_page';
const defaultTrackingOptions = {
  mediumContext: MEDIUM_CONTEXT,
};

const Home = () => {
  const [contentFeed, setContentFeed] = useState({});
  const [posterRows, setPosterRows] = useState({});
  const [selectedPosterRow, setSelectedPosterRow] = useState();
  const [sidebarModules, setSidebarModules] = useState({});
  const [spotlightModule, setSpotlightModule] = useState({});
  const [trailerTray, setTrailerTray] = useState({});
  const [weeklyBoxOffice, setWeeklyBoxOffice] = useState({});
  const [loaded, setLoaded] = useState(false);

  const api = new NoovieAPI();

  const getPosterRow = useCallback(
    async id => {
      await setSelectedPosterRow(new List());

      const posterRow = posterRows.list.items.find(pr => pr.id === id);

      setSelectedPosterRow(posterRow);

      trackListImpression(id);
    },
    [posterRows]
  );

  const getMoreContent = useCallback(async () => {
    const moreContentResponse = await api.getMoreContent(
      contentFeed.meta.pagination
    );

    moreContentResponse.feed.items = [
      ...contentFeed.feed.items,
      ...moreContentResponse.feed.items,
    ];

    setContentFeed(moreContentResponse);
  }, [api, contentFeed]);

  const trackCallToActionClick = listItem => {
    const contextType = 'List';
    const contextId = sidebarModules.list.id;

    const options = {
      ...defaultTrackingOptions,
      customData: listItem.callToActionText,
      listItemId: listItem.id,
    };

    TrackingService.trackCallToActionClick(contextType, contextId, options);
  };

  const trackListImpression = listId => {
    TrackingService.trackListImpression(listId, defaultTrackingOptions);
  };

  const trackListItemClick = listItem => {
    const listId = posterRows.list.id;
    const listItemId = listItem.id;

    TrackingService.trackListItemClick(
      listId,
      listItemId,
      defaultTrackingOptions
    );
  };

  const trackListScroll = () => {
    const contextId = posterRows.list.id;

    TrackingService.trackListScroll(contextId, defaultTrackingOptions);
  };

  const trackPageView = () => {
    TrackingService.trackPageView(MEDIUM_CONTEXT);
  };

  useEffect(() => {
    const initialLoad = async () => {
      setLoaded(true);
      const contentFeedResponse = await api.getContentFeed({
        start_date: moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        limit: 6,
      });
      const sidebarModulesResponse = await api.getList('sidebar_modules');
      const spotlightModuleResponse = await api.getList('spotlight_home_page');
      const trailerTrayResponse = await api.getList('homepage_trailer_tray');
      const posterFeedsResponse = await api.getList('poster_rows');
      const weeklyBoxOfficeResponse = await api.getBoxOffice();

      setContentFeed(contentFeedResponse);
      setSidebarModules(sidebarModulesResponse);
      setSpotlightModule(spotlightModuleResponse);
      setTrailerTray(trailerTrayResponse);
      await setPosterRows(posterFeedsResponse);

      setWeeklyBoxOffice(weeklyBoxOfficeResponse);
    };

    if (!loaded) {
      initialLoad();
    }
  }, [api, getPosterRow, loaded]);

  useEffect(() => {
    if (
      !selectedPosterRow &&
      posterRows.list &&
      posterRows.list.items.length > 0
    ) {
      getPosterRow(posterRows.list.items[0].id);
    }
  });

  useEffect(() => {
    if (
      contentFeed.feed &&
      contentFeed.feed.items.length === 6 &&
      window.innerWidth >= bigTabletBreakpoint
    ) {
      getMoreContent();
    }
  }, [contentFeed, getMoreContent]);

  useEffect(() => {
    trackPageView();
  }, []);

  const renderPosterRows = () => {
    if (!posterRows.list) return null;

    return (
      <HomePosterRows
        posterRows={posterRows.list}
        selectedPosterRow={selectedPosterRow}
        onSelect={getPosterRow}
        onTrackScroll={trackListScroll}
        onTrackClick={trackListItemClick}
      />
    );
  };

  const renderContentFeed = (start, end) => {
    if (!contentFeed.feed) return null;

    return (
      <div className="content-feed">
        {contentFeed.feed.items.map((item, index) => {
          if (start && index < start) return null;
          if (end && index > end) return null;

          const indexType = index % 6;
          const contentKey = `${item.type}_${item.id}`;

          if (indexType === 0) {
            return <LargeCard key={contentKey} content={item} />;
          }

          if (indexType === 1) {
            return <MediumCard key={contentKey} content={item} align="left" />;
          }

          if (indexType === 2) {
            return <MediumCard key={contentKey} content={item} align="right" />;
          }

          if (indexType === 3) {
            return <LargeCard key={contentKey} content={item} />;
          }

          if (indexType === 4) {
            const nextItem = contentFeed.feed.items[index + 1];
            const nextContentKey =
              nextItem && `${nextItem.type}_${nextItem.id}`;
            const contentKeys = nextItem
              ? `${contentKey}_${nextContentKey}`
              : contentKey;

            return (
              <div key={contentKeys} className="small-card-container">
                <SmallCard content={item} />
                {nextItem && <SmallCard content={nextItem} />}
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  };

  const renderContentFeedMore = () => {
    if (
      contentFeed &&
      contentFeed.meta &&
      contentFeed.meta.pagination &&
      contentFeed.meta.pagination.links.nextPageUrl
    ) {
      return (
        <div className="content-feed-more-container">
          <div className="content-feed-more-line" />
          <button
            type="button"
            className="content-feed-more-text"
            onClick={getMoreContent}
          >
            More
            {SVG.downArrow()}
          </button>
        </div>
      );
    }
    return null;
  };

  const renderTrailers = () => {
    if (!trailerTray.list) return null;

    return <TrailerTray videos={trailerTray.list.items} />;
  };

  const renderSidebarModules = (top, start, end) => {
    if (!sidebarModules.list || !spotlightModule.list || !weeklyBoxOffice.list)
      return null;

    return (
      <Fragment>
        {top && (
          <Fragment>
            <AdContainer
              id="home-sidebar-slot-1"
              className="ad-centered ad-bottom-margin"
              unit="noovie.com/homepage"
              position={mid1}
              sizeMapping={[
                {
                  browser: [990, 0],
                  slot: [
                    [300, 250],
                    [300, 600],
                  ],
                },
                {
                  browser: [0, 0],
                  slot: [300, 250],
                },
              ]}
            />
            <SpotlightModule spotlight={spotlightModule} />

            <Responsive
              as={WeeklyBoxOffice}
              minWidth={desktopBreakpoint}
              boxOffice={weeklyBoxOffice}
            />
            <Responsive
              as={WeeklyBoxOffice}
              maxWidth={bigTabletBreakpoint - 1}
              boxOffice={weeklyBoxOffice}
            />
          </Fragment>
        )}

        {end !== -1 && (
          <Responsive
            as={WeeklyBoxOffice}
            minWidth={bigTabletBreakpoint}
            maxWidth={desktopBreakpoint - 1}
            boxOffice={weeklyBoxOffice}
          />
        )}

        {sidebarModules.list.items.map((item, i) => {
          if (start && i <= start) return null;
          if (end && i > end) return null;

          return (
            <EditorialModule
              handleClick={() => trackCallToActionClick(item)}
              key={item.id}
              item={item}
            />
          );
        })}

        {end !== -1 && (
          <AdContainer
            id="home-sidebar-slot-2"
            className="ad-centered ad-bottom-margin"
            unit="noovie.com/homepage"
            position={mid2}
            sizeMapping={[
              {
                browser: [990, 0],
                slot: [
                  [300, 250],
                  [300, 600],
                ],
              },
              {
                browser: [0, 0],
                slot: [300, 250],
              },
            ]}
          />
        )}
      </Fragment>
    );
  };

  const renderDesktop = () => (
    <Fragment>
      <div className="home-content-container">
        <div className="main-column-container">
          <div className="search-and-show-container">
            <HomeSearch />
            {renderPosterRows()}
          </div>

          <div className="content-feed-container">
            {renderContentFeed(0, 1)}
          </div>
        </div>

        <div className="sidebar-column-container">
          {renderSidebarModules(true, 0, -1)}
        </div>
      </div>

      <div className="trailer-tray-container">{renderTrailers()}</div>

      <div className="home-content-container bottom">
        <div className="main-column-container">
          <div className="content-feed-container">
            {renderContentFeed(2)}
            {renderContentFeedMore()}
          </div>
        </div>

        <div className="sidebar-column-container">
          {renderSidebarModules(false, 0)}
        </div>
      </div>
    </Fragment>
  );

  const renderBigTablet = () => (
    <Fragment>
      <div className="home-content-container">
        <div className="main-column-container">
          <div className="search-and-show-container">
            <HomeSearch />
            {renderPosterRows()}
          </div>
        </div>
      </div>

      <div className="home-content-fixed-container">
        <div className="home-content-container middle">
          <div className="main-column-container">
            <div className="content-feed-container">
              {renderContentFeed(0, 1)}
            </div>
          </div>

          <div className="sidebar-column-container">
            {renderSidebarModules(true, 0, -1)}
          </div>
        </div>
      </div>

      <div className="trailer-tray-container">{renderTrailers()}</div>

      <div className="home-content-fixed-container">
        <div className="home-content-container bottom">
          <div className="main-column-container">
            <div className="content-feed-container">
              {renderContentFeed(2)}
              {renderContentFeedMore()}
            </div>
          </div>

          <div className="sidebar-column-container">
            {renderSidebarModules(false, 0)}
          </div>
        </div>
      </div>
    </Fragment>
  );

  const renderTablet = () => (
    <Fragment>
      <div className="home-content-fixed-container">
        <div className="home-content-container">
          <div className="main-column-container">
            <div className="search-and-show-container">
              <HomeSearch />
            </div>
            <div className="show-container">{renderPosterRows()}</div>

            <hr className="content-feed-divider" />

            <AdContainer
              id="home-content-feed-slot"
              className="ad-centered ad-bottom-margin"
              unit="noovie.com/homepage"
              position={top}
              sizeMapping={[
                {
                  browser: [0, 0],
                  slot: [300, 250],
                },
              ]}
            />

            <div className="content-feed-container">
              {renderContentFeed(0, 1)}
            </div>
          </div>
        </div>

        <div className="trailer-tray-container">{renderTrailers()}</div>

        <div className="home-content-container bottom">
          <div className="main-column-container">
            <div className="content-feed-container">
              {renderContentFeed(2, 5)}
            </div>

            {renderSidebarModules(true)}

            <div className="content-feed-container">
              {renderContentFeed(6)}
              {renderContentFeedMore()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <title>
          Find Movies, Showtimes & Theaters Near You, Watch Trailers & More! |
          Noovie
        </title>
        <meta
          name="description"
          content="Noovie is your source for what's next in entertainment. Discover movies, watch trailers, find showtimes, buy tickets, and more."
        />
      </Helmet>
      <Carousel mediumContext={MEDIUM_CONTEXT} />

      <Responsive minWidth={desktopBreakpoint}>{renderDesktop()}</Responsive>

      <Responsive
        minWidth={bigTabletBreakpoint}
        maxWidth={desktopBreakpoint - 1}
      >
        {renderBigTablet()}
      </Responsive>

      <Responsive maxWidth={bigTabletBreakpoint - 1}>
        {renderTablet()}
      </Responsive>
      <div className="blur-wrapper" />
    </Fragment>
  );
};

export default Home;
