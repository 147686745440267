import List from '../List';

export default class TheatricalMovieListResponse {
  constructor(response) {
    this._list = new List({ items: response.movies });
  }

  get list() {
    return this._list;
  }
}
