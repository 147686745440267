import React, { Component } from 'react';
import { Divider, Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {
  NEED_LOCATION,
  SHOWTIMES_NO_SHOWTIMES_FOR_MOVIE,
  SHOWTIMES_NO_SHOWTIMES_FOR_MOVIES,
  THEATER_DETAIL_NO_SHOWTIMES,
  SHOWTIMES_NO_THEATERS,
  MOVIE_DETAIL_NO_SHOWTIMES,
  MOVIE_DETAIL_NOT_IN_THEATRICAL,
} from '../showtimes-filter/showtimes-filter-errors';
import {
  tabletBreakpoint,
  bigTabletBreakpoint,
} from '../../utils/responsive-breakpoints';
import { buildNNoovieLink } from '../../utils/nooviePath';
import Theater from '../../models/Theater';
import VideoExperienceService from '../../services/VideoExperienceService';
import TheaterAccordionContainer from './theater-accordion-container';
import NoovieAPI from '../../models/api/NoovieAPI';

const EXPANDED_COUNT = 1;

const desktopInsertAdIndex = 2;
const mobileInsertAdIndex = 0;

class TheaterMoviesContainer extends Component {
  static propTypes = {
    adComponent: PropTypes.element,
    theaters: PropTypes.arrayOf(PropTypes.instanceOf(Theater)).isRequired,
    onTrackTheaterClick: PropTypes.func,
    onTrackTheaterFavorite: PropTypes.func,
    onTrackProviderClick: PropTypes.func,
    customError: PropTypes.element,
    isInTheatersPage: PropTypes.bool,
  };

  getAmenities = () => {
    this.api.getAmenities().then(response => {
      const { amenities } = response;
      const theaterAmenities = {};
      const showtimeAmenities = {};

      amenities.forEach(amenity => {
        if (amenity.amenityType === 'theater') {
          const { systemName } = amenity;
          theaterAmenities[systemName] = amenity;
        } else {
          const { systemName } = amenity;
          showtimeAmenities[systemName] = amenity;
        }
      });

      this.setState({ showtimeAmenities, theaterAmenities });
    });
  };
  renderError = errorCode => {
    let errorContainer = null;

    switch (errorCode) {
      case NEED_LOCATION:
        errorContainer = this.renderNeedLocationError();
        break;
      case SHOWTIMES_NO_SHOWTIMES_FOR_MOVIE:
        errorContainer = this.renderShowtimesNoShowtimesForMovieError();
        break;
      case SHOWTIMES_NO_SHOWTIMES_FOR_MOVIES:
        errorContainer = this.renderShowtimesNoShowtimesForMoviesError();
        break;
      case SHOWTIMES_NO_THEATERS:
        errorContainer = this.renderShowtimesNoTheatersError();
        break;
      case THEATER_DETAIL_NO_SHOWTIMES:
        errorContainer = this.renderShowtimesNoMoviesForTheaterError();
        break;
      case MOVIE_DETAIL_NO_SHOWTIMES:
      case MOVIE_DETAIL_NOT_IN_THEATRICAL:
        errorContainer = this.renderMovieDetailNoShowtimesError();
        break;
      default:
        errorContainer = null;
    }

    return (
      <>
        {errorContainer}
        {this.props.adComponent}
      </>
    );
  };
  renderAccordion = (theater, i) => {
    const {
      adComponent,
      isInTheatersPage,
      onTrackTheaterClick,
      onTrackTheaterFavorite,
      onTrackProviderClick,
    } = this.props;

    const { showtimeAmenities, theaterAmenities } = this.state;

    return (
      <div key={theater.id}>
        <TheaterAccordionContainer
          key={theater.name}
          theater={theater}
          theaterIndex={i}
          setTheaterTrailer={this.setTheaterTrailer}
          expanded={isInTheatersPage ? true : i < EXPANDED_COUNT}
          isInTheatersPage={isInTheatersPage}
          onTrackTheaterClick={onTrackTheaterClick}
          onTrackTheaterFavorite={onTrackTheaterFavorite}
          onTrackProviderClick={onTrackProviderClick}
          showtimeAmenities={showtimeAmenities}
          theaterAmenities={theaterAmenities}
        />

        {i === desktopInsertAdIndex && adComponent && (
          <Responsive minWidth={bigTabletBreakpoint}>{adComponent}</Responsive>
        )}

        {i === mobileInsertAdIndex && adComponent && (
          <Responsive maxWidth={bigTabletBreakpoint - 1}>
            {adComponent}
          </Responsive>
        )}
      </div>
    );
  };

  modalIsOpen = () => !!this.state.movie;

  setTheaterTrailer = movie => {
    VideoExperienceService.openModalForVideo(movie.video.id);
  };

  closeModal = () => this.setState({ movie: null });

  hasMoreTheaters = () => !!this.props.nextPageUrl;

  renderNeedLocationError = () => (
    <div className="showtimes-error-container">
      <div className="showtimes-error-message">
        To get started, indicate your location in the box above.
      </div>
    </div>
  );

  renderShowtimesNoShowtimesForMovieError = () => (
    <div className="showtimes-error-container showtimes-no-showtimes-for-movie-error-container">
      <div className="showtimes-error-message showtimes-no-showtimes-for-movie-error">
        Unfortunately, there are no available showtimes for that movie in your
        area.
        <br />
        <Responsive maxWidth={tabletBreakpoint - 1}>
          <br />
        </Responsive>
        <span className="error-link">
          Try selecting other movies, changing the date, or changing your
          location
        </span>{' '}
        to see results.
      </div>
    </div>
  );

  renderShowtimesNoMoviesForTheaterError = () => (
    <div className="showtimes-error-container theater-detail-no-showtimes-error-container">
      <div className="showtimes-error-message theater-detail-no-showtimes-error">
        There are currently no showtimes reported for this theater.
        <br />
        <a className="error-link" href={buildNNoovieLink('showtimes')}>
          Click here
        </a>{' '}
        to find showtimes for movies now playing in your area.
      </div>
    </div>
  );

  renderShowtimesNoShowtimesForMoviesError = () => (
    <div className="showtimes-error-container">
      <div className="showtimes-error-message">
        Unfortunately, there are currently no showtimes returned for your
        search.
        <Responsive maxWidth={tabletBreakpoint - 1}>
          <br />
        </Responsive>
        <span className="error-link">
          Try selecting other movies, changing the date, or changing your
          location
        </span>{' '}
        to see results.
      </div>
    </div>
  );

  renderMovieDetailNoShowtimesError = () => (
    <div className="showtimes-error-container movie-detail-no-showtimes-error-container">
      <div className="showtimes-error-message movie-detail-no-showtimes-error">
        There are currently no showtimes for this movie.
        <br />
        <a className="error-link" href={buildNNoovieLink('showtimes')}>
          Click here
        </a>{' '}
        to find showtimes for movies now playing in your area.
      </div>
    </div>
  );

  renderShowtimesNoTheatersError = () => (
    <div className="showtimes-error-container">
      <div className="showtimes-error-message">
        No theaters found for this location. Try
        <span className="error-link"> entering a different location.</span>
      </div>
    </div>
  );
  renderTheaterMoviesContainer = () => {
    const {
      errorCode,
      customError,
      selectedTheater,
      getMoreTheaters,
      isInTheatersPage,
    } = this.props;
    const { showtimeAmenities } = this.state;
    const theaters = selectedTheater ? [selectedTheater] : this.props.theaters;

    if (!theaters.length && customError) {
      return customError;
    }

    if (errorCode) {
      return this.renderError(errorCode);
    }

    const isDisplayTheaters =
      theaters.length > 0 && Object.keys(showtimeAmenities).length > 0;

    return (
      <div className="theater-showtimes-container">
        {isDisplayTheaters && theaters.map(this.renderAccordion)}
        {!isInTheatersPage && this.hasMoreTheaters() && !selectedTheater ? (
          <Divider horizontal>
            <span className="view-more-theaters" onClick={getMoreTheaters}>
              View More Theaters
            </span>
          </Divider>
        ) : null}
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      movie: null,
      showtimeAmenities: {},
      theaterAmenities: {},
    };

    this.api = new NoovieAPI();
  }

  componentDidMount() {
    this.getAmenities();
  }

  render() {
    return this.renderTheaterMoviesContainer();
  }
}

export default TheaterMoviesContainer;
