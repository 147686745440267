import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NoovieApi from '../../models/api/NoovieAPI';
import { buildNNoovieLink } from '../../utils/nooviePath';
import SVG from '../../utils/svg';
import UserService from '../../services/UserService';

class FavoriteTheaterToggle extends Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    theaterId: PropTypes.number.isRequired,
    onToggleFavoriteStatus: PropTypes.func.isRequired,
    onTrackTheaterFavorite: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const isFavorite = this.props.isFavorite;

    const userAuthToken = UserService.getUser().authToken;

    this.api = new NoovieApi(userAuthToken);
    this.state = { isFavorite };
    this.toggleFavoriteStatus = this.toggleFavoriteStatus.bind(this);
  }

  handleFavoriteClick = e => {
    this.props.onTrackTheaterFavorite(!this.state.isFavorite);

    this.toggleFavoriteStatus(e);
  };

  toggleFavoriteStatus(e) {
    e.stopPropagation();

    if (UserService.getUser()) {
      const isFavorite = !this.state.isFavorite;

      this.setState({ isFavorite });

      if (isFavorite) {
        this.api.addFavoriteTheater(this.props.theaterId);
      } else {
        this.api.removeFavoriteTheater(this.props.theaterId);
      }

      if (this.props.onToggleFavoriteStatus) {
        this.props.onToggleFavoriteStatus(isFavorite);
      }
    } else {
      const redirectTo = `${window.location.pathname}${window.location.search}`;

      window.NoovieConnect.login(response => {
        const accessToken = response.access_token;
        window.location = buildNNoovieLink('authorize', {
          access_token: accessToken,
          redirect_to: redirectTo,
        });
      });
    }
  }

  render() {
    const { height } = this.props;

    return (
      <div
        className="favorite-theater-toggle"
        onClick={this.handleFavoriteClick}
      >
        {this.state.isFavorite
          ? SVG.filledHeart(height)
          : SVG.unfilledHeart(height)}
      </div>
    );
  }
}

export default FavoriteTheaterToggle;
