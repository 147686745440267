export const ADDRESS = 'Address';
export const PLACE = 'Place';

export class SuggestionResult {
  constructor(options = {}) {
    this._id = options.id;
    this._type = options.type;
    this._title = options.title;
    this._subtitle = options.subtitle;
    this._address = options.address;
    this._coordinates = options.coordinates;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get title() {
    return this._title;
  }

  get subtitle() {
    return this._subtitle;
  }

  get address() {
    return this._address;
  }

  get coordinates() {
    return this._coordinates;
  }
}

window.BING_MAPS_LOADED_NOOVIE = false;

export default class LocationAutosuggestService {
  constructor(options = {}) {
    this.manager = null;

    if (!window.BING_MAPS_LOADED_NOOVIE) {
      this.loadBingMapScript();
      window.BING_MAPS_LOADED_NOOVIE = true;
    }
  }

  loadBingMapScript = () => {
    const BingMaps = document.getElementById('BingMaps');
    if (BingMaps) {
      document.body.removeChild(BingMaps);
    }

    window.BING_MAPS_NOOVIE_LOADED = () => {
      this.loadManager();
    };

    // TODO: put api key in config
    const script = document.createElement('script');
    script.src =
      'https://www.bing.com/api/maps/mapcontrol?callback=BING_MAPS_NOOVIE_LOADED&key=AhSCKyNlerlpGnNYj6lJCjsu8c-THpLHDVmkvVnGkVK3NYFNfis4GuzxQOVzzQR5';
    script.async = true;
    script.defer = true;

    script.id = 'BingMaps';
    document.body.appendChild(script);
  };

  loadManager(options) {
    if (!this.manager) {
      const { Microsoft } = window;

      Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
        callback: () => {
          this.manager = new Microsoft.Maps.AutosuggestManager(options);
        },
      });
    }
  }

  getSuggestions(query) {
    const formattedQuery = query + ' ';

    return new Promise(resolve => {
      const { Microsoft } = window;
      this.manager = new Microsoft.Maps.AutosuggestManager();
      this.manager.getSuggestions(formattedQuery, suggestions => {
        const results = suggestions.map(
          LocationAutosuggestService.formatResult
        );
        resolve(results);
      });
    });
  }

  static formatResult(result) {
    const {
      entityId,
      entityType,
      title,
      subtitle,
      formattedSuggestion,
      location,
    } = result;
    const type = entityType === 'PostalAddress' ? ADDRESS : PLACE;
    // NOTE: location will be undefined when entityType === 'PostalAddress'
    // https://docs.microsoft.com/en-us/bingmaps/v8-web-control/modules/autosuggest-module/suggestionresult-object
    const coordinates = location
      ? { latitude: location.latitude, longitude: location.longitude }
      : null;

    const options = {
      id: entityId,
      type,
      title,
      subtitle,
      address: formattedSuggestion,
      coordinates,
    };

    return new SuggestionResult(options);
  }
}
