import NoovieAPI from '../models/api/NoovieAPI';
import $ from 'jquery';

const GeocoderService = {
  checkForZipcode: query =>
    // check for 5 digit (plus option of additional 4 digits) zipcode
    /^\d{5}(?:[-]\d{4})?$/.test(query),

  // TODO: put api key in config
  getCoordinates: query => {
    const API_KEY =
      'AhSCKyNlerlpGnNYj6lJCjsu8c-THpLHDVmkvVnGkVK3NYFNfis4GuzxQOVzzQR5';
    return $.ajax(
      `https://dev.virtualearth.net/REST/v1/Locations?query=${encodeURIComponent(
        query
      )}&key=${API_KEY}`
    );
  },

  getZipcode: (latitude, longitude) => {
    const api = new NoovieAPI();

    return api.getZipcode(latitude, longitude);
  },

  findZipcode: addressDetails => {
    let zipcode = null;
    addressDetails.forEach(detail => {
      const isPostalCode = detail.types[0] === 'postal_code';
      if (isPostalCode) {
        zipcode = detail.long_name;
      }
    });
    return zipcode;
  },
};

export default GeocoderService;
