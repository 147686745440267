import moment from 'moment-timezone';
import { ordinalDate } from './date-service';

export default class MovieService {
  // convertRunTime(runTimeInSeconds):
  // The API returns movie.run_time in seconds.
  // Convert and return as string for 'spotlight-header':
  static convertRunTime(runTimeInSeconds) {
    const runTimeInMinutes = runTimeInSeconds / 60;
    const hours =
      runTimeInMinutes >= 60 ? Math.floor(runTimeInMinutes / 60) : 0;
    const minutes = hours > 0 ? runTimeInMinutes % 60 : runTimeInMinutes;

    return `${hours}hr ${minutes}m`;
  }

  static formatMovieDetails(movie) {
    const details = [];
    if (movie.year) details.push(movie.year);
    if (movie.genre) details.push(movie.genre);
    if (movie.pg_rating) details.push(movie.pg_rating);
    if (movie.run_time && movie.run_time > 0) {
      details.push(MovieService.convertRunTime(movie.run_time));
    }
    return details.join(' · ');
  }

  static formatRunTime(runTime) {
    const hours = Math.floor(runTime / 60);
    const minutes = runTime % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
  }

  static formatReleaseDate(releaseDate) {
    return moment(releaseDate).format(ordinalDate);
  }

  static displayExperiences(experiences) {
    let formatted = '';

    for (let i = 0; i < experiences.length; i++) {
      if (i > 0) {
        formatted += ' • ';
      }

      formatted += this.formatExperience(experiences[i]);
    }

    return formatted;
  }

  static formatExperience(experience) {
    switch (experience) {
      case 'REGULAR':
        return 'Standard';
      case 'LUXURY_LOUNGER':
        return 'Luxury Lounger';
      case 'THREE_D':
        return '3D';
      case 'FOUR_D_X':
        return '4DX';
      case 'DOLBY_ATMOS':
        return 'Dolby Atmos';
      case 'CINEMARK_XD':
        return 'Cinemark XD';
      case 'D_BOX':
        return 'D-BOX';
      default:
        return experience;
    }
  }

  static displayScreenType(type) {
    switch (type) {
      case 'regular':
      case '2d':
        return 'Standard';
      case '3d':
        return '3D';
      case 'imax':
        return 'IMAX';
      case 'dolby':
        return 'Dolby Atmos';
      default:
        return type;
    }
  }

  static hasEmptyPoster = movie => {
    const url = !!movie.poster_url
      ? movie.poster_url
      : movie.image_location_url;

    return url === MovieService.NO_POSTER_IMG;
  };

  static formatCast(cast) {
    let truncatedCast = cast
      .slice(0, 3)
      .map(member => member.name)
      .join(', ');
    if (cast.length > 3) {
      truncatedCast += '\u2026';
    }
    return truncatedCast;
  }

  static formatMovieTime(time) {
    let timeParts = time.split(':');
    let hour = Number(timeParts[0]);
    let minutes = timeParts[1];
    let period = hour < 12 ? 'am' : 'pm';

    if (hour === 0) {
      hour = 12;
    } else if (hour !== 12) {
      hour = hour % 12;
    }
    return `${hour}:${minutes} ${period}`;
  }

  static displayUpcomingTimes(times, now) {
    return times.filter(time => {
      return moment(`${time.date} ${time.time}`).isAfter(now, 'minute');
    });
  }

  static isPastTime(showing, timeZone = null) {
    const { date, time } = showing;
    const showingTime = `${date} ${time}`;
    const pastMidnight = moment(showingTime).isBetween(
      `${date} 00:00`,
      `${date} 04:00`
    );
    const now = timeZone
      ? moment()
          .tz(timeZone)
          .format('YYYY-MM-DD HH:mm')
      : moment().format('YYYY-MM-DD HH:mm');

    if (pastMidnight) {
      const nextDate = moment(date)
        .add(1, 'day')
        .format('YYYY-MM-DD');
      return moment(`${nextDate} ${time}`).isBefore(now, 'minute');
    }

    return moment(showingTime).isBefore(now, 'minute');
  }

  static removeScreentypesWithoutShowtimes(screentypes) {
    return screentypes.filter(screentype => {
      return screentype.showings.length > 0;
    });
  }

  static formatDetails(movie) {
    const details = [];
    if (movie.pg_rating) {
      details.push(movie.pg_rating);
    }
    if (movie.genre) {
      details.push(movie.genre);
    }
    if (movie.run_time && movie.run_time > 0) {
      details.push(MovieService.formatRunTime(movie.run_time));
    }
    return details.join(' | ');
  }

  static formatMovieSlug(slug) {
    // Assumes API provides the following url format: "/watch/movies/black-panther-270043"
    return slug.split('/')[3];
  }

  static findOffset(url) {
    // Assumes API provides the following url format:
    // "https://gowatchit.com/api/v3/search/movies?api_key=XX&offset=60&term=black+panther"
    if (url) {
      const match = url.match(/offset=(\d+)/);
      if (match) {
        return match[1];
      }
      return null;
    }
    return null;
  }

  static Movie(data) {
    const slug = data.url ? MovieService.formatMovieSlug(data.url) : null;
    const image_location_url = data.poster_url;

    return {
      ...data,
      slug,
      image_location_url,
    };
  }

  static NO_POSTER_IMG = 'http://gowatchit.com/assets/movie_imgs/noposter.png';

  static findUpcomingTimesByScreentype({ screentypes, date }) {
    return screentypes
      .map(screentype => {
        const times = screentype.showings;
        const upcomingTimes = MovieService.displayUpcomingTimes(times, date);

        if (upcomingTimes.length > 0) {
          return {
            screen_type: screentype.screen_type,
            showings: upcomingTimes,
          };
        }

        return undefined;
      })
      .filter(screentype => {
        return screentype !== undefined;
      });
  }

  static formatAPIResponse(responses) {
    return responses.map(MovieService.Movie);
  }
}
