import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { isMobile } from 'react-device-detect';

import { DFP_NETWORK_CODE } from '../utils/constants';
import { getQueryParams } from '../utils/browser';

export const top = 'top';
export const mid1 = 'mid1';
export const mid2 = 'mid2';
export const bottom = 'bottom';

export default class AdContainer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    unit: PropTypes.string,
    position: PropTypes.string.isRequired,
    sizeMapping: PropTypes.arrayOf(
      PropTypes.shape({
        browser: PropTypes.array.isRequired,
        slot: PropTypes.array.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    unit: undefined,
    className: undefined,
  };

  constructor(props) {
    super(props);

    this.slot = null;
    this.id = props.id || `ad-slot-${props.id}-${props.position}`;
    this.slotName = `/${DFP_NETWORK_CODE}/${props.unit}`;
    this.platform = isMobile ? 'mweb' : 'desktop';
    this.test = getQueryParams().test;
  }

  componentDidMount() {
    this.setupGoogleTag();
  }

  componentWillUnmount() {
    if (window.googletag.destroySlots) {
      window.googletag.destroySlots([this.slot]);
    }
  }

  setupGoogleTag = () => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.cmd.push(() => {
      this.defineSlot();
      this.setTargeting();
      this.enable();
    });
  };

  getSlotSize = sizeMapping => {
    let slotSize = sizeMapping[0].slot;

    // Assign slotSize as the next, non-empty slot array
    if (!slotSize.length) {
      for (let i = 1; i < sizeMapping.length; i += 1) {
        const { slot } = sizeMapping[i];
        if (slot.length) {
          slotSize = slot;
          break;
        }
      }
    }

    return slotSize;
  };

  buildSizeMapping = sizeMapping => {
    let mapping = window.googletag.sizeMapping();

    // Append addSize() for each slot mapping provided
    sizeMapping.forEach(size => {
      mapping = mapping.addSize(size.browser, size.slot);
    });

    mapping = mapping.build();

    return mapping;
  };

  defineSlot = () => {
    const { sizeMapping } = this.props;

    const definedSlot = window.googletag.defineSlot(
      this.slotName,
      this.getSlotSize(sizeMapping),
      this.id
    );

    if (definedSlot) {
      this.slot = definedSlot
        .defineSizeMapping(this.buildSizeMapping(sizeMapping))
        .addService(window.googletag.pubads())
        .setTargeting('pos', this.position);
    } else {
      this.slot = null;
    }
  };

  setTargeting = () => {
    window.googletag.pubads().setTargeting('platform', this.platform);

    if (this.test) {
      window.googletag.pubads().setTargeting('test', this.test);
    }
  };

  enable = () => {
    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.enableServices();
  };

  display = () => {
    window.googletag.cmd.push(() => window.googletag.display(this.id));
  };

  render() {
    const { className } = this.props;
    const containerClass = className
      ? `ad-container ${className}`
      : 'ad-container';

    return (
      <div className={containerClass}>
        <Waypoint onEnter={this.display} />
        <div id={this.id} />
      </div>
    );
  }
}
