import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { Responsive } from 'semantic-ui-react';
import { buildNNoovieLink } from '../../utils/nooviePath';
import {
  bigTabletBreakpoint,
  desktopBreakpoint,
  smallMobileBreakpoint,
  tabletBreakpoint,
} from '../../utils/responsive-breakpoints';
import CardLabel from './CardLabel';

const LargeCard = ({ content }) => {
  const {
    description,
    type,
    image,
    publishedAt,
    name,
    title,
    updatedAt,
  } = content;
  const redirectPath = buildNNoovieLink(content);

  return (
    <a href={redirectPath}>
      <div className="large-card">
        <div
          className="large-card-image-container"
          style={{ backgroundImage: `url("${image}")` }}
        >
          <CardLabel label={type} />
        </div>

        <div className="large-card-details-container">
          <div className="large-card-published-date">
            {moment(publishedAt || updatedAt)
              .utc()
              .format('MMMM D, YYYY')}
          </div>

          <div className="large-card-title">{name || title}</div>

          <Responsive
            minWidth={smallMobileBreakpoint}
            maxWidth={tabletBreakpoint - 1}
          >
            <div className="large-card-description">{description}</div>
          </Responsive>
          <Responsive
            minWidth={tabletBreakpoint}
            maxWidth={bigTabletBreakpoint - 1}
          >
            <div className="large-card-description">
              <Truncate lines={3}>{description}</Truncate>
            </div>
          </Responsive>
          <Responsive
            minWidth={bigTabletBreakpoint}
            maxWidth={desktopBreakpoint - 1}
          >
            <div className="large-card-description">
              <Truncate lines={5}>{description}</Truncate>
            </div>
          </Responsive>
          <Responsive minWidth={desktopBreakpoint}>
            <div className="large-card-description">
              <Truncate lines={3}>{description}</Truncate>
            </div>
          </Responsive>
        </div>
      </div>
    </a>
  );
};

LargeCard.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    publishedAt: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

export default LargeCard;
