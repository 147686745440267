import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Movie from '../../models/Movie';
import SVG from '../../utils/svg';
import { buildNNoovieLink } from '../../utils/nooviePath';
import { tabletBreakpoint } from '../../utils/responsive-breakpoints';
import TheaterShowtimes from './theater-showtimes';

const playIconHeightDefault = 30;
const playIconHeightMobile = 20;

class TheaterShowtimesContainer extends Component {
  static propTypes = {
    showtimeAmenities: PropTypes.object.isRequired,
    movies: PropTypes.arrayOf(PropTypes.instanceOf(Movie)).isRequired,
    theaterName: PropTypes.string.isRequired,
    openAmenitiesModal: PropTypes.func.isRequired,
    theaterTimeZone: PropTypes.string,
    setTheaterTrailer: PropTypes.func,
    isInTheatersPage: PropTypes.bool,
    onTrackProviderClick: PropTypes.func,
  };

  convertToHoursAndMinutes = minutes => {
    const remainingMinutes = minutes % 60;
    const hours = (minutes - remainingMinutes) / 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  setTheaterTrailer = movie => {
    if (this.props.setTheaterTrailer) {
      this.props.setTheaterTrailer(movie);
    }
  };

  renderMoviePoster = movie => (
    <a href={buildNNoovieLink(movie)}>
      <img className="theater-movie-poster" src={movie.posterUrl} alt="" />
    </a>
  );

  renderVideoPlayerIcon = movie => (
    <>
      <Responsive
        minWidth={tabletBreakpoint}
        className="video-player"
        onClick={() => this.setTheaterTrailer(movie)}
      >
        {SVG.videoPlayerIcon(playIconHeightDefault)}
      </Responsive>
      <Responsive
        maxWidth={tabletBreakpoint - 1}
        className="video-player"
        onClick={() => this.setTheaterTrailer(movie)}
      >
        {SVG.videoPlayerIcon(playIconHeightMobile)}
      </Responsive>
    </>
  );

  renderMovieShowtimes = movie => {
    const {
      showtimeAmenities,
      openAmenitiesModal,
      theaterTimeZone,
      isInTheatersPage,
      onTrackProviderClick,
    } = this.props;

    return (
      <div key={movie.id} className="theater-movies-row">
        {isInTheatersPage && movie.posterUrl && (
          <Responsive minWidth={tabletBreakpoint}>
            {this.renderMoviePoster(movie)}
          </Responsive>
        )}

        <div key={movie.id} className="theater-movies-nested-container">
          {isInTheatersPage && movie.posterUrl && (
            <Responsive maxWidth={tabletBreakpoint - 1}>
              {this.renderMoviePoster(movie)}
            </Responsive>
          )}

          <div className="theater-movie-detail-container">
            {!isInTheatersPage &&
              movie.video &&
              this.renderVideoPlayerIcon(movie)}

            <div className="theater-metadata-container">
              <a className="theater-movie-title" href={buildNNoovieLink(movie)}>
                {movie.title}
              </a>
              <div className="theater-metadata">
                {movie.pgRating && (
                  <>
                    {movie.pgRating}
                    {movie.runTime > 0 && <span className="dot">•</span>}
                  </>
                )}
                {movie.runTime > 0 && (
                  <>{this.convertToHoursAndMinutes(movie.runTime)}</>
                )}
              </div>
            </div>
          </div>

          <Responsive minWidth={tabletBreakpoint}>
            <TheaterShowtimes
              theaterTimeZone={theaterTimeZone}
              showtimeAmenities={showtimeAmenities}
              showtimes={movie.showtimes}
              openAmenitiesModal={openAmenitiesModal}
              onTrackProviderClick={onTrackProviderClick}
            />
          </Responsive>
        </div>

        <Responsive maxWidth={tabletBreakpoint - 1}>
          <TheaterShowtimes
            theaterTimeZone={theaterTimeZone}
            showtimeAmenities={showtimeAmenities}
            showtimes={movie.showtimes}
            openAmenitiesModal={openAmenitiesModal}
            onTrackProviderClick={onTrackProviderClick}
          />
        </Responsive>
      </div>
    );
  };

  renderNoShowtimesAvailable = () => (
    <div className="no-showtimes-available-container">
      No showtimes available on the selected date.
    </div>
  );

  render() {
    const { movies, isInTheatersPage } = this.props;

    if (isInTheatersPage && !movies.length) {
      return this.renderNoShowtimesAvailable();
    }

    return movies.length > 0 && movies.map(this.renderMovieShowtimes);
  }
}

export default TheaterShowtimesContainer;
