import Image from './Image';
import Video from './Video';
import Showtime from './Showtime';
import Contributor from './Contributor';

export default class Movie {
  constructor(options = {}) {
    this._id = options.id;
    this._list_id = options.list_id;
    this._list_item_id = options.list_item_id;
    this._genre = options.genre;
    this._slug = options.slug;
    this._pg_rating = options.pg_rating;
    this._poster_url = options.poster_url || options.image_location_url;
    this._image_location_url = options.image_location_url;
    this._iconic_image_url = options.iconic_image_url;
    this._run_time = options.run_time;
    this._title = options.title;
    this._tms_id = options.tms_id;
    this._description = options.description;
    this._short_description = options.short_description;
    this._theatrical_release_date = options.theatrical_release_date;
    this._in_theatrical = options.in_theatrical;
    this._theater_count = options.theater_count;
    this._url = options.url;
    this._year = options.year;
    this._type = options.type;

    if (options.images && options.images.length > 0) {
      this._images = options.images.map(image => new Image(image));
    } else {
      this._images = [];
    }

    if (options.videos && options.videos.length > 0) {
      this._videos = options.videos.map(video => new Video(video));
    } else if (options.video) {
      this._videos = [new Video(options.video)];
    } else {
      this._videos = [];
    }

    if (options.contributors && options.contributors.length > 0) {
      this._contributors = options.contributors.map(
        contributor => new Contributor(contributor)
      );
    } else {
      this._contributors = [];
    }

    if (options.showtimes && options.showtimes.length > 0) {
      this._showtimes = options.showtimes.map(
        showtime => new Showtime(showtime)
      );
    } else {
      this._showtimes = [];
    }

    if (options.url && !options.slug) {
      this._slug = options.url.substr(options.url.lastIndexOf('/') + 1);
    }
  }

  static fromListItem(item) {
    const options = {
      ...item,
      id: item.movie_id || item.id,
      list_item_id: item.id,
      description: item.annotation || item.description,
      year: item.date || item.year,
      type: item.listable_type,
    };

    return new Movie(options);
  }

  get id() {
    return this._id;
  }

  get imageLocationUrl() {
    return this._image_location_url;
  }

  get iconicImageUrl() {
    return this._iconic_image_url;
  }

  get bannerImage() {
    return this.imageLocationUrl || this.posterUrl;
  }

  get listId() {
    return this._list_id;
  }

  get listItemId() {
    return this._list_item_id;
  }

  get genre() {
    return this._genre;
  }

  get slug() {
    return this._slug;
  }

  get pgRating() {
    return this._pg_rating;
  }

  get posterUrl() {
    return this._poster_url;
  }

  get runTime() {
    return this._run_time;
  }

  get title() {
    return this._title;
  }

  get tmsId() {
    return this._tms_id;
  }

  get type() {
    return 'Movie';
  }

  get description() {
    return this._description;
  }

  get shortDescription() {
    return this._short_description;
  }

  get theatricalReleaseDate() {
    return this._theatrical_release_date;
  }

  get isInTheatrical() {
    return this._in_theatrical;
  }

  get url() {
    return this._url;
  }

  get year() {
    return this._year;
  }

  get images() {
    return this._images;
  }

  get video() {
    return this.videos[0];
  }

  get videos() {
    return this._videos.filter(video => {
      return !(video.isRedband() || video.isHidden());
    });
  }

  get contributors() {
    return this._contributors;
  }

  get showtimes() {
    return this._showtimes;
  }

  get theaterCount() {
    return this._theater_count;
  }
}
