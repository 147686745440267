import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ titleText, boldWordCount }) => {
  const titleWords = titleText.toUpperCase().split(' ');
  let boldText = titleWords[0];
  if (boldWordCount > 1) {
    for (let i = 1; i < boldWordCount; i++) {
      boldText += ` ${titleWords[i]}`;
    }
  }
  const restOfTitle = titleWords
    .slice(boldWordCount, titleWords.length)
    .join(' ');

  return (
    <Fragment>
      <h2 className="section-header">
        <span className="header-bold">{boldText} </span>
        {restOfTitle}
      </h2>
    </Fragment>
  );
};

SectionTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  boldWordCount: PropTypes.number,
};

SectionTitle.defaultProps = {
  boldWordCount: 1,
};

export default SectionTitle;
