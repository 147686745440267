import React, { Fragment, useEffect, useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import AvailabilitiesRow from '../streaming/AvailabilitiesRow';

import GowatchitAPI from '../../models/api/GowatchitAPI';
import { buildNNoovieLink } from '../../utils/nooviePath';
import {
  mobileBreakpoint,
  tabletBreakpoint,
  desktopBreakpoint,
} from '../../utils/responsive-breakpoints';

// renderAvailabilitiesRow(isHeroCard, availabilities):
// The displayNumber (number of availabilities displayed on each
// StreamingCard) is conditionally dependent on two things:
// 1) The browser width breakpoint, and
// 2) Whether or not the card is a big "hero card" in its PosterRow:
const renderAvailabilitiesRow = (movie, isHeroCard, availabilities, key) => (
  <Fragment key={key}>
    <Responsive minWidth={mobileBreakpoint} maxWidth={tabletBreakpoint - 1}>
      <AvailabilitiesRow
        movie={movie}
        availabilities={availabilities}
        displayNumber={isHeroCard ? 4 : 2}
      />
    </Responsive>
    <Responsive minWidth={tabletBreakpoint} maxWidth={desktopBreakpoint - 1}>
      <AvailabilitiesRow
        movie={movie}
        availabilities={availabilities}
        displayNumber={isHeroCard ? 9 : 4}
      />
    </Responsive>
    <Responsive minWidth={desktopBreakpoint}>
      <AvailabilitiesRow
        movie={movie}
        availabilities={availabilities}
        displayNumber={isHeroCard ? 9 : 3}
      />
    </Responsive>
  </Fragment>
);

// StreamingCard(movie, isHeroCard):
const StreamingCard = props => {
  const { movie, isHeroCard } = props;
  const gwiApi = new GowatchitAPI();
  const [availabilities, setAvailabilities] = useState({});

  useEffect(() => {
    const tmsId = [];
    tmsId.push(movie._tms_id);

    gwiApi
      .fetchGowatchitMovieIds(tmsId)
      .then(response => response.gowatchitMovieIds[0].id)
      .then(response => gwiApi.fetchMovieAvailabilities(response))
      .then(response => {
        const { availabilities } = response;
        setAvailabilities(availabilities);
      });
  }, [gwiApi, movie._tms_id]);

  return (
    <div className="streaming-card">
      <a href={buildNNoovieLink(movie)}>
        <div
          className="streaming-card-image-container"
          style={{ backgroundImage: `url(${movie._iconic_image_url})` }}
        />
      </a>
      <div className="streaming-card-details-container">
        {availabilities &&
          availabilities.length > 0 &&
          renderAvailabilitiesRow(movie, isHeroCard, availabilities)}

        <a href={buildNNoovieLink(movie)}>
          <div className="streaming-card-title">{movie.title}</div>

          <div className="streaming-card-advisory-genre">
            {movie.pgRating}
            {movie.pgRating && movie.genre && (
              <span className="streaming-poster-rating-genre-dot">·</span>
            )}
            {movie.genre}
          </div>

          {isHeroCard && (
            <div className="streaming-card-description">
              {movie.description}
            </div>
          )}
        </a>
      </div>
    </div>
  );
};

StreamingCard.propTypes = {
  movie: PropTypes.object.isRequired,
  isHeroCard: PropTypes.bool,
};

StreamingCard.defaultProps = {
  isHeroCard: false,
};

export default StreamingCard;
