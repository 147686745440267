import React from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import SectionTitle from '../SectionTitle';

import { buildNNoovieLink } from '../../utils/nooviePath';
import {
  tabletBreakpoint,
  bigTabletBreakpoint,
} from '../../utils/responsive-breakpoints';

const SpotlightModule = ({ spotlight: { list } }) => {
  const movie = list.items[0];
  const spotlightHeaderText = "What's Streaming";

  const renderSpotlightHeader = () => (
    <div className="spotlight-header-container">
      <div className="spotlight-header">
        <SectionTitle titleText={spotlightHeaderText} />
      </div>
      <div className="spotlight-subheader">Spotlight: {list.name}</div>
    </div>
  );

  const renderSpotlightMovieDetails = () => (
    <div className="spotlight-movie-details">
      <div className="spotlight-movie-title">{movie.title}</div>
      <div className="spotlight-movie-metadata">
        {movie.pgRating && `${movie.pgRating},`} {movie.genre}
      </div>
    </div>
  );

  const renderSpotlight = () => (
    <>
      {renderSpotlightHeader()}
      <div
        className="spotlight-movie-poster-container"
        style={{ backgroundImage: `url("${movie.posterUrl}")` }}
      >
        <div className="spotlight-movie-poster-gradient" />

        {renderSpotlightMovieDetails()}
      </div>
    </>
  );

  const renderSpotlightTablet = () => (
    <div className="spotlight-tablet-container">
      <div
        className="spotlight-movie-poster-container"
        style={{ backgroundImage: `url("${movie.posterUrl}")` }}
      />

      <div className="spotlight-tablet-content-container">
        {renderSpotlightHeader()}

        {renderSpotlightMovieDetails()}
      </div>

      <div className="line" />
      <div className="gray-box" />
    </div>
  );

  return (
    <a href={buildNNoovieLink(movie)}>
      <Responsive className="spotlight-module" maxWidth={tabletBreakpoint - 1}>
        {renderSpotlight()}
      </Responsive>

      <Responsive
        className="spotlight-module"
        minWidth={tabletBreakpoint}
        maxWidth={bigTabletBreakpoint - 1}
      >
        {renderSpotlightTablet()}
      </Responsive>

      <Responsive className="spotlight-module" minWidth={bigTabletBreakpoint}>
        {renderSpotlight()}
      </Responsive>
    </a>
  );
};

SpotlightModule.propTypes = {
  spotlight: PropTypes.shape({
    list: PropTypes.shape({
      items: PropTypes.array,
      name: PropTypes.string,
    }),
  }),
};

export default SpotlightModule;
