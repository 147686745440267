import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Responsive } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import AdContainer, { top, bottom } from '../components/AdContainer';
import NavbarContext from '../components/navigation/navbar-context';
import LargePosterRow from '../components/streaming/LargePosterRow';
import SmallPosterRow from '../components/streaming/SmallPosterRow';
import CallToAction from '../components/links/call-to-action';
import SpotlightContainer from '../components/SpotlightContainer';
import FlickityPosterRow from '../components/streaming/FlickityPosterRow';

import HeaderImage from '../assets/img/RedRemotePhoto.jpg';
import NoovieAPI from '../models/api/NoovieAPI';
import { bigTabletBreakpoint } from '../utils/responsive-breakpoints';
import { buildRedirect } from '../utils/nooviePath';
import TrackingService from '../services/tracking_service';

const MEDIUM_CONTEXT = 'streaming_page';
const defaultTrackingOptions = {
  mediumContext: MEDIUM_CONTEXT,
};
const SPOTLIGHT_CALL_TO_ACTION_TEXT = 'See Streaming Options';

const Streaming = () => {
  // State variables:
  // loaded = initial value false
  // posterRows = array of List objects that each have 1 ARRAY of movies
  // spotlights = array of Spotlight objects that each have 1 Movie
  const [spotlights, setSpotlights] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [posterRows, setPosterRows] = useState({
    largePosterRow1: {},
    smallPosterRow1: {},
    smallPosterRow2: {},
    largePosterRow2: {},
    sliderPosterRow1: {},
    sliderPosterRow2: {},
    sliderPosterRow3: {},
    sliderPosterRow4: {},
  });

  const { setDarkTheme } = useContext(NavbarContext);

  const api = new NoovieAPI();

  function styleAdNavbar() {
    const adContainer = document.getElementsByClassName('ad-navbar');
    if (adContainer.length) {
      adContainer[0].style.backgroundColor = '#F0F0F0';
    }
  }

  const trackListImpression = listId => {
    TrackingService.trackListImpression(listId, defaultTrackingOptions);
  };

  const trackListItemClick = listItem => {
    const listItemId = listItem.id;
    const listId = listItem.id;

    TrackingService.trackListItemClick(
      listId,
      listItemId,
      defaultTrackingOptions
    );
  };

  const trackListScroll = listId => () => {
    TrackingService.trackListScroll(listId, defaultTrackingOptions);
  };

  useEffect(() => {
    const initialLoad = async () => {
      setLoaded(true);
      const posterRowsResponse = await api.getList('streaming_poster_rows');
      const spotlightsResponse = await api.getSpotlights(
        'streaming_spotlights'
      );

      setSpotlights(spotlightsResponse.list.items);
      if (posterRowsResponse) {
        setPosterRows({
          largePosterRow1: posterRowsResponse.list.items[0],
          smallPosterRow1: posterRowsResponse.list.items[1],
          smallPosterRow2: posterRowsResponse.list.items[2],
          largePosterRow2: posterRowsResponse.list.items[3],
          sliderPosterRow1: posterRowsResponse.list.items[4],
          sliderPosterRow2: posterRowsResponse.list.items[5],
          sliderPosterRow3: posterRowsResponse.list.items[6],
          sliderPosterRow4: posterRowsResponse.list.items[7],
        });
      }

      for (let p = 0; p < posterRowsResponse.list.items.length; p += 1) {
        trackListImpression(posterRowsResponse.list.items[p].id);
      }

      for (let s = 0; s < spotlightsResponse.list.items.length; s += 1) {
        trackListImpression(spotlightsResponse.list.items[s].id);
      }
    };

    if (!loaded) {
      styleAdNavbar();
      setDarkTheme(false);
      initialLoad();
    }
  }, [api, loaded, setDarkTheme]);

  useEffect(() => {
    const trackPageView = () => {
      TrackingService.trackPageView(MEDIUM_CONTEXT);
    };

    trackPageView();
  }, []);

  const renderHeroBanner = () => (
    <div
      className="hero-banner"
      style={{ backgroundImage: `url(${HeaderImage})` }}
    >
      <div className="hero-banner-text-area">
        <h1>Now Streaming</h1>
        <p>
          Noovie is your source for what to watch on all the key streaming
          services, including Netflix, Hulu, Amazon Prime, and more. We know
          what’s coming in, what’s going out, and what’s popular. Plus, find our
          Top Picks for what to watch now.
        </p>
      </div>
    </div>
  );

  const handleSpotlightCallToActionClick = spotlight => () => {
    const contextType = 'List';
    const contextId = spotlight.list_id;
    const options = {
      ...defaultTrackingOptions,
      customData: SPOTLIGHT_CALL_TO_ACTION_TEXT,
      listItemId: spotlight.id,
    };

    TrackingService.trackCallToActionClick(contextType, contextId, options);
  };

  const handleTrackList = list => () => {
    trackListItemClick(list.id);
  };

  const renderSpotlight = (spotlight, align) => (
    <SpotlightContainer
      align={align}
      callToActionText={SPOTLIGHT_CALL_TO_ACTION_TEXT}
      onCallToActionClick={handleSpotlightCallToActionClick(spotlight)}
      spotlight={spotlight}
    />
  );

  const {
    smallPosterRow1,
    smallPosterRow2,
    largePosterRow1,
    largePosterRow2,
    sliderPosterRow1,
    sliderPosterRow2,
    sliderPosterRow3,
    sliderPosterRow4,
  } = posterRows;
  const sliderRows = [
    sliderPosterRow1,
    sliderPosterRow2,
    sliderPosterRow3,
    sliderPosterRow4,
  ];

  const renderContent = () => (
    <Fragment>
      <Helmet>
        <title>
          Streaming | Find What’s Streaming on Netflix, Hulu, HBO and More |
          Noovie
        </title>
        <meta
          name="description"
          content="Find out what’s new to steam on Netflix, Hulu, HBO, Amazon Prime, and more."
        />
        <meta
          name="keywords"
          content="streaming, streaming movies, streaming services, streaming tv, whats on netflix, whats on hulu, whats on hbo, whats on tv tonight, watch movies online, rent movies online, buy movies online, watch movies instantly, watch movies dvd, watch movies blu ray, watch movies in theater, watch movies on demand, Netflix, iTunes, Redbox, Amazon, Fandango, Movies on Demand."
        />
      </Helmet>
      {renderHeroBanner()}

      {/* Top Container */}
      {/* ------------------------------------------- */}
      <div className="streaming-poster-row-container top-container">
        {/* First LargePosterRow */}
        {/* ------------------------------------------- */}
        {posterRows && Object.keys(largePosterRow1).length > 0 && (
          <div className="whats-new-desktop">
            <LargePosterRow posterRow={largePosterRow1} />
            <a
              href={buildRedirect(largePosterRow1)}
              onClick={handleTrackList(largePosterRow1)}
              className="view-more-button-top"
            >
              <CallToAction callToAction={`See More ${largePosterRow1.name}`} />
            </a>
          </div>
        )}

        {/* First SmallPosterRow */}
        {/* ------------------------------------------- */}
        {posterRows && Object.keys(smallPosterRow1).length > 0 && (
          <div className="top-right-container-desktop">
            <AdContainer
              id="home-sidebar-slot-1"
              className="ad-streaming-top"
              unit="noovie.com/homepage"
              position={top}
              sizeMapping={[
                {
                  browser: [0, 0],
                  slot: [300, 250],
                },
              ]}
            />

            <div className="popular-now-desktop">
              <SmallPosterRow isAdColumn posterRow={smallPosterRow1} />
            </div>
            <a
              href={buildRedirect(smallPosterRow1)}
              onClick={handleTrackList(smallPosterRow1)}
              className="view-more-button-top"
            >
              <CallToAction callToAction={`See More ${smallPosterRow1.name}`} />
            </a>
          </div>
        )}
      </div>

      {/* First Spotlight */}
      {/* ------------------------------------------- */}
      <div className="spotlight-container-component-holder">
        {spotlights &&
          spotlights.length > 0 &&
          renderSpotlight(spotlights[0], 'right')}
      </div>

      {/* Middle Container for */}
      {/* Mobile & Tablet: */}
      {/* ------------------------------------------- */}
      <Responsive
        className="streaming-poster-row-container middle-container"
        maxWidth={bigTabletBreakpoint - 1}
      >
        {/* Second Large Poster Row */}
        {/* ------------------------------------------- */}
        {posterRows && Object.keys(largePosterRow2).length > 0 && (
          <div className="leaving-soon-desktop">
            <LargePosterRow posterRow={largePosterRow2} />
            <a
              href={buildRedirect(largePosterRow2)}
              onClick={handleTrackList(largePosterRow2)}
              className="view-more-button"
            >
              <CallToAction callToAction={`See More ${largePosterRow2.name}`} />
            </a>
          </div>
        )}

        {/* Second Small Poster Row */}
        {/* ------------------------------------------- */}
        {posterRows && Object.keys(smallPosterRow2).length > 0 && (
          <div className="top-picks-desktop">
            <SmallPosterRow posterRow={smallPosterRow2} />
            <a
              href={buildRedirect(smallPosterRow2)}
              onClick={handleTrackList(smallPosterRow2)}
              className="view-more-button"
            >
              <CallToAction callToAction={`See More ${smallPosterRow2.name}`} />
            </a>
          </div>
        )}
      </Responsive>

      {/* Middle Container for */}
      {/* 1024px and up: */}
      {/* ------------------------------------------- */}
      <Responsive
        className="streaming-poster-row-container middle-container"
        minWidth={bigTabletBreakpoint}
      >
        {/* Second Small Poster Row */}
        {/* ------------------------------------------- */}
        {posterRows && Object.keys(smallPosterRow2).length > 0 && (
          <div className="top-picks-desktop">
            <SmallPosterRow posterRow={smallPosterRow2} />
            <a
              href={buildRedirect(smallPosterRow2)}
              onClick={handleTrackList(smallPosterRow2)}
              className="view-more-button"
            >
              <CallToAction callToAction={`See More ${smallPosterRow2.name}`} />
            </a>
          </div>
        )}

        {/* Second Large Poster Row */}
        {/* ------------------------------------------- */}
        {posterRows && Object.keys(largePosterRow2).length > 0 && (
          <div className="leaving-soon-desktop">
            <LargePosterRow posterRow={largePosterRow2} />
            <a
              href={buildRedirect(largePosterRow2)}
              onClick={handleTrackList(largePosterRow2)}
              className="view-more-button"
            >
              <CallToAction callToAction={`See More ${largePosterRow2.name}`} />
            </a>
          </div>
        )}
      </Responsive>

      {/* Second Spotlight */}
      {/* ------------------------------------------- */}
      <div className="spotlight-container-component-holder">
        {spotlights &&
          spotlights.length > 0 &&
          renderSpotlight(spotlights[1], 'left')}
      </div>

      {/* Bottom Container */}
      {/* Flickity Poster Galleries */}
      {/* ------------------------------------------- */}
      {posterRows && Object.keys(sliderPosterRow1).length > 0 && (
        <div className="flickity-gallery-desktop bottom-container">
          {sliderRows.map(row => (
            <FlickityPosterRow
              key={row.id}
              id={row.id}
              posterRowName={row.name}
              posterRowMovies={row.movies}
              onTrackScroll={trackListScroll(row.id)}
            />
          ))}
        </div>
      )}

      <AdContainer
        id="streaming-bottom-ad-slot"
        className="ad-centered ad-streaming-bottom"
        unit="noovie.com/streaming"
        width={728}
        height={90}
        position={bottom}
        sizeMapping={[
          {
            browser: [990, 0],
            slot: [728, 90],
          },
          {
            browser: [0, 0],
            slot: [300, 250],
          },
        ]}
      />
    </Fragment>
  );

  return <Fragment>{posterRows && spotlights && renderContent()}</Fragment>;
};

export default Streaming;
