import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { Responsive } from 'semantic-ui-react';
import CardLabel from './CardLabel';
import { buildNNoovieLink } from '../../utils/nooviePath';
import {
  smallMobileBreakpoint,
  tabletBreakpoint,
} from '../../utils/responsive-breakpoints';

function renderImage(type, imageUrl, align) {
  const backgroundImage = { backgroundImage: `url("${imageUrl}")` };
  const imageContainerClass = classNames('medium-card-image-container', {
    'left-aligned': align === 'left',
    'right-aligned': align === 'right',
  });

  return (
    <div className={imageContainerClass}>
      <div className="medium-card-image" style={backgroundImage}>
        <CardLabel label={type} />
      </div>
    </div>
  );
}

const MediumCard = ({ content, align }) => {
  const {
    description,
    type,
    image,
    publishedAt,
    name,
    title,
    updatedAt,
  } = content;
  const redirectPath = buildNNoovieLink(content);
  const detailsContainerClass = classNames('medium-card-details-container', {
    'left-aligned': align === 'left',
    'right-aligned': align === 'right',
  });

  return (
    <a href={redirectPath}>
      <div className="medium-card">
        <div className="medium-card-container">
          {align === 'left' && renderImage(type, image, align)}

          <div className={detailsContainerClass}>
            <div className="medium-card-published-date">
              {moment(publishedAt || updatedAt)
                .utc()
                .format('MMMM D, YYYY')}
            </div>

            <Responsive
              minWidth={smallMobileBreakpoint}
              maxWidth={tabletBreakpoint - 1}
            >
              <div className="medium-card-name">
                <Truncate lines={4}>{name || title}</Truncate>
              </div>
            </Responsive>
            <Responsive minWidth={tabletBreakpoint}>
              <div className="medium-card-name">
                <Truncate lines={6}>{name || title}</Truncate>
              </div>
            </Responsive>

            <div className="medium-card-description">{description}</div>
          </div>

          {align === 'right' && renderImage(type, image, align)}
        </div>

        <div className="medium-card-mobile-description-container">
          <div className="medium-card-mobile-description">{description}</div>
        </div>
      </div>
    </a>
  );
};

MediumCard.propTypes = {
  align: PropTypes.string,
  content: PropTypes.shape({
    description: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    publishedAt: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

export default MediumCard;
