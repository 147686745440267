import Theater from '../Theater';

export default class TheaterDetailResponse {
  constructor(response) {
    this._theater = new Theater(response.theater);
    this._available_dates = response.available_dates;
  }

  get theater() {
    return this._theater;
  }

  get availableDates() {
    return this._available_dates;
  }
}
