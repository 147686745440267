import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import states from 'us-state-codes';
import LocationAutosuggestService from '../services/LocationAutosuggestService';
import GeocoderService from '../services/geocoder_service';
import SVG from '../utils/svg';
import { buildNNoovieLink } from '../utils/nooviePath';
import { getLocationParams } from '../services/showtimes_service';
import { ShowtimesFilterContext } from './showtimes-filter/showtimes-filter-context';
import {
  bigTabletBreakpoint,
  tabletBreakpoint,
} from '../utils/responsive-breakpoints';

const HomeSearch = props => {
  const { isInTheatersPage } = props;

  const pinHeight = 18;
  const searchIcon = 24;

  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const { locationFilter } = useContext(ShowtimesFilterContext);

  const autosuggest = new LocationAutosuggestService();

  const handleInputChange = async e => {
    setQuery(e.target.value);

    const results = await autosuggest.getSuggestions(e.target.value);
    setSuggestions(results);
  };

  const handleSelectSuggestion = (event, suggestion) => {
    const { address, title } = suggestion;
    const isZipcode = GeocoderService.checkForZipcode(title);
    const location = isZipcode ? title : address;

    setQuery(location);
    setShowResults(false);
  };

  const handleSelectCurrentLocation = () => {
    if (
      locationFilter.selectedLocation &&
      locationFilter.selectedLocation.zipcode
    ) {
      setQuery(locationFilter.selectedLocation.zipcode);
    }
  };

  const routeUserToShowtimes = async () => {
    const isZipcode = GeocoderService.checkForZipcode(query);
    if (isZipcode) {
      window.location = buildNNoovieLink(`showtimes?zipcode=${query}`);
    } else {
      const { lat, lng } = await getLocationParams(query);
      window.location = buildNNoovieLink(`showtimes?lat=${lat}&lng=${lng}`);
    }
  };

  useEffect(() => {
    setQuery(locationFilter.selectedLocation.zipcode || '');
  }, [locationFilter]);

  const isZipCode = text => !Number.isNaN(text) && text.length === 5;

  const renderSuggestion = suggestion => {
    const { id, title, subtitle, type } = suggestion;

    // show Place suggestions only if we think the entry is a zipcode
    if (type !== 'Place' && isZipCode(query)) {
      return null;
    }

    const subtitleSplit = subtitle.split(', ');
    let newSubtitle = subtitle;

    if (subtitleSplit.length === 2) {
      let state = subtitleSplit[0].trim();

      state = states.getStateCodeByStateName(state);

      newSubtitle = state;
    } else if (subtitleSplit.length === 3) {
      const firstItem = subtitleSplit[0].trim();
      let secondItem = subtitleSplit[1].trim();

      if (isZipCode(secondItem)) {
        newSubtitle = firstItem;
      } else {
        if (secondItem.indexOf(' ') !== -1) {
          const stateSplit = secondItem.split(' ');

          const lastSplitItem = stateSplit[stateSplit.length - 1];
          if (isZipCode(lastSplitItem)) {
            stateSplit.pop();
            secondItem = stateSplit.join(' ').trim();
          }
        }

        secondItem = states.getStateCodeByStateName(secondItem);
        newSubtitle = `${firstItem}, ${secondItem}`;
      }
    }

    return (
      <div
        key={id}
        className="suggestion"
        onClick={event => {
          handleSelectSuggestion(event, suggestion);
        }}
      >
        <div>
          <p className="title">{`${title} (${newSubtitle})`}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="home-search">
      <div className="home-search-input-container">
        <div className="home-search-input-icon">
          {SVG.navbarSearchIcon(searchIcon)}
        </div>
        <input
          type="input"
          placeholder="Enter City + State or Zip Code"
          onChange={handleInputChange}
          onFocus={() => setShowResults(true)}
          onBlur={debounce(() => {
            setShowResults(false);
          }, 300)}
          value={query || ''}
        />
        {showResults && (
          <div className="suggestion-container">
            <div
              className="current-location"
              onClick={event => handleSelectCurrentLocation(event)}
            >
              {SVG.location(pinHeight)}
              <div>Current location</div>
            </div>
            {suggestions.map(renderSuggestion)}
          </div>
        )}
      </div>
      <Responsive
        className="find-showtimes-button-container"
        minWidth={isInTheatersPage ? bigTabletBreakpoint : tabletBreakpoint}
      >
        <button
          type="button"
          className="find-showtimes"
          onClick={routeUserToShowtimes}
        >
          {isInTheatersPage ? <>Find Theaters Now</> : <>Find Showtimes</>}
        </button>
      </Responsive>
      <Responsive
        className="find-showtimes-button-container"
        maxWidth={
          isInTheatersPage ? bigTabletBreakpoint - 1 : tabletBreakpoint - 1
        }
      >
        <button
          type="button"
          className="find-showtimes"
          onClick={routeUserToShowtimes}
        >
          GO
        </button>
      </Responsive>
    </div>
  );
};

HomeSearch.propTypes = {
  isInTheatersPage: PropTypes.bool,
};

HomeSearch.defaultProps = {
  isInTheatersPage: false,
};

export default withRouter(HomeSearch);
