import React, { Fragment } from 'react';
import config from '../../config/env';
import SVG from '../../utils/svg';

const noovieLogoDefaultHeight = 36;
const noovieComLogoDefaultHeight = 23;
const noovieArcadeLogoDefaultHeight = 23;
const noovieFantasyLogoDefaultHeight = 27;
const noovieTriviaLogoDefaultHeight = 23;
const chevronRightDefaultHeight = 24;

const NavbarAppsDropdown = () => (
  <Fragment>
    <div className="family-dropdown ">
      <div className="family-heading">
        <div className="text-center">
          {SVG.noovieLogo(noovieLogoDefaultHeight)}
          <div className="noovie-heading-text">
            We are what’s new in movies. Visit one of our family of movie
            brands:
          </div>
        </div>
      </div>
      <a href="/" className="family-item">
        <div className="media">
          <div className="media-body">
            <div className="family-image">
              {SVG.noovieCom(noovieComLogoDefaultHeight)}
            </div>
            <div className="noovie-com-text">
              Your personalized movie guide & source for what’s new in movies.
            </div>
          </div>
          <div className="chevron-right">
            {SVG.chevronRight(chevronRightDefaultHeight)}
          </div>
        </div>
      </a>
      <a href={config.noovieArcadeUrl} className="family-item">
        <div className="media">
          <div className="media-body">
            <div className="family-image">
              {SVG.noovieArcade(noovieArcadeLogoDefaultHeight)}
            </div>
            <div className="noovie-arcade-text">
              A revolutionary gaming experience that combines the movie
              theater’s big screen with Augmented Reality.
            </div>
          </div>
          <div className="chevron-right">
            {SVG.chevronRight(chevronRightDefaultHeight)}
          </div>
        </div>
      </a>
      <a href={config.noovieFMLUrl} className="family-item">
        <div className="media">
          <div className="media-body">
            <div className="family-image">
              {SVG.noovieFantasy(noovieFantasyLogoDefaultHeight)}
            </div>
            <div className="noovie-fantasy-text">
              Test your ability to predict box office success against others and
              win big.
            </div>
          </div>
          <div className="chevron-right">
            {SVG.chevronRight(chevronRightDefaultHeight)}
          </div>
        </div>
      </a>
      <a href={config.noovieShuffleUrl} className="family-item border-0">
        <div className="media">
          <div className="media-body">
            <div className="family-image">
              {SVG.noovieTrivia(noovieTriviaLogoDefaultHeight)}
            </div>
            <div className="noovie-trivia-text">
              Think you know movies? Prove it. Play, win, and be rewarded.
            </div>
          </div>
          <div className="chevron-right">
            {SVG.chevronRight(chevronRightDefaultHeight)}
          </div>
        </div>
      </a>
    </div>
  </Fragment>
);

export default NavbarAppsDropdown;
