import Movie from '../Movie';

export default class SearchResponse {
  constructor(response) {
    this._movies = response.search.movies.map(movie => new Movie(movie));
    this._total_results = response.search.total_results;
    this._paging = response.search.paging;
  }

  get movies() {
    return this._movies;
  }

  get total_results() {
    return this._total_results;
  }

  get paging() {
    return this._paging;
  }
}
