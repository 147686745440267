import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'flickity';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

import { addClass } from '../../utils/dom-helper';

export default class ListRowContainer extends PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    cellSelectorClass: PropTypes.string,
    renderItem: PropTypes.func,
    onChange: PropTypes.func,
    onStaticClick: PropTypes.func,
    wrapAround: PropTypes.bool,
    groupCells: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
      PropTypes.number,
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    cellSelectorClass: 'default-cell-selector',
    wrapAround: false,
    groupCells: true,
  };

  flktyWrapper = isBrowser ? React.createRef() : null;

  componentDidMount = () => {
    if (isBrowser) this.mountFlkty();
  };

  componentWillUnmount() {
    if (isBrowser) this.unmountFlkty();
  }

  mountFlkty = () => {
    if (!this.flkty && this.props.items.length) {
      const options = {
        accessibility: true,
        cellAlign: 'left',
        cellSelector: `.${this.props.cellSelectorClass}`,
        contain: false,
        groupCells: this.props.groupCells,
        pageDots: false,
        wrapAround: this.props.wrapAround,
        arrowShape:
          'M28.655 3.89L32 6.085 20.16 24.13l11.832 17.747-3.327 2.22-13.3-19.95z',
      };

      this.flkty = new Flickity(this.flktyWrapper.current, options);

      // Set viewBox of custom svg defined in arrowShape
      const prevButton = this.flkty.prevButton.element;
      const nextButton = this.flkty.nextButton.element;
      const prevButtonSVG = prevButton.firstChild;
      const nextButtonSVG = nextButton.firstChild;

      prevButtonSVG.setAttribute('viewBox', '0 0 48 48');
      nextButtonSVG.setAttribute('viewBox', '0 0 48 48');
      nextButtonSVG.firstChild.setAttribute(
        'transform',
        'translate(48, 48) rotate(180)'
      );

      // Hide nav buttons if both are disabled
      if (prevButton.disabled && nextButton.disabled) {
        addClass(prevButton, 'hidden');
        addClass(nextButton, 'hidden');
      }

      // Set event listeners
      this.flkty.on('change', this.handleChange);
      this.flkty.on('settle', this.resetShiftCells);

      if (this.props.onStaticClick) {
        this.flkty.on('staticClick', this.handleStaticClick);
      }

      this.flkty.resize();
    }
  };

  unmountFlkty = () => {
    if (this.flkty) {
      this.flkty.off('change', this.handleChange);
      this.flkty.off('settle', this.resetShiftCells);

      if (this.props.onStaticClick) {
        this.flkty.off('staticClick', this.handleStaticClick);
      }

      this.flkty.destroy();
    }
  };

  handleChange = index => {
    const { onChange, wrapAround } = this.props;

    if (onChange) onChange(index);

    if (wrapAround && !this.flkty.options.wrapAround) {
      this.flkty.options.wrapAround = this.props.wrapAround;
      // Updating Flickity wrapAround option after initial setup is not
      // technically supported, but has an undocumented function for this:
      // https://github.com/metafizzy/flickity/issues/733#issuecomment-379054327
      this.flkty._getWrapShiftCells();
      this.flkty.nextButton.element.disabled = false;
    }
  };

  resetShiftCells = index => {
    if (index === 1) {
      this.flkty.select(1, false, true);
      this.flkty.off('settle', this.resetShiftCells);
    }
  };

  handleStaticClick = (event, pointer, cellElement, cellIndex) => {
    const { onStaticClick } = this.props;

    if (onStaticClick) onStaticClick(event, pointer, cellElement, cellIndex);
  };

  renderItem = (item, key) => {
    const { renderItem } = this.props;

    if (renderItem) return renderItem(item, key);

    return (
      <div key={key} className="default-cell-selector">
        <div className="cell-container">{item.id}</div>
      </div>
    );
  };

  renderBrowser = () => {
    return (
      <div ref={this.flktyWrapper} className="flickity-wrapper">
        {this.props.items.map(this.renderItem)}
      </div>
    );
  };

  renderMobile = () => {
    return (
      <div className="scroll-wrapper">
        <div className="scroll">{this.props.items.map(this.renderItem)}</div>
      </div>
    );
  };

  render() {
    return (
      <div className="list-row-container">
        <BrowserView device={isBrowser}>{this.renderBrowser()}</BrowserView>

        <MobileView device={isMobile}>{this.renderMobile()}</MobileView>
      </div>
    );
  }
}
