import Image from './Image';
import Video from './Video';
import Contribution from './Contribution';
import List from './List';

import { NO_IMAGE_URL } from '../utils/constants';

export default class Contributor {
  constructor(options) {
    this._id = options.id;
    this._name = options.name;
    this._age = options.age;
    this._biography = options.biography;
    this._date_of_birth = options.date_of_birth;
    this._highlights = options.highlights;
    this._quotes = options.quotes;
    this._headshot_url = options.headshot_url || NO_IMAGE_URL;
    this._credits = options.credits;
    this._role = options.role;

    if (options.images && options.images.length > 0) {
      this._images = options.images.map(image => new Image(image));
    } else {
      this._images = [];
    }

    if (options.videos && options.videos.length > 0) {
      this._videos = options.videos.map(video => new Video(video));
    } else if (options.video) {
      this._videos = [new Video(options.video)];
    } else {
      this._videos = [];
    }

    if (options.contributions && options.contributions.length > 0) {
      this._contributions = options.contributions.map(
        contribution => new Contribution(contribution)
      );
    } else {
      this._contributions = [];
    }

    if (options.recent_projects && options.recent_projects.length > 0) {
      this._recent_projects = new List({ items: options.recent_projects });
    } else {
      this._recent_projects = new List({ items: [] });
    }
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get age() {
    return this._age;
  }

  get biography() {
    return this._biography;
  }

  get dateOfBirth() {
    return this._date_of_birth;
  }

  get images() {
    return this._images;
  }

  get videos() {
    return this._videos.filter(
      video => !(video.isRedband() || video.isHidden())
    );
  }

  get highlights() {
    return this._highlights;
  }

  get quotes() {
    return this._quotes;
  }

  get contributions() {
    return this._contributions;
  }

  get recentProjects() {
    return this._recent_projects;
  }

  get headshotUrl() {
    return this._headshot_url;
  }

  get credits() {
    return this._credits;
  }

  get role() {
    return this._role;
  }

  isDirector() {
    return this._role === 'director';
  }

  isCast() {
    return this._role === 'cast';
  }
}
