class ApiError {
  constructor(message, response) {
    this.message = message;
    this.response = response;
  }

  getMessage() {
    return this.message;
  }

  getResponse() {
    return this.response;
  }
}

export default class AbstractAPI {
  static appendQueryParams(url, params) {
    const queryString = Object.keys(params)
      .reduce((accumulator, key) => {
        const value = params[key];
        if (value) {
          if (value.constructor === Array) {
            value.forEach(item => accumulator.push(`${key}[]=${item}`));
          } else {
            accumulator.push(`${key}=${value}`);
          }
        }
        return accumulator;
      }, [])
      .join('&');

    return `${url}?${queryString}`;
  }

  static checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    throw new ApiError(response.statusText, response);
  }

  static parseJson(response) {
    return response.json();
  }
}
