import AbstractApi from './AbstractAPI';
import AvailabilitiesResponse from '../../models/responses/AvailabilitiesResponse';
import GowatchitMovieIdsResponse from '../../models/responses/GowatchitMovieIdsResponse';
import ENV from '../../config/env';

export default class GowatchitApi extends AbstractApi {
  constructor(accessToken = null) {
    super();

    this.apiKey = ENV.gowatchitAPIKey;
    this.apiHost = ENV.gowatchitAPIHost;
  }

  fetchGowatchitMovieIds = async tmsIds => {
    const params = {
      api_key: this.apiKey,
      id_type: 'tms_id',
      ids: [...tmsIds],
    };

    const response = await this.get(`/search/gowatchit_ids`, params);
    return new GowatchitMovieIdsResponse(response);
  };

  fetchMovieAvailabilities = async movieId => {
    const params = {
      api_key: this.apiKey,
    };

    const response = await this.get(
      `/movies/${movieId}/availabilities`,
      params
    );

    return new AvailabilitiesResponse(response);
  };

  get(url, params) {
    return this.query('GET', url, params);
  }

  query(method, url, params = {}, options = {}) {
    const finalParams = { ...params };

    let finalUrl = `${this.apiHost}${url}`;

    if (method === 'GET' && !!Object.keys(finalParams).length) {
      finalUrl = GowatchitApi.appendQueryParams(finalUrl, finalParams);
    }

    let fetchOptions = {
      method,
    };

    return fetch(finalUrl, fetchOptions)
      .then(GowatchitApi.checkStatus)
      .then(GowatchitApi.parseJson);
  }
}
