import { nooviePath } from '../utils/nooviePath';
import config from '../config/env';

const NCM_VIDEO_EXPERIENCE_BRICK_TYPE = 'video-experience';
const NOOVIE_TWITTER_HANDLE = '@WhatsNoovie';
const MEDIUM = 'website';

export default class VideoExperienceService {
  static openModalForPlaylist(playlistId, options = {}) {
    this.openModal({ playlistId, ...options });
  }

  static openModalForVideo(videoId, mediumContext, options = {}) {
    this.openModal(mediumContext, { videoId, ...options });
  }

  static openModalForMovie(movieId, mediumContext, options = {}) {
    this.openModal(mediumContext, { movieId, ...options });
  }

  static openModal(mediumContext, params) {
    const trackingContext = {
      origin: MEDIUM,
      originContext: mediumContext,
    };

    const adConfig = {
      adPre: true,
      adTagType: 'googima',
      adTagUrl: config.videoAdManifestUrl.replace(/&amp;/g, '&'),
    };

    adConfig.adTagUrl += `&correlator=${new Date().getTime()}`;

    const options = {
      ...trackingContext,
      ...adConfig,
      ...params,
      replaceState: true,
      share: true,
      twitterHandle: NOOVIE_TWITTER_HANDLE,
      brickType: NCM_VIDEO_EXPERIENCE_BRICK_TYPE,
    };

    window.GWIMason.openExperienceModal(options);

    this.setupPushState(options);
  }

  static setupPushState({ videoId, playlistId, brickType }) {
    window.history.pushState(
      { noovie: true },
      null,
      this.videoExperiencePath(videoId, playlistId)
    );

    window.onpopstate = () => {
      window.onpopstate = null;
      window.GWIMason.closeExperienceModal({ brickType });
    };
  }

  static videoExperiencePath(videoId, playlistId = null) {
    const { pathname } = window.location;

    let path = `/videos/${videoId}`;
    const params = {};

    if (playlistId) {
      path = `/playlists/${playlistId}`;
      if (videoId) path += `/videos/${videoId}`;
    }

    if (pathname !== '/') {
      params.redirect_to = pathname;
    }

    return nooviePath(path, params);
  }
}
