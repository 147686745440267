import Theater from '../Theater';

export default class NearbyTheatersDetailResponse {
  constructor(response) {
    if (response.theaters && response.theaters.length > 0) {
      this._nearby_theaters = response.theaters.map(
        theater => new Theater(theater)
      );
    } else {
      this._nearby_theaters = [];
    }
  }

  get nearbyTheaters() {
    return this._nearby_theaters;
  }
}
