import Url from '../Url';

export default class UrlResponse {
  constructor(response) {
    this._url = new Url(response.url);
  }

  get url() {
    return this._url;
  }
}
