import lscache from 'lscache';
import GowatchitApi from '../models/api/GowatchitAPI';

export default class MovieAvailabilitiesService {
  static async getAvailabilitiesForMovies(movies) {
    const api = new GowatchitApi();

    const tmsIds = [];
    movies.forEach(movie => {
      if (movie.tmsId) tmsIds.push(movie.tmsId);
    });

    const { gowatchitMovieIds } = await api.fetchGowatchitMovieIds(tmsIds);

    let formattedAvailabilities = [];

    for (const gowatchitMovieId of gowatchitMovieIds) {
      const formattedAvailability = { tmsId: gowatchitMovieId.tmsId };

      const cachedAvailabilities = lscache.get(gowatchitMovieId.tmsId);
      if (cachedAvailabilities) {
        formattedAvailability.availabilities = cachedAvailabilities;
      } else {
        const { availabilities } = await api.fetchMovieAvailabilities(
          gowatchitMovieId.id
        );
        lscache.set(gowatchitMovieId.tmsId, availabilities);
        formattedAvailability.availabilities = availabilities;
      }
      formattedAvailabilities.push(formattedAvailability);
    }

    return formattedAvailabilities;
  }
}
