import Video from './Video';
import { TRAILER_BUTTON_NAME, SHOWTIMES_BUTTON_NAME } from '../utils/constants';

export default class CarouselItemButton {
  constructor(options = {}) {
    this._id = options.id;
    this._carousel_item_id = options.carousel_item_id;
    this._watchable_id = options.watchable_id;
    this._button_type = options.button_type;
    this._button_text = options.button_text;
    this._url = options.url;
    this._new_tab = options.new_tab;
    this._background_image_url = options.background_image_url;
    this._video_id = options.video_id;
    this._position = options.position;

    if (options.video) {
      this._video = new Video(options.video);
    }
  }

  get id() {
    return this._id;
  }

  get carouselItemId() {
    return this._carousel_item_id;
  }

  get watchableId() {
    return this._watchable_id;
  }

  get type() {
    return this._button_type;
  }

  get text() {
    return this._button_text;
  }

  get url() {
    return this._url;
  }

  get newTab() {
    return !!this._new_tab;
  }

  get backgroundImageUrl() {
    return this._background_image_url;
  }

  get videoId() {
    return this._video_id;
  }

  get video() {
    return this._video;
  }

  get position() {
    return this._position;
  }

  get isCustomButton() {
    return !this.isTrailerButton && !this.isShowtimesButton;
  }

  get isTrailerButton() {
    return this.type === TRAILER_BUTTON_NAME;
  }

  get isShowtimesButton() {
    return this.type === SHOWTIMES_BUTTON_NAME;
  }
}
