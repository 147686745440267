import $ from 'jquery';

import UrlResponse from '../responses/UrlResponse';
import MovieDetailResponse from '../responses/MovieDetailResponse';
import TheaterDetailResponse from '../responses/TheaterDetailResponse';
import TheatersDetailResponse from '../responses/TheatersDetailResponse';
import CarouselResponse from '../responses/CarouselResponse';
import ListResponse from '../responses/ListResponse';
import StoryResponse from '../responses/StoryResponse';
import SearchResponse from '../responses/SearchResponse';
import TheatricalMovieListResponse from '../responses/TheatricalMovieListResponse';
import TheatersListResponse from '../responses/theaters-list-response';
import AmenitiesResponse from '../responses/AmenitiesResponse';
import NearbyTheatersDetailResponse from '../responses/NearbyTheatersDetailResponse';
import ZipcodeResponse from '../responses/ZipcodeResponse';
import ContentFeedResponse from '../responses/ContentFeedResponse';
import WeeklyBoxOffice from '../WeeklyBoxOffice';

import AbstractAPI from './AbstractAPI';
import ENV from '../../config/env';

export default class NoovieAPI extends AbstractAPI {
  constructor(authToken = null, options = {}) {
    super();

    // TODO: put in app config
    this.apiKey = ENV.noovieAPIKey;
    this.authToken = authToken;
    this.apiHost = ENV.noovieAPIHost;
  }

  addFavoriteTheater(id, params = {}) {
    return this.post(`/theaters/${id}/favorite`, params);
  }

  removeFavoriteTheater(id, params = {}) {
    return this.delete(`/theaters/${id}/favorite`, params);
  }

  search(term, offset = 0, params = {}) {
    const options = {
      term: encodeURIComponent(term),
      offset,
      ...params,
    };

    return this.get('/search', options).then(
      response => new SearchResponse(response)
    );
  }

  getShortURL(url) {
    const params = { url };

    return this.post('/urls', params).then(
      response => new UrlResponse(response)
    );
  }

  getContentFeed(params = {}) {
    return this.get('/noovie/detail/content_feed', params).then(response => {
      return new ContentFeedResponse(response);
    });
  }

  getFavoriteTheaters(params = {}) {
    const queryParams = {
      date: params.date || null,
      lat: params.lat || null,
      lng: params.lng || null,
    };

    return this.get('/noovie/detail/theaters/favorites', queryParams).then(
      response => new TheatersDetailResponse(response)
    );
  }

  getMoreContent(paginatedResponse) {
    const { nextPageUrl } = paginatedResponse.links;
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.apiKey,
        'X-auth-token': this.authToken,
        'X-gwi-asset': 'true',
      },
    };

    return fetch(nextPageUrl, fetchOptions)
      .then(NoovieAPI.checkStatus)
      .then(NoovieAPI.parseJson)
      .then(response => new ContentFeedResponse(response));
  }

  getMovie(id, params = {}) {
    return this.get(`/noovie/detail/movies/${id}`, params).then(
      response => new MovieDetailResponse(response)
    );
  }

  getTheater(id, params = {}) {
    return this.get(`/noovie/detail/theaters/${id}`, params).then(
      response => new TheaterDetailResponse(response)
    );
  }

  getNearbyTheaters(id, params = {}) {
    return this.get(`/theaters/${id}/nearby`, params).then(
      response => new NearbyTheatersDetailResponse(response)
    );
  }

  getTheaters(params = {}) {
    const queryParams = {
      movie_ids: params.movieIds || null,
      date: params.date,
      zipcode: params.zipcode ? params.zipcode : null,
      lat: params.lat || null,
      lng: params.lng || null,
      radius: params.radius || 15,
      limit: params.limit || 10,
      offset: params.offset || null,
      amenities: params.amenities || null,
    };

    return this.get('/noovie/detail/theaters', queryParams).then(
      response => new TheatersDetailResponse(response)
    );
  }

  getMoreTheaters(nextPageUrl) {
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.apiKey,
        'X-auth-token': this.authToken,
        'X-gwi-asset': 'true',
      },
    };

    return fetch(nextPageUrl, fetchOptions)
      .then(NoovieAPI.checkStatus)
      .then(NoovieAPI.parseJson)
      .then(response => new TheatersDetailResponse(response));
  }

  getCarousel(systemName) {
    return this.get(`/carousels/${systemName}`).then(
      response => new CarouselResponse(response)
    );
  }

  getList(id, params = {}) {
    const queryParams = {
      limit: params.limit || null,
      offset: params.offset || null,
    };

    return this.get(`/noovie/lists/${id}`, queryParams).then(
      response => new ListResponse(response)
    );
  }

  getSpotlights(id, params = {}) {
    const queryParams = {
      limit: params.limit || null,
      offset: params.offset || null,
    };

    return this.get(`/noovie/lists/${id}`, queryParams);
  }

  getUrlRedirect(key) {
    return this.get(`/urls/${key}`);
  }

  getStory(id, params = {}) {
    return this.get(`/noovie/stories/${id}`).then(
      response => new StoryResponse(response)
    );
  }

  getMoreResults(paginatedResponse) {
    const { nextPageUrl } = paginatedResponse.links;
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.apiKey,
        'X-auth-token': this.authToken,
        'X-gwi-asset': 'true',
      },
    };

    return fetch(nextPageUrl, fetchOptions)
      .then(NoovieAPI.checkStatus)
      .then(NoovieAPI.parseJson)
      .then(response => new ListResponse(response));
  }

  getTheatricalList() {
    return this.get('/noovie/detail/movies/theatrical').then(
      response => new TheatricalMovieListResponse(response)
    );
  }

  getFavoriteTheatersList(params = {}) {
    return this.get('/lists/theater_list', params).then(
      response => new TheatersListResponse(response)
    );
  }

  getAmenities() {
    return this.get('/amenities').then(
      response => new AmenitiesResponse(response)
    );
  }

  getZipcode(lat, lng) {
    const params = { lat, lng };

    return this.get('/geo/zipcode', params).then(
      response => new ZipcodeResponse(response)
    );
  }

  getBoxOffice() {
    return this.get('/noovie/box_office').then(
      response => new WeeklyBoxOffice(response)
    );
  }

  get(url, params) {
    return this.query('GET', url, params);
  }

  post(url, params, options = {}) {
    return this.query('POST', url, params, options);
  }

  put(url, params) {
    return this.query('PUT', url, params);
  }

  delete(url, params) {
    return this.query('DELETE', url, params);
  }

  query(method, url, params = {}, options = {}) {
    let finalUrl = `${this.apiHost}/api/v3${url}`;

    if (method === 'GET' && !!Object.keys(params).length) {
      finalUrl = NoovieAPI.appendQueryParams(finalUrl, params);
    }

    const defaultData = {};
    // Add extra params, if any
    let data = $.extend(defaultData, params);

    if (options.stringifyParams) {
      data = JSON.stringify(data);
    }

    let fetchOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': this.apiKey,
        'X-auth-token': this.authToken,
        'X-gwi-asset': 'true',
      },
    };

    if (method === 'PUT' || method === 'POST') {
      fetchOptions = $.extend(fetchOptions, { body: JSON.stringify(data) });
    }

    return fetch(finalUrl, fetchOptions)
      .then(NoovieAPI.checkStatus)
      .then(NoovieAPI.parseJson);
  }
}
