import moment from 'moment/moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Responsive } from 'semantic-ui-react';
import AdContainer, { bottom, mid1, top } from '../components/AdContainer';
import LargeCard from '../components/cards/LargeCard';
import SmallCard from '../components/cards/SmallCard';
import InTheatersPosterRows from '../components/list-row/InTheatersPosterRows';
import NavbarContext from '../components/navigation/navbar-context';
import SectionTitle from '../components/SectionTitle';
import SimpleDateSelector from '../components/simple-date-selector';
import SpotlightContainer from '../components/SpotlightContainer';
import FavoriteTheaters from '../components/theaters/favorite-theaters';
import MovieCardsRow from '../components/in-theaters/MovieCardsRow';
import NoovieAPI from '../models/api/NoovieAPI';
import TrackingService from '../services/tracking_service';
import MediumCard from '../components/cards/MediumCard';
import WeeklyBoxOffice from '../components/sidebar-modules/WeeklyBoxOffice';
import {
  bigTabletBreakpoint,
  desktopBreakpoint,
  smallMobileBreakpoint,
} from '../utils/responsive-breakpoints';
import * as ShowtimesService from '../services/showtimes_service';
import UserService from '../services/UserService';

const MEDIUM_CONTEXT = 'in_theaters_page';
const defaultTrackingOptions = {
  mediumContext: MEDIUM_CONTEXT,
};
const MOVIE_NEWS_TITLE = 'Movie News';
const SPOTLIGHT_CALL_TO_ACTION_TEXT = 'See Showtimes';

const InTheaters = () => {
  const [isMyTheatersLoading, setIsMyTheatersLoading] = useState(true);
  const [posterRows, setPosterRows] = useState({});
  const [list, setList] = useState({});
  const [movieNewsList, setMovieNewsList] = useState({});
  const [spotlight, setSpotlight] = useState({});
  const [weeklyBoxOffice, setWeeklyBoxOffice] = useState({});
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [myTheaters, setMyTheaters] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { setDarkTheme } = useContext(NavbarContext);

  const userAuthToken = UserService.getUser()
    ? UserService.getUser().authToken
    : null;

  const api = new NoovieAPI(userAuthToken);

  const trackListImpression = listId => {
    TrackingService.trackListImpression(listId, defaultTrackingOptions);
  };

  const trackListItemClick = (listId, listItem) => {
    TrackingService.trackListItemClick(
      listId,
      listItem.id,
      defaultTrackingOptions
    );
  };

  const trackListScroll = listId => {
    TrackingService.trackListScroll(listId, defaultTrackingOptions);
  };

  const trackCallToActionClick = (listId, listItem) => {
    const contextType = 'List';
    const contextId = listId;

    const options = {
      ...defaultTrackingOptions,
      customData: listItem.callToActionText || listItem.annotation,
    };

    TrackingService.trackCallToActionClick(contextType, contextId, options);
  };

  const trackTheaterClick = theaterId => {
    TrackingService.trackTheaterClick(theaterId, defaultTrackingOptions);
  };

  const trackTheaterFavorite = (theaterId, isFavorite) => {
    const options = {
      ...defaultTrackingOptions,
      customData: isFavorite ? 'favorited' : 'unfavorited',
    };

    TrackingService.trackTheaterFavorite(theaterId, options);
  };

  const trackProviderClick = (theaterId, providerFormatId) => {
    const contextType = 'Theater';
    const contextId = theaterId;

    TrackingService.trackProviderClick(
      contextType,
      contextId,
      providerFormatId,
      defaultTrackingOptions
    );
  };

  const trackSimpleDateSelectorClick = () => {
    const contextType = null;
    const contextId = null;

    TrackingService.trackSimpleDateSelectorInteraction(
      contextType,
      contextId,
      defaultTrackingOptions
    );
  };

  useEffect(() => {
    const initialLoad = async () => {
      setLoaded(true);
      const weeklyBoxOfficeResponse = await api.getBoxOffice();
      setWeeklyBoxOffice(weeklyBoxOfficeResponse);

      const posterRowsResponse = await api.getList('in_theaters_poster_rows');
      setPosterRows(posterRowsResponse.list);
      trackListImpression(posterRowsResponse.list.items[0].id);

      const movieNewsListResponse = await api.getList('in_theaters_movie_news');
      setMovieNewsList(movieNewsListResponse.list.items);
      trackListImpression(movieNewsListResponse.list.id);

      const spotlightResponse = await api.getSpotlights(
        'in_theaters_spotlight'
      );
      setSpotlight(spotlightResponse.list.items[0]);
      trackListImpression(spotlightResponse.list.id);

      const { list } = await api.getList('in_theaters_movie_cards');
      setList(list);
      trackListImpression(list.id);

      if (UserService.getUser()) {
        const myTheatersResponse = await getMyTheaters(date);

        setMyTheaters(myTheatersResponse.theaters);
      }

      setIsMyTheatersLoading(false);
    };

    if (!loaded) {
      setDarkTheme(true);
      initialLoad();
    }
  });

  useEffect(() => {
    const trackPageView = () => {
      TrackingService.trackPageView(MEDIUM_CONTEXT);
    };

    trackPageView();
  }, []);

  const getMyTheaters = async date => {
    const browserLocation = await ShowtimesService.getBrowserLocationParams();

    const params = { ...browserLocation, date };

    const myTheaters = await api.getFavoriteTheaters(params);

    return myTheaters;
  };

  const handleDateSelectorClick = async date => {
    await setDate(date);

    const myTheatersResponse = await getMyTheaters(date);

    setMyTheaters(myTheatersResponse.theaters);

    trackSimpleDateSelectorClick();
  };

  const handleSpotlightCallToActionClick = spotlight => () => {
    const contextType = 'List';
    const contextId = spotlight.list_id;
    const options = {
      ...defaultTrackingOptions,
      customData: SPOTLIGHT_CALL_TO_ACTION_TEXT,
      listItemId: spotlight.id,
    };

    TrackingService.trackCallToActionClick(contextType, contextId, options);
  };

  const renderPosterRows = posterRows => (
    <InTheatersPosterRows
      posterRows={posterRows}
      onTrackImpression={trackListImpression}
      onTrackClick={trackListItemClick}
      onTrackScroll={trackListScroll}
      onTrackCTAClick={trackCallToActionClick}
    />
  );

  const renderMovieNews = movieNewsList => (
    <Fragment>
      <SectionTitle titleText={MOVIE_NEWS_TITLE} />
      <LargeCard content={movieNewsList[0]} />
      <Responsive
        minWidth={smallMobileBreakpoint}
        maxWidth={bigTabletBreakpoint - 1}
      >
        {movieNewsList[1] && (
          <MediumCard align="left" content={movieNewsList[1]} />
        )}
        <AdContainer
          id="home-content-feed-slot"
          className="ad-centered ad-bottom-margin"
          unit="noovie.com/homepage"
          position={top}
          sizeMapping={[
            {
              browser: [0, 0],
              slot: [300, 250],
            },
          ]}
        />
        {movieNewsList[2] && (
          <MediumCard align="right" content={movieNewsList[2]} />
        )}
        {movieNewsList[3] && (
          <MediumCard align="left" content={movieNewsList[3]} />
        )}
      </Responsive>
      <Responsive
        minWidth={bigTabletBreakpoint}
        maxWidth={desktopBreakpoint - 1}
      >
        <div className="small-card-container">
          {movieNewsList[1] && <SmallCard content={movieNewsList[1]} />}
          {movieNewsList[2] && <SmallCard content={movieNewsList[2]} />}
        </div>
      </Responsive>
      <Responsive minWidth={desktopBreakpoint}>
        <div className="small-card-container">
          {movieNewsList[1] && <SmallCard content={movieNewsList[1]} />}
          {movieNewsList[2] && <SmallCard content={movieNewsList[2]} />}
          {movieNewsList[3] && <SmallCard content={movieNewsList[3]} />}
        </div>
      </Responsive>
    </Fragment>
  );

  const renderSpotlight = align => (
    <SpotlightContainer
      align={align}
      callToActionText={SPOTLIGHT_CALL_TO_ACTION_TEXT}
      onCallToActionClick={handleSpotlightCallToActionClick(spotlight)}
      spotlight={spotlight}
    />
  );

  const renderAd = (position, width, height) => {
    const adClassName =
      bottom === position ? 'ad-centered ad-end-margin' : 'ad-end-margin';
    return (
      <AdContainer
        id={`in-theaters-${position}`}
        className={adClassName}
        position={position}
        unit="noovie.com/in-theaters"
        sizeMapping={[
          {
            browser: [0, 0],
            slot: [[width, height]],
          },
        ]}
      />
    );
  };

  const renderAltPicks = list => <MovieCardsRow movieList={list} />;

  const renderDateSelector = () => (
    <Fragment>
      <Responsive
        className="simple-date-selector-component-container"
        minWidth={smallMobileBreakpoint}
        maxWidth={bigTabletBreakpoint - 1}
      >
        <SimpleDateSelector
          numberOfDates={11}
          onDateClick={handleDateSelectorClick}
        />
      </Responsive>
      <Responsive
        className="simple-date-selector-component-container"
        minWidth={bigTabletBreakpoint}
        maxWidth={desktopBreakpoint - 1}
      >
        <SimpleDateSelector
          numberOfDates={5}
          onDateClick={handleDateSelectorClick}
        />
      </Responsive>
      <Responsive
        className="simple-date-selector-component-container"
        minWidth={desktopBreakpoint}
      >
        <SimpleDateSelector
          numberOfDates={7}
          onDateClick={handleDateSelectorClick}
        />
      </Responsive>
    </Fragment>
  );

  const renderFavoriteTheaters = () => (
    <FavoriteTheaters
      favoriteTheaters={myTheaters}
      onTrackTheaterClick={trackTheaterClick}
      onTrackTheaterFavorite={trackTheaterFavorite}
      onTrackProviderClick={trackProviderClick}
    />
  );

  return (
    <div className="in-theaters">
      <div className="page-title-wrapper">
        <div className="page-title-container">
          <div className="page-title">In Theaters</div>
        </div>
      </div>

      <div className="poster-rows-list-wrapper">
        <div className="poster-rows-list-container">
          {posterRows && renderPosterRows(posterRows)}
        </div>
      </div>

      <div className="in-theaters-content-container">
        <div className="main-column-container">
          <div className="movie-news-wrapper">
            <div className="movie-news-container">
              {movieNewsList.length > 0 && renderMovieNews(movieNewsList)}
            </div>
          </div>
        </div>

        <div className="sidebar-column-container">
          <Responsive minWidth={bigTabletBreakpoint}>
            {renderAd(mid1, 300, 250)}
          </Responsive>
          <WeeklyBoxOffice boxOffice={weeklyBoxOffice} />
        </div>
      </div>

      <div className="spotlight-container-component-holder">
        {spotlight && renderSpotlight('right')}
      </div>

      <div className="movie-card-container-component-holder">
        {list.items && renderAltPicks(list)}
      </div>

      <div className="my-theaters-container">
        {myTheaters.length > 0 && renderDateSelector()}
        {!isMyTheatersLoading && renderFavoriteTheaters()}
      </div>

      {/* Bottom Ad  */}
      <Responsive maxWidth={bigTabletBreakpoint - 1}>
        {renderAd(bottom, 300, 250)}
      </Responsive>
      <Responsive minWidth={bigTabletBreakpoint}>
        {renderAd(bottom, 728, 90)}
      </Responsive>
    </div>
  );
};

export default InTheaters;
