import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ListRowContainer from '../list-row/ListRowContainer';
import PosterTile from '../cards/PosterTile';

import Movie from '../../models/Movie';

const FlickityPosterRow = props => {
  const { posterRowName, posterRowMovies, onTrackScroll, onTrackClick } = props;

  return (
    <Fragment>
      <p className="streaming-flickity-row-header">{posterRowName}</p>
      <ListRowContainer
        items={posterRowMovies}
        cellSelectorClass="poster-movie-cell-selector"
        renderItem={movie => PosterTile(movie, onTrackClick)}
        onChange={onTrackScroll}
      />
    </Fragment>
  );
};

FlickityPosterRow.propTypes = {
  id: PropTypes.number.isRequired,
  posterRowName: PropTypes.string.isRequired,
  posterRowMovies: PropTypes.arrayOf(PropTypes.instanceOf(Movie)).isRequired,
  onTrackClick: PropTypes.func.isRequired,
  onTrackScroll: PropTypes.func.isRequired,
};

export default FlickityPosterRow;
