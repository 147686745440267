import React from 'react';
import PropTypes from 'prop-types';

const CardLabel = ({ label }) => (
  <div className="card-label-container">
    <div className="card-label">
      <p>{label}</p>
    </div>
  </div>
);

CardLabel.propTypes = {
  label: PropTypes.string,
};

export default CardLabel;
