export default class VideoVersion {
  constructor(options) {
    this._aspect_ratio = options.aspect_ratio;
    this._video_codec = options.video_codec;
    this._bitrate = options.bitrate;
    this._screening_format = options.screening_format;
    this._container = options.container;
    this._mime_type = options.mime_type;
    this._width = options.width;
    this._height = options.height;
    this._url = options.url;
  }

  get aspectRatio() {
    return this._aspect_ratio;
  }

  get videoCodec() {
    return this._video_codec;
  }

  get bitrate() {
    return this._bitrate;
  }

  get screeningFormat() {
    return this._screening_format;
  }

  get container() {
    return this._container;
  }

  get mimeType() {
    return this._mime_type;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get url() {
    return this._url;
  }

  get quality() {
    return this._quality;
  }

  set quality(quality) {
    this._quality = quality;
  }
}
