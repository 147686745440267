import React from 'react';
import PropTypes from 'prop-types';

import SectionTitle from '../SectionTitle';

import CallToAction from '../links/call-to-action';
import { buildNNoovieLink } from '../../utils/nooviePath';

const WeeklyBoxOffice = ({ boxOffice: { weeklyBoxOffice } }) => {
  const boxOfficeHeaderText = 'BOX OFFICE RANKINGS';

  const renderBoxOfficeMovie = (boxOfficeMovie, index) => (
    <div
      key={boxOfficeMovie.movie.id}
      className="in-theaters-box-office box-office-movie-container"
    >
      <div
        className="box-office-movie-poster"
        style={{ backgroundImage: `url("${boxOfficeMovie.movie.posterUrl}")` }}
      >
        <div className="box-office-movie-poster-overlay" />
      </div>

      <div className="box-office-movie-detail">
        <div className="box-office-movie-rank">{index + 1}</div>

        <div>
          <div className="box-office-movie-title">
            {boxOfficeMovie.movie.title}
          </div>

          <div className="box-office-movie-gross">
            {`$${parseFloat(
              (boxOfficeMovie.weekendGross / 1000000).toFixed(1)
            )}M`}
            &nbsp;•&nbsp;
            {`$${parseFloat(
              (boxOfficeMovie.totalLifetimeGross / 1000000).toFixed(1)
            )}M`}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <a href={buildNNoovieLink('top-box-office')}>
      <div className="weekly-box-office-container">
        <SectionTitle titleText={boxOfficeHeaderText} boldWordCount={2} />
        {weeklyBoxOffice &&
          weeklyBoxOffice.slice(0, 3).map(renderBoxOfficeMovie)}
        <CallToAction callToAction="View All Rankings" />
      </div>
    </a>
  );
};

WeeklyBoxOffice.propTypes = {
  boxOffice: PropTypes.shape({
    list: PropTypes.object,
    weeklyBoxOffice: PropTypes.array,
  }).isRequired,
};

export default WeeklyBoxOffice;
