import Owner from './Owner';

export default class Gallery {
  constructor(options = {}) {
    this._id = options.id;
    this._title = options.title;
    this._description = options.description;
    this._body_content = options.body_content;
    this._image = options.image;
    this._images = options.images;
    this._image_count = options.image_count;
    this._published_at = options.published_at;
    this._updated_at = options.updated_at;
    this._created_at = options._created_at;
    this._url = options.url;
    this._slug = options.slug;
    this._owner = new Owner(options.owner);
    this._type = options.type;
    this._list_id = options.list_id;
  }

  static fromListItem(item) {
    const options = {
      ...item,
      id: item.listable_id,
      description: item.annotation,
      image: item.image_location_url,
      updated_at: item.date,
      type: item.listable_type,
    };

    return new Gallery(options);
  }

  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get bodyContent() {
    return JSON.parse(this._body_content);
  }

  get image() {
    return this._image;
  }

  get images() {
    return this._images;
  }

  get imageCount() {
    return this._image_count;
  }

  get publishedAt() {
    return this._published_at;
  }

  get updatedAt() {
    return this._updated_at;
  }

  get createdAt() {
    return this._created_at;
  }

  get url() {
    return this._url;
  }

  get slug() {
    return this._slug;
  }

  get owner() {
    return this._owner;
  }

  get type() {
    return this._type;
  }

  get listId() {
    return this._list_id;
  }
}
