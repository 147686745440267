import List from './List';
import Playlist from './Playlist';
import Story from './Story';
import Gallery from './Gallery';

export default class ContentFeed {
  constructor(options = {}) {
    if (options.content_feed && options.content_feed.length > 0) {
      this._items = options.content_feed.map(item => {
        switch (item.type) {
          case 'List':
            return new List(item);
          case 'Playlist':
            return new Playlist(item);
          case 'Story':
            return new Story(item);
          case 'Gallery':
            return new Gallery(item);
          default:
            return null;
        }
      });
    } else {
      this._items = [];
    }
  }

  get items() {
    return this._items;
  }

  set items(newItems) {
    this._items = newItems;
  }
}
