import Amenity from '../Amenity';

export default class AmenitiesResponse {
  constructor(response) {
    if (response.amenities && response.amenities.length > 0) {
      this._amenities = response.amenities.map(amenity => new Amenity(amenity));
    } else {
      this._amenities = [];
    }
  }

  get amenities() {
    return this._amenities;
  }
}
