import GowatchitMovieId from '../GowatchitMovieId';

export default class GowatchitMovieIdsResponse {
  constructor(response) {
    if (response.watchables && response.watchables.length) {
      this._gowatchitMovieIds = response.watchables.map(watchable => {
        if (watchable.watchable_type === 'Movie') {
          return new GowatchitMovieId(watchable);
        }
        return null;
      });
    } else {
      this._gowatchitMovieIds = [];
    }
  }

  get gowatchitMovieIds() {
    return this._gowatchitMovieIds;
  }
}
