import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import { desktopBreakpoint } from '../../utils/responsive-breakpoints';
import { buildRedirect } from '../../utils/nooviePath';
import CallToAction from '../links/call-to-action';
import PosterTile from '../cards/PosterTile';
import ListRowContainer from './ListRowContainer';

export default function InTheatersPosterRows(props) {
  const {
    posterRows,
    onTrackImpression,
    onTrackClick,
    onTrackScroll,
    onTrackCTAClick,
  } = props;

  const [selectedPosterRow, setSelectedPosterRow] = useState({});

  const selectPosterRow = async item => {
    // Fix bug where Flickity does not unmount properly
    // because of conditional rendering
    await setSelectedPosterRow({});

    setSelectedPosterRow(item);
  };

  useEffect(() => {
    if (posterRows.items && posterRows.items.length) {
      selectPosterRow(posterRows.items[0]);
    }
  }, [posterRows]);

  const handleTrackClick = movie => {
    const listId = posterRows.id;

    if (listId) {
      onTrackClick(listId, movie);
    }
  };

  const handleTrackScroll = () => {
    const listItemId = selectedPosterRow.id;

    if (listItemId) {
      onTrackScroll(listItemId);
    }
  };

  const handleTabClick = item => {
    selectPosterRow(item);

    onTrackImpression(item.id);
  };

  const renderTab = item => {
    let activeClass = '';
    if (selectedPosterRow.name && selectedPosterRow.name === item.name) {
      activeClass = 'active';
    }

    return (
      <div
        key={item.name}
        className={`poster-row-tab ${activeClass}`}
        onClick={() => handleTabClick(item)}
      >
        {item.name}
      </div>
    );
  };

  return (
    <div className="poster-rows-container">
      <div className="poster-row-tabs-container">
        <div className="poster-row-tabs">
          {posterRows.items && posterRows.items.map(renderTab)}
        </div>

        {selectedPosterRow.name && (
          <Responsive
            className="call-to-action-container"
            minWidth={desktopBreakpoint}
          >
            <CallToAction
              callToAction={`See All ${selectedPosterRow.name}`}
              href={buildRedirect(selectedPosterRow)}
              handleClick={() =>
                onTrackCTAClick(posterRows.id, selectedPosterRow)
              }
              fill={'white'}
            />
          </Responsive>
        )}
      </div>

      {selectedPosterRow.movies && selectedPosterRow.movies.length && (
        <div className="poster-row-movies">
          <ListRowContainer
            cellSelectorClass="poster-movie-cell-selector"
            items={selectedPosterRow.movies}
            renderItem={movie => PosterTile(movie, handleTrackClick)}
            onChange={handleTrackScroll}
          />
        </div>
      )}

      {selectedPosterRow.name && (
        <Responsive
          className="call-to-action-container"
          maxWidth={desktopBreakpoint - 1}
        >
          <CallToAction
            callToAction={`See All ${selectedPosterRow.name}`}
            href={buildRedirect(selectedPosterRow)}
            handleClick={() =>
              onTrackCTAClick(posterRows.id, selectedPosterRow)
            }
            fill={'white'}
          />
        </Responsive>
      )}
    </div>
  );
}

InTheatersPosterRows.propTypes = {
  posterRows: PropTypes.object.isRequired,
  onTrackImpression: PropTypes.func.isRequired,
  onTrackClick: PropTypes.func.isRequired,
  onTrackScroll: PropTypes.func.isRequired,
  onTrackCTAClick: PropTypes.func.isRequired,
};
