import Pagination from './Pagination';

export default class Meta {
  constructor(options = {}) {
    this._pagination = new Pagination(options.pagination);
  }

  get pagination() {
    return this._pagination;
  }
}
