import React from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import SmallStreamingCard from '../cards/SmallStreamingCard';
import SectionTitle from '../SectionTitle';

import {
  tabletBreakpoint,
  bigTabletBreakpoint,
  desktopBreakpoint,
} from '../../utils/responsive-breakpoints';

// SmallPosterRow(posterRowMovies, isAdColumn):
const SmallPosterRow = props => {
  const { isAdColumn, posterRow } = props;
  const rowTitle = posterRow.name;
  const posterRowMovies = posterRow.movies;

  return (
    <div className="small-poster-row">
      <SectionTitle titleText={rowTitle} />
      {/* 375px SmallPosterRows: */}
      <Responsive maxWidth={tabletBreakpoint - 1}>
        <div className="small-poster-row-bottom-cards">
          {posterRowMovies.slice(0, 4).map((movie, index) => (
            <SmallStreamingCard key={movie.id} movie={movie} index={index} />
          ))}
        </div>
      </Responsive>

      {/* 720px SmallPosterRows: */}
      <Responsive
        minWidth={tabletBreakpoint}
        maxWidth={bigTabletBreakpoint - 1}
      >
        <div className="small-poster-row-bottom-cards">
          {posterRowMovies.slice(0, 3).map(movie => (
            <SmallStreamingCard key={movie.id} movie={movie} />
          ))}
        </div>
      </Responsive>

      {/* 1020px SmallPosterRows: */}
      {/* Note: For bigTablet and desktop breakpoints, the top-rightmost */}
      {/* SmallPosterRow only, shares its column with an ad. Since this can conditionally */}
      {/* determine the SPR's tile displayNumber, a boolean 'isAdColumn' is introduced: */}
      {/* --------------------------------------------------------------------------- */}
      <Responsive
        minWidth={bigTabletBreakpoint}
        maxWidth={desktopBreakpoint - 1}
      >
        <div className="small-poster-row-bottom-cards">
          {/* isAdColumn ? render 5 movies : render 7 movies */}
          {isAdColumn
            ? posterRowMovies
                .slice(0, 5)
                .map(movie => (
                  <SmallStreamingCard key={movie.id} movie={movie} />
                ))
            : posterRowMovies
                .slice(0, 7)
                .map(movie => (
                  <SmallStreamingCard key={movie.id} movie={movie} />
                ))}
        </div>
      </Responsive>

      {/* 1440px+ SmallPosterRows: */}
      <Responsive minWidth={desktopBreakpoint}>
        <div className="small-poster-row-bottom-cards">
          {/* isAdColumn ? render 4 movies : render 6 movies */}
          {isAdColumn
            ? posterRowMovies
                .slice(0, 4)
                .map(movie => (
                  <SmallStreamingCard key={movie.id} movie={movie} />
                ))
            : posterRowMovies
                .slice(0, 6)
                .map(movie => (
                  <SmallStreamingCard key={movie.id} movie={movie} />
                ))}
        </div>
      </Responsive>
    </div>
  );
};

// props.isAdColumn:
// if this SmallPosterRow appears in the same
// column as an Ad, its displayNumber will be different.
SmallPosterRow.propTypes = {
  posterRow: PropTypes.object.isRequired,
  isAdColumn: PropTypes.bool,
};

SmallPosterRow.defaultProps = {
  isAdColumn: false,
};

export default SmallPosterRow;
