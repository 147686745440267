import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import AvailabilitiesRow from '../streaming/AvailabilitiesRow';

import GowatchitAPI from '../../models/api/GowatchitAPI';
import Movie from '../../models/Movie';
import { buildNNoovieLink } from '../../utils/nooviePath';
import {
  tabletBreakpoint,
  bigTabletBreakpoint,
  desktopBreakpoint,
} from '../../utils/responsive-breakpoints';

function renderImage(movie) {
  return (
    <div
      className="small-streaming-card-image-container"
      style={{ backgroundImage: `url(${movie.iconicImageUrl})` }}
    />
  );
}

// For mobile view only.
// SmallStreamingCards must be alternated left/right:
function isOddNumber(index) {
  return index % 2 !== 0;
}

// SmallStreamingCard(movie, index):
// ---
// if align = left, renderImage(movie.image)
// Title
// AvailabilitiesRow
// Rating/Genre
// if align = right, renderImage(movie.image)
const SmallStreamingCard = props => {
  const { movie, index } = props;
  const gwiApi = new GowatchitAPI();
  const [availabilities, setAvailabilities] = useState({});

  // MOBILE ONLY: Determine alignment of card's photo:
  // align defaults to 'left':
  let align = 'left';
  if (index && isOddNumber(index)) {
    align = 'right';
  }

  useEffect(() => {
    const tmsId = [];
    tmsId.push(movie._tms_id);

    gwiApi
      .fetchGowatchitMovieIds(tmsId)
      .then(response => response.gowatchitMovieIds[0].id)
      .then(response => gwiApi.fetchMovieAvailabilities(response))
      .then(response => {
        const { availabilities } = response;
        setAvailabilities(availabilities);
      });
  }, [gwiApi, movie._tms_id]);

  const titleEllipsis = text => {
    const maxLength = 33;
    if (text.length > maxLength) {
      return `${text.substr(0, maxLength - 1)}...`;
    }
    return text;
  };

  return (
    <div className={`small-streaming-card ${align}-aligned`}>
      <a href={buildNNoovieLink(movie)}>
        {align === 'left' && renderImage(movie)}
      </a>

      <div className="small-streaming-card-details-container-wrapper">
        <div className="small-streaming-card-details-container">
          {/* 375px: */}
          {/* ----------------------------- */}
          <Responsive maxWidth={tabletBreakpoint - 1}>
            <a href={buildNNoovieLink(movie)}>
              <div className="small-streaming-card-title">
                {titleEllipsis(movie.title)}
              </div>
            </a>
            {availabilities && availabilities.length > 0 && (
              <AvailabilitiesRow
                movie={movie}
                availabilities={availabilities}
                displayNumber={2}
              />
            )}
          </Responsive>

          {/* 720px: */}
          {/* On 720px ONLY, AvailabilitiesRow is displayed ABOVE movie title, genre, ... */}
          <Responsive
            minWidth={tabletBreakpoint}
            maxWidth={bigTabletBreakpoint - 1}
          >
            {availabilities && availabilities.length > 0 && (
              <AvailabilitiesRow
                movie={movie}
                availabilities={availabilities}
                displayNumber={2}
              />
            )}

            <a href={buildNNoovieLink(movie)}>
              <div className="small-streaming-card-title">{movie.title}</div>
            </a>
          </Responsive>

          {/* 1024px: */}
          {/* On 1024px ONLY, the SVGs in the AvailabilitiesRow */}
          {/* have a height of 25px. Set 'isBigTabletSPR' to true: */}
          <Responsive
            minWidth={bigTabletBreakpoint}
            maxWidth={desktopBreakpoint - 1}
          >
            <a href={buildNNoovieLink(movie)}>
              <div className="small-streaming-card-title">
                {titleEllipsis(movie.title)}
              </div>
            </a>
            {availabilities && availabilities.length > 0 && (
              <AvailabilitiesRow
                movie={movie}
                availabilities={availabilities}
                displayNumber={2}
                isBigTabletSPR
                svgHeight={25}
              />
            )}
          </Responsive>

          {/* 1440px+ : */}
          {/* ----------------------------- */}
          <Responsive minWidth={desktopBreakpoint}>
            <a href={buildNNoovieLink(movie)}>
              <div className="small-streaming-card-title">
                {titleEllipsis(movie.title)}
              </div>
            </a>
            {availabilities && availabilities.length > 0 && (
              <AvailabilitiesRow
                movie={movie}
                availabilities={availabilities}
                displayNumber={2}
              />
            )}
          </Responsive>

          <div className="small-streaming-card-rating-genre">
            {movie.pgRating}
            {movie.pgRating && movie.genre && (
              <span className="streaming-poster-rating-genre-dot">·</span>
            )}
            {movie.genre}
          </div>
        </div>
      </div>
      <a href={buildNNoovieLink(movie)}>
        {align === 'right' && renderImage(movie)}
      </a>
    </div>
  );
};

SmallStreamingCard.propTypes = {
  movie: PropTypes.instanceOf(Movie).isRequired,
  index: PropTypes.number, // used to determine odd/even card 'flipping'
};

SmallStreamingCard.defaultProps = {
  index: 0,
};

export default SmallStreamingCard;
