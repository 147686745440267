import Movie from './Movie';

export default class Contribution {
  constructor(options) {
    this._id = options.id;
    this._role = options.role;
    this._character_name = options.character_name;
    this._movie = new Movie(options.movie);
  }

  get id() {
    return this._id;
  }

  get role() {
    return this._role;
  }

  get characterName() {
    return this._character_name;
  }

  get movie() {
    return this._movie;
  }
}
