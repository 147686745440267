export default class ZipcodeResponse {
  constructor(response) {
    if (response.geo) {
      this._latitude = response.geo.lat;
      this._longitude = response.geo.lng;
      this._zipcode = response.geo.zipcode;
    }
  }

  get latitude() {
    return this._latitude;
  }

  get longitude() {
    return this._longitude;
  }

  get zipcode() {
    return this._zipcode;
  }
}
