import React from 'react';
import UrlParse from 'url-parse';
import NoovieAPI from '../models/api/NoovieAPI';
import config from '../config/env';

const Redirect = () => {
  const url = new UrlParse(window.location);
  const api = new NoovieAPI();

  const redirect = async () => {
    let newLocation = '/';
    if (url.pathname && url.pathname.startsWith('/')) {
      const path = url.pathname.substr(1);
      try {
        const redirectResponse = await api.getUrlRedirect(path);

        if (redirectResponse.url) {
          newLocation = redirectResponse.url.url;
        }
      } catch {
        newLocation = `${config.nNoovie}/${path}`;
      }
    }

    window.location = newLocation;
  };

  redirect();

  return <div />;
};

export default Redirect;
