import Movie from '../Movie';
import Theater from '../Theater';

export default class MovieDetailResponse {
  constructor(response) {
    this._movie = new Movie(response.movie);
    this._available_dates = response.available_dates;

    if (response.theaters && response.theaters.length > 0) {
      this._theaters = response.theaters.map(theater => new Theater(theater));
    } else {
      this._theaters = [];
    }
  }

  get movie() {
    return this._movie;
  }

  get availableDates() {
    return this._available_dates;
  }

  get theaters() {
    return this._theaters;
  }
}
