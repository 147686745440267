import React from 'react';
import { Responsive } from 'semantic-ui-react';

import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';

import AdContainer, { top } from '../AdContainer';

export default function NavbarAd() {
  return (
    <Responsive minWidth={bigTabletBreakpoint}>
      <AdContainer
        id="navbar-ad-slot"
        className="ad-centered ad-navbar"
        unit="noovie.com"
        width={728}
        height={90}
        position={top}
        sizeMapping={[
          {
            browser: [990, 0],
            slot: [728, 90],
          },
        ]}
      />
    </Responsive>
  );
}
