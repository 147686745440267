import List from './List';
import WeeklyBoxOfficeItem from './WeeklyBoxOfficeItem';

export default class WeeklyBoxOffice {
  constructor(options = {}) {
    this._list = new List(options.list);
    this._weekly_box_office = [];
    if (options.weekly_box_office) {
      this._weekly_box_office = options.weekly_box_office.map(boxOffice => {
        const filteredItems = options.list.items.filter(
          item => item.listable_id === boxOffice.movie_id
        );

        return new WeeklyBoxOfficeItem({ ...filteredItems[0], ...boxOffice });
      });
    }
  }

  get list() {
    return this._list;
  }

  get weeklyBoxOffice() {
    return this._weekly_box_office;
  }
}
