import CarouselItemButton from './CarouselItemButton';
import Video from './Video';

export default class CarouselItem {
  constructor(options = {}) {
    this._id = options.id;
    this._carousel_id = options.carousel_id;
    this._watchable_id = options.watchable_id;
    this._title = options.title;
    this._title_url = options.title_url;
    this._description = options.description;
    this._background_image_url = options.background_image_url;
    this._call_to_action_text = options.call_to_action_text;
    this._url = options.url;
    this._video_id = options.video_id;
    this._video = new Video(options.video);
    this._position = options.position;

    if (
      options.carousel_item_buttons &&
      options.carousel_item_buttons.length > 0
    ) {
      this._carousel_item_buttons = options.carousel_item_buttons.map(
        button => new CarouselItemButton(button)
      );
    } else {
      this._carousel_item_buttons = [];
    }
  }

  get id() {
    return this._id;
  }

  get callToActionText() {
    return this._call_to_action_text;
  }

  get url() {
    return this._url;
  }

  get carouselId() {
    return this._carousel_id;
  }

  get watchableId() {
    return this._watchable_id;
  }

  get title() {
    return this._title;
  }

  get titleUrl() {
    return this._title_url;
  }

  get description() {
    return this._description;
  }

  get backgroundImageUrl() {
    return this._background_image_url;
  }

  get videoId() {
    return this._video_id;
  }

  get video() {
    return this._video;
  }

  get position() {
    return this._position;
  }

  get carouselItemButtons() {
    return this._carousel_item_buttons;
  }
}
