import LoginUser from '../LoginUser';

export default class UserMeResponse {
  constructor(response) {
    if (response.loginUser) {
      this._loginUser = new LoginUser(response.loginUser);
    }
  }

  get loginUser() {
    return this._loginUser;
  }
}
