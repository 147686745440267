import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
  Fragment,
} from 'react';
import { Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { buildNNoovieLink } from '../utils/nooviePath';
import {
  tabletBreakpoint,
  bigTabletBreakpoint,
} from '../utils/responsive-breakpoints';
import { PROVIDER_FORMAT_NAME_WHITELIST, PG_RATINGS } from '../utils/constants';
import NoovieAPI from '../models/api/NoovieAPI';
import SlugService from '../services/SlugService';
import MovieAvailabilitiesService from '../services/MovieAvailabilitiesService';
import TrackingService from '../services/tracking_service';

import FiltersContainer from '../components/streaming/FiltersContainer';
import NavbarContext from '../components/navigation/navbar-context';
import AdContainer, { mid1, bottom } from '../components/AdContainer';

const MEDIUM_CONTEXT = 'secondary_streaming_page';
const defaultTrackingOptions = {
  mediumContext: MEDIUM_CONTEXT,
};

const mobileAdIndex = 6;
const tabletAdIndex = 8;

const MovieGrid = props => {
  const [movies, setMovies] = useState([]);
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [filters, setFilters] = useState({
    platforms: [],
    genres: [],
    years: [],
    ratings: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    platforms: [],
    genres: [],
    years: [],
    ratings: [],
  });
  const [movieAvailabilities, setMovieAvailabilities] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const listName = useRef('');

  const { setDarkTheme } = useContext(NavbarContext);

  const api = new NoovieAPI();

  const trackListImpression = listId => {
    TrackingService.trackListImpression(listId, defaultTrackingOptions);
  };

  useEffect(() => {
    const initialLoad = async () => {
      setLoaded(true);
      setDarkTheme(false);

      const slug = props.match.params.id;
      const id = SlugService.parseIdFromSlug(slug);
      const params = { limit: 999 };
      const { list } = await api.getList(id, params);

      trackListImpression(id);

      listName.current = list.name;

      setMovies(list.items);
    };

    if (!loaded) {
      initialLoad();
    }
  }, [loaded, api, setDarkTheme, props]);

  useEffect(() => {
    if (movies.length) {
      const getMovieAvailabilities = async () => {
        const movieAvailabilities = await MovieAvailabilitiesService.getAvailabilitiesForMovies(
          movies
        );
        setMovieAvailabilities(movieAvailabilities);
      };

      getMovieAvailabilities();
    }
  }, [movies]);

  useEffect(() => {
    const getFilterOptions = () => {
      // Platform
      const availablePlatforms = [];
      for (const provider in PROVIDER_FORMAT_NAME_WHITELIST) {
        const formattedProviderName = PROVIDER_FORMAT_NAME_WHITELIST[provider];

        for (const movieAvailability of movieAvailabilities) {
          const isPlatformAvailable = movieAvailability.availabilities.some(
            availability =>
              availability._provider_format_name === formattedProviderName
          );
          if (isPlatformAvailable) {
            availablePlatforms.push(formattedProviderName);
            break;
          }
        }
      }

      // Genre
      const availableGenres = [];
      for (const movie of movies) {
        if (!availableGenres.includes(movie.genre)) {
          availableGenres.push(movie.genre);
        }
      }
      availableGenres.sort();

      // Year
      const availableYears = [];
      for (const movie of movies) {
        if (!availableYears.includes(movie.year)) {
          availableYears.push(movie.year);
        }
      }
      availableYears.sort();

      // Rating
      let availableRatings = [];
      for (const movie of movies) {
        if (!availableRatings.includes(movie.pgRating)) {
          availableRatings.push(movie.pgRating);
        }
      }
      availableRatings = PG_RATINGS.filter(pgRating =>
        availableRatings.includes(pgRating)
      );

      const newFilterOptions = {
        platforms: availablePlatforms,
        genres: availableGenres,
        years: availableYears,
        ratings: availableRatings,
      };
      setFilterOptions(newFilterOptions);
    };

    getFilterOptions();
  }, [movieAvailabilities, movies]);

  const applyFilters = (selectedFilters, filterType) => {
    const newFilters = {
      ...filters,
      [filterType]: selectedFilters,
    };
    setFilters(newFilters);
  };

  const bulkApplyFilters = selectedFilters => {
    setFilters(selectedFilters);
  };

  const shouldFilter = useCallback(() => {
    for (const filter in filters) {
      if (filters[filter].length) return true;
    }
    return false;
  }, [filters]);

  useEffect(() => {
    const filterMovies = () => {
      const newFilteredMovies = [];

      for (const movie of movies) {
        let shouldIncludeMovie = false;

        // Platform
        if (filters.platforms.length) {
          const availabilitiesForMovie = movieAvailabilities.find(
            movieAvailability => movieAvailability.tmsId === movie.tmsId
          );
          if (availabilitiesForMovie) {
            for (const availability of availabilitiesForMovie.availabilities) {
              shouldIncludeMovie = filters.platforms.includes(
                availability._provider_format_name
              );
              if (shouldIncludeMovie) break;
            }
          }
          if (!shouldIncludeMovie) continue;
        }

        // Genre
        if (filters.genres.length) {
          shouldIncludeMovie = filters.genres.includes(movie.genre);
          if (!shouldIncludeMovie) continue;
        }

        // Year
        if (filters.years.length) {
          const minYear = filters.years[0];
          const maxYear = filters.years[1];
          shouldIncludeMovie = movie.year >= minYear && movie.year <= maxYear;
          if (!shouldIncludeMovie) continue;
        }

        // Rating
        if (filters.ratings.length) {
          shouldIncludeMovie = filters.ratings.includes(movie.pgRating);
          if (!shouldIncludeMovie) continue;
        }

        if (shouldIncludeMovie) {
          newFilteredMovies.push(movie);
        }
      }

      setFilteredMovies(newFilteredMovies);
    };

    if (shouldFilter()) {
      filterMovies();
    } else {
      setFilteredMovies([]);
    }
  }, [filters, shouldFilter, movies, movieAvailabilities]);

  useEffect(() => {
    const trackPageView = () => {
      TrackingService.trackPageView(MEDIUM_CONTEXT);
    };

    trackPageView();
  }, []);

  const renderMiddleAd = () => (
    <AdContainer
      id="streaming-grid-ad-middle"
      className="ad-centered"
      unit="noovie.com/streaming"
      position={mid1}
      sizeMapping={[
        {
          browser: [0, 0],
          slot: [300, 250],
        },
      ]}
    />
  );

  const renderMovie = (movie, index) => (
    <Fragment key={movie.id}>
      {index === tabletAdIndex && (
        <Responsive
          className="middle-ad"
          minWidth={tabletBreakpoint}
          maxWidth={bigTabletBreakpoint - 1}
        >
          {renderMiddleAd()}
        </Responsive>
      )}
      {index === mobileAdIndex && (
        <Responsive className="middle-ad" maxWidth={tabletBreakpoint - 1}>
          {renderMiddleAd()}
        </Responsive>
      )}

      <a key={movie.id} className="movie-link" href={buildNNoovieLink(movie)}>
        <div
          className="movie-poster"
          style={{ backgroundImage: `url("${movie.posterUrl}")` }}
        />

        <div className="movie-title">{movie.title}</div>
        <div className="movie-details">
          {movie.pgRating}
          {movie.pgRating && movie.genre && (
            <span className="streaming-poster-rating-genre-dot">
              &nbsp;•&nbsp;
            </span>
          )}
          {movie.genre}
        </div>
      </a>
    </Fragment>
  );

  return (
    <div className="streaming-grid-container">
      <div className="header-container">
        <header>{listName.current}</header>

        <FiltersContainer
          filters={filters}
          filterOptions={filterOptions}
          applyFilters={applyFilters}
          bulkApplyFilters={bulkApplyFilters}
        />
      </div>

      <div className="movies-container">
        {shouldFilter()
          ? filteredMovies.map(renderMovie)
          : movies.map(renderMovie)}
      </div>

      <AdContainer
        id="streaming-grid-ad-bottom"
        className="ad-centered"
        unit="noovie.com/streaming"
        position={bottom}
        sizeMapping={[
          {
            browser: [990, 0],
            slot: [728, 90],
          },
          {
            browser: [0, 0],
            slot: [300, 250],
          },
        ]}
      />
    </div>
  );
};

MovieGrid.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default MovieGrid;
