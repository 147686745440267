import ProviderSVG from './provider-svg';

export const ITUNES_APP_STORE_ARCADE_URL =
  'https://itunes.apple.com/us/app/itunes-connect/id1312862659?mt=8';

export const ANDROID_APP_STORE_ARCADE_URL =
  'https://play.google.com/store/apps/details?id=com.ncm.nooviear';

export const NOOVIE_TWITTER_HANDLE = '@WhatsNoovie';

export const BOX_OFFICE_LIVE_LIST_ID = 835;

export const FANTASY_MOVIE_LEAGUE_URL = 'https://fantasymovieleague.com/';

export const HOME_CAROUSEL_NAME = 'home';

export const TRAILER_BUTTON_NAME = 'trailer';

export const TRAILERS_CAROUSEL_NAME = 'trailers';

export const SHOWTIMES_BUTTON_NAME = 'showtimes';

// Provider format names should be ordered in the desired sort order.
export const PROVIDER_FORMAT_NAME_WHITELIST = {
  NETFLIX: 'Netflix Instant',
  HULU: 'Hulu (subscription)',
  PRIME_VIDEO_SUBSCRIPTION: 'Prime Video (subscription)',
  HBO_GO: 'HBO GO',
  PRIME_VIDEO: 'Prime Video',
  ITUNES: 'iTunes',
  GOOGLE_PLAY: 'Google Play',
  YOUTUBE: 'YouTube',
  VUDU: 'VUDU',
  SHOWTIME_ANYTIME: 'Showtime Anytime',
  SHOWTIME_STREAMING: 'Showtime Streaming',
  STARZ: 'Starz',
  MUBI: 'Mubi',
  TUBI_TV: 'Tubi TV',
  CRACKLE: 'Crackle',
  FANDOR: 'Fandor',
};

export const PROVIDER_FORMAT_LOGOS = {
  'Netflix Instant': ProviderSVG.netflix(),
  'Hulu (subscription)': ProviderSVG.hulu(),
  'Prime Video (subscription)': ProviderSVG.primeVideo(),
  'HBO GO': ProviderSVG.hboGo(),
  iTunes: ProviderSVG.iTunes(),
  'Google Play': ProviderSVG.googlePlay(),
  YouTube: ProviderSVG.youTube(),
  VUDU: ProviderSVG.vudu(),
  'Showtime Anytime': ProviderSVG.showtimeAnytime(),
  'Showtime Streaming': ProviderSVG.showtime(),
  Starz: ProviderSVG.starz(),
  Mubi: ProviderSVG.mubi(),
  'Tubi TV': ProviderSVG.tubiTV(),
  Crackle: ProviderSVG.crackle(),
  Fandor: ProviderSVG.fandor(),
};

// PG ratings should be ordered in the desired sort order.
export const PG_RATINGS = ['G', 'PG', 'PG-13', 'R'];

export const FILTER_TYPES = {
  PLATFORM: 'platforms',
  GENRE: 'genres',
  YEAR: 'years',
  RATING: 'ratings',
};

export const NO_POSTER_URL =
  'https://static-assets.noovie.com/images/no-poster.png';

export const NO_IMAGE_URL =
  'https://static-assets.noovie.com/images/no-image.png';

export const DFP_NETWORK_CODE = 21631355527;

export const QUERY_PARAM_WHITELIST = ['gwi_', 'utm_'];

export const NCM_VIDEO_EXPERIENCE_BRICK_TYPE = 'video-experience';

export const NCM_PHOTO_EXPERIENCE_BRICK_TYPE = 'photo-experience';
