import Showing from './Showing';

export default class Showtime {
  constructor(options) {
    this._experiences = options.experiences;
    this._screen_type = options.screen_type;
    this._amenities = options.amenities;

    if (options.showings && options.showings.length > 0) {
      this._showings = options.showings.map(showing => new Showing(showing));
    } else {
      this._showings = [];
    }
  }

  get experiences() {
    return this._experiences;
  }

  get screenType() {
    return this._screen_type;
  }

  get amenities() {
    return this._amenities;
  }

  get showings() {
    return this._showings;
  }
}
