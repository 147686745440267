import Story from '../Story';

export default class StoryResponse {
  constructor(response) {
    this._story = new Story(response);
  }

  get story() {
    return this._story;
  }
}
