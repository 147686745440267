import Movie from './Movie';

export default class Theater {
  constructor(options) {
    this._id = options.id;
    this._name = options.name;
    this._address = options.address;
    this._city = options.city;
    this._state = options.state;
    this._zipcode = options.zipcode;
    this._distance = options.distance;
    this._lat = options.lat;
    this._lng = options.lng;
    this._time_zone = options.time_zone;
    this._amenities = options.amenities;
    this._theater_url = options.theater_url;
    this._available_dates = options.available_dates;
    this._favorite = options.favorite;
    this._has_noovie_pre_show = options.has_noovie_pre_show;
    this._time_zone = options.time_zone;
    this._url = options.url;

    if (options.movies && options.movies.length > 0) {
      this._movies = options.movies.map(movie => new Movie(movie));
    } else {
      this._movies = [];
    }
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get address() {
    return this._address;
  }

  get city() {
    return this._city;
  }

  get state() {
    return this._state;
  }

  get zipcode() {
    return this._zipcode;
  }

  get distance() {
    return this._distance;
  }

  get lat() {
    return this._lat;
  }

  get lng() {
    return this._lng;
  }

  get timeZone() {
    return this._time_zone;
  }

  get amenities() {
    return this._amenities;
  }

  get theaterUrl() {
    return this._theater_url;
  }

  get isFavorite() {
    return this._favorite;
  }

  get hasNooviePreShow() {
    return this._has_noovie_pre_show;
  }

  get movies() {
    return this._movies;
  }

  get url() {
    return this._url;
  }
}
