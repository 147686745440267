import * as R from 'ramda';
import moment from 'moment';

const DEFAULT_CACHED_DAYS = 1;
const coordinates = 'coordinates';
const address = 'address';

const LocalStore = () => {
  const getStoredCoordinates = () =>
    JSON.parse(localStorage.getItem(coordinates));

  const setStoredCoordinates = newCoordinates => {
    localStorage.setItem(coordinates, JSON.stringify(newCoordinates));
  };

  const removeStoredCoordinates = () => {
    localStorage.removeItem(coordinates);
  };

  const getStoredAddress = () => JSON.parse(localStorage.getItem(address));

  const setStoredAddress = newAddress => {
    localStorage.setItem(address, JSON.stringify(newAddress));
  };

  const removeStoredAddress = () => {
    localStorage.removeItem(address);
  };

  const cacheExpireKey = key => `${key}_EXPIRES_AT`;

  const cachedSet = (
    key,
    val,
    expiresAt = moment().add(DEFAULT_CACHED_DAYS, 'days')
  ) => {
    if (R.isNil(val)) {
      return undefined;
    }
    localStorage.setItem(key, JSON.stringify(val));
    localStorage.setItem(
      cacheExpireKey(key),
      JSON.stringify(expiresAt.valueOf())
    );
  };

  const cachedGet = key => {
    const rawVal = localStorage.getItem(key);
    if (R.isNil(rawVal)) {
      return undefined;
    }

    const val = JSON.parse(rawVal);
    const rawExpiresAt = localStorage.getItem(cacheExpireKey(key));
    if (!rawExpiresAt) {
      return val;
    }

    const expiresAt = moment(JSON.parse(rawExpiresAt));
    const now = moment();

    // This "lazily" expires the cache -- it leaves old values in the cache
    // if they've already expired and just returns undefined
    if (expiresAt.isBefore(now)) {
      return undefined;
    }
    return val;
  };

  return {
    getStoredCoordinates,
    setStoredCoordinates,
    removeStoredCoordinates,
    getStoredAddress,
    setStoredAddress,
    removeStoredAddress,
    cachedGet,
    cachedSet,
  };
};

export default LocalStore();
