export default class Image {
  constructor(options) {
    this._id = options.id;
    this._image_type = options.image_type;
    this._caption = options.caption;
    this._url = options.url;
    this._position = options.position;
  }

  get id() {
    return this._id;
  }

  get imageType() {
    return this._image_type;
  }

  get caption() {
    return this._caption;
  }

  get url() {
    return this._url;
  }

  get position() {
    return this._position;
  }
}
