import React from 'react';
import SVG from '../utils/svg';
import { buildNNoovieLink } from '../utils/nooviePath';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <section className="footer-container">
    <div className="social-links-container">
      <div className="icon-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://facebook.com/WhatsNoovie"
        >
          {SVG.facebookFooterIcon(25, '#ffffff')}
        </a>
      </div>

      <div className="icon-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/WhatsNoovie"
        >
          {SVG.twitterFooterIcon(22, '#ffffff')}
        </a>
      </div>

      <div className="icon-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/WhatsNoovie/"
        >
          {SVG.instagramFooterIcon(27, '#ffffff')}
        </a>
      </div>
    </div>

    <div className="info-links-container">
      <div className="link-container">
        <a href={buildNNoovieLink('about_noovie')}>About</a>
      </div>
      <div className="link-container">
        <a href="https://www.ncm.com/careers">Careers</a>
      </div>
      <div className="link-container">
        <a href="https://www.ncm.com/contact-us">Advertise</a>
      </div>
    </div>

    <div className="footnote-container">
      <div className="footnote-links-container">
        <a
          href="https://sso.noovie.com/documents/terms-of-service"
          target="blank"
        >
          Terms of Use
        </a>
        <span className="dot-separator">&#8226;</span>
        <a
          href="https://sso.noovie.com/documents/privacy-policy"
          target="blank"
        >
          Privacy Policy
        </a>
        <div className="footer-ccpa">
          <span className="dot-separator">&#8226;</span>
          <a href="https://ccpa.noovie.com" target="blank">
            California Privacy Rights
          </a>
        </div>
      </div>
      <div className="copyright-container">
        &copy;Copyright {currentYear} National CineMedia, LLC.{' '}
        <span className="all-rights">All Rights Reserved</span>
      </div>
    </div>

    <img
      height="0"
      width="0"
      src="https://loadus.exelator.com/load/?p=730&g=109&j=0"
      alt="eXelate Tag"
      tabIndex="-1"
      aria-hidden="true"
      hidden
    />
  </section>
);

export default Footer;
