import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import NavbarProvider from './components/navigation/navbar-provider';
import VideoDetail from './pages/VideoDetail';
import Redirect from './pages/Redirect';

const App = () => (
  <NavbarProvider>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={MainRoutes} />
        <Route exact path="/index.html" component={MainRoutes} />
        <Route exact path="/in-theaters" component={MainRoutes} />
        <Route path="/in-theaters/:id" component={MainRoutes} />
        <Route path="/streaming" component={MainRoutes} />
        <Route path="/streaming/:id" component={MainRoutes} />
        <Route path="/videos/:videoId" component={VideoDetail} />
        <Route path="/:slug" component={Redirect} />
      </Switch>
    </BrowserRouter>
  </NavbarProvider>
);

export default App;
