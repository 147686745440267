export default class Owner {
  constructor(options = {}) {
    this._id = options.id;
    this._screen_name = options.screen_name;
  }

  get id() {
    return this._id;
  }

  get screenName() {
    return this._screen_name;
  }
}
