import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';

import ProviderSVG from '../../utils/provider-svg';
import { PROVIDER_FORMAT_NAME_WHITELIST } from '../../utils/constants';
import PrimeVideoPNG from './prime-video-rent-own116x60.png';
import {
  tabletBreakpoint,
  desktopBreakpoint,
} from '../../utils/responsive-breakpoints';
import TrackingService from '../../services/tracking_service';

const MEDIUM_CONTEXT = 'streaming_page';
const defaultTrackingOptions = {
  mediumContext: MEDIUM_CONTEXT,
};

const AvailabilitiesRow = props => {
  const {
    availabilities,
    displayNumber,
    svgHeight,
    isBigTabletSPR, // needed for .png sizing
  } = props;
  const [filteredProviders, setFilteredProviders] = useState();

  // arrangeProviders(arrayOfProviderObjects)
  // Takes in the [Array] of available provider {Objects}
  // and orders them as specified by the product team:
  const arrangeProviders = arrayOfProviderObjects => {
    const sorted = [];
    const providerNames = Object.values(PROVIDER_FORMAT_NAME_WHITELIST);
    if (arrayOfProviderObjects) {
      for (let x = 0; x < providerNames.length; x++) {
        for (let y = 0; y < arrayOfProviderObjects.length; y++) {
          if (
            providerNames[x] === arrayOfProviderObjects[y]._provider_format_name
          ) {
            sorted.push(arrayOfProviderObjects[y]);
          }
        }
      }
    }
    return sorted;
  };

  // checkIfInWhiteList(arrayOfProviderObjects, arrayOfProviderNames)
  // arrayOfProviderObjects: providers received from props.availabilities
  // arrayOfProviderNames: array of whitelisted provider names to check against
  const checkIfInWhitelist = (arrayOfProviderObjects, arrayOfProviderNames) => {
    const filteredOutProviders = [];

    if (Array.isArray(arrayOfProviderObjects)) {
      const arrayOfNamesFromProps = [];
      arrayOfProviderObjects.forEach(provObj => {
        arrayOfNamesFromProps.push(provObj.providerFormatName);
      });
      arrayOfNamesFromProps.forEach((providerName, index) => {
        if (arrayOfProviderNames.includes(providerName)) {
          filteredOutProviders.push(arrayOfProviderObjects[index]);
        }
      });

      return filteredOutProviders;
    }
    return null;
  };

  const handleProviderClick = providerFormatName => () => {
    const { movie } = props;
    TrackingService.trackProviderClick(
      movie.type,
      movie.id,
      providerFormatName,
      defaultTrackingOptions
    );
  };

  const renderStreamingTile = (availability, providerTileHeight, isSmall) => {
    switch (availability.providerFormatName) {
      case 'Prime Video': // the only .png; must render <div> with background image:
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div
              className={`streaming-tile prime-png ${isSmall ? 'small' : ''}`}
              style={{ backgroundImage: `url("${PrimeVideoPNG}")` }}
            />
          </a>
        );
      case 'Prime Video (subscription)': // all remaining tiles have SVGs:
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.primeVideo(providerTileHeight)}
            </div>
          </a>
        );
      case 'VUDU':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.vudu(providerTileHeight)}
            </div>
          </a>
        );
      case 'Tubi TV':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.tubiTV(providerTileHeight)}
            </div>
          </a>
        );
      case 'Netflix Instant':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.netflix(providerTileHeight)}
            </div>
          </a>
        );
      case 'Hulu (subscription)':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.hulu(providerTileHeight)}
            </div>
          </a>
        );
      case 'iTunes':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.iTunes(providerTileHeight)}
            </div>
          </a>
        );
      case 'HBO GO':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.hboGo(providerTileHeight)}
            </div>
          </a>
        );
      case 'YouTube':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.youTube(providerTileHeight)}
            </div>
          </a>
        );
      case 'Google Play':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.googlePlay(providerTileHeight)}
            </div>
          </a>
        );
      case 'Showtime Anytime':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.showtimeAnytime(providerTileHeight)}
            </div>
          </a>
        );
      case 'Showtime Streaming':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.showtime(providerTileHeight)}
            </div>
          </a>
        );
      case 'Starz':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.starz(providerTileHeight)}
            </div>
          </a>
        );
      case 'Mubi':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.mubi(providerTileHeight)}
            </div>
          </a>
        );
      case 'Crackle':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.crackle(providerTileHeight)}
            </div>
          </a>
        );
      case 'Fandor':
        return (
          <a
            key={availability.providerFormatName}
            href={availability._watch_now_url}
            onClick={handleProviderClick(availability.providerFormatName)}
            target="blank"
          >
            <div className="streaming-tile">
              {ProviderSVG.fandor(providerTileHeight)}
            </div>
          </a>
        );
      default:
        return null;
    }
  };

  // calculateRemainingTiles(listLength, tilesDisplayed)
  // This calculates the "+4", etc., number that appears on truncated
  // AvailabilitiesRows. It takes in the total length of
  // white-listed providers for that movie, and subtracts from it the displayNumber:
  const calculateRemainingTiles = (listLength, tilesDisplayed) => (
    <p>{`+${listLength - tilesDisplayed}`}</p>
  );
  // Filter out unwanted Providers in response:
  useEffect(() => {
    if (availabilities) {
      const arrayOfProviderNames = Object.values(
        PROVIDER_FORMAT_NAME_WHITELIST
      );
      let providersResponse = checkIfInWhitelist(
        availabilities,
        arrayOfProviderNames
      );
      providersResponse = arrangeProviders(providersResponse);
      setFilteredProviders(providersResponse);
    }
  }, [availabilities]);

  return (
    <Fragment>
      {/* 375px mobileBreakpoint */}
      {/* ---------------------------------------------------------------- */}
      {availabilities && Array.isArray(availabilities) && filteredProviders && (
        <Responsive
          as="div"
          className="availabilities-row"
          maxWidth={tabletBreakpoint - 1}
        >
          {filteredProviders
            .slice(0, displayNumber)
            .map(availability => renderStreamingTile(availability, svgHeight))}
          {filteredProviders.length !== 0 &&
          filteredProviders.length - displayNumber > 0
            ? calculateRemainingTiles(filteredProviders.length, displayNumber)
            : null}
        </Responsive>
      )}

      {/* 720px & 1024px breakpoints */}
      {/* ---------------------------------------------------------------- */}
      {availabilities && Array.isArray(availabilities) && filteredProviders && (
        <Responsive
          as="div"
          className="availabilities-row"
          minWidth={tabletBreakpoint}
          maxWidth={desktopBreakpoint - 1}
        >
          {filteredProviders
            .slice(0, displayNumber)
            .map(availability =>
              renderStreamingTile(availability, svgHeight, isBigTabletSPR)
            )}
          {filteredProviders.length !== 0 &&
          filteredProviders.length - displayNumber > 0
            ? calculateRemainingTiles(filteredProviders.length, displayNumber)
            : null}
        </Responsive>
      )}

      {/* 1440px+ breakpoint */}
      {/* ---------------------------------------------------------------- */}
      {availabilities && Array.isArray(availabilities) && filteredProviders && (
        <Responsive
          as="div"
          className="availabilities-row"
          minWidth={desktopBreakpoint}
        >
          {filteredProviders
            .slice(0, displayNumber)
            .map(availability => renderStreamingTile(availability, svgHeight))}
          {filteredProviders.length !== 0 &&
          filteredProviders.length - displayNumber > 0
            ? calculateRemainingTiles(filteredProviders.length, displayNumber)
            : null}
        </Responsive>
      )}
    </Fragment>
  );
};

AvailabilitiesRow.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
  movie: PropTypes.object.isRequired,
  displayNumber: PropTypes.number.isRequired,
  svgHeight: PropTypes.number, // Allows for custom SVG sizing on breakpoints
  isBigTabletSPR: PropTypes.bool, // 'Is this row a SmallPosterRow on BigTablet breakpoint?'
};

AvailabilitiesRow.defaultProps = {
  svgHeight: 30,
  isBigTabletSPR: false, // isBigTabletSPR is literally only needed to resize that one PNG...
};

export default AvailabilitiesRow;
