import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';

import CallToAction from './links/call-to-action';
import NoovieAPI from '../models/api/NoovieAPI';
import TrackingService from '../services/tracking_service';

function Carousel(props) {
  const defaultTrackingOptions = {
    mediumContext: props.mediumContext,
  };

  const trackCallToActionClick = carouselItem => {
    const contextType = 'carousel_item';
    const contextId = carouselItem.id;

    const options = {
      ...defaultTrackingOptions,
      customData: carouselItem.callToActionText,
    };

    TrackingService.trackCallToActionClick(contextType, contextId, options);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  const [data, setData] = useState({});

  const getInitialData = async () => {
    const api = new NoovieAPI();

    const carouselResponse = await api.getCarousel(`home`);

    setData(carouselResponse);
  };

  const renderCarouselVideoBackground = item => (
    <div className="bg-video-container">
      {item.video_id && (
        <video autoPlay muted loop id="myVideo" className="bg-cover">
          <source src={item.video_id} type="video/mp4" />
        </video>
      )}
    </div>
  );

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div
      className={`home-slideshow ${isMobile ? 'home-slideshow-mobile' : ''}`}
    >
      {data.carousel && (
        <Slider {...settings}>
          {data.carousel.carouselItems.map((item, i) => (
            <div className="slide" key={i}>
              <div
                className="slide-bg bg-cover"
                style={{
                  backgroundImage: `url(${item.backgroundImageUrl})`,
                }}
              >
                {renderCarouselVideoBackground(item)}
                <div className="bg-overlay">
                  <div className="carousel-slider-container">
                    <div className="slider-content">
                      <a
                        href={item.url}
                        onClick={() => trackCallToActionClick(item)}
                      >
                        <h1 className="title">{item.title}</h1>
                        <h2 className="subtitle">{item.annotation}</h2>
                        <p className="description">{item.description}</p>
                      </a>
                      {item.callToActionText && (
                        <CallToAction
                          handleClick={() => trackCallToActionClick(item)}
                          href={item.url}
                          callToAction={item.callToActionText}
                          fill="#ffffff"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}

Carousel.propTypes = {
  mediumContext: PropTypes.object,
};

export default Carousel;
