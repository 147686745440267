import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react';
import { Range } from 'rc-slider';

import { bigTabletBreakpoint } from '../../utils/responsive-breakpoints';
import { FILTER_TYPES } from '../../utils/constants';
import FiltersDropdowns from './FiltersDropdowns';
import FiltersModal from './FiltersModal';

const minYearSliderRange = 2;

const FiltersContainer = ({
  filters,
  filterOptions,
  applyFilters,
  bulkApplyFilters,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(filters);

  const isSelected = (filter, filterType) =>
    selectedFilters[filterType].includes(filter);

  const handleFilterSelect = (filter, filterType) => {
    const newSelectedFilters = { ...selectedFilters };

    if (filterType === FILTER_TYPES.YEAR) {
      const defaultMinYear = filterOptions.years[0];
      const defaultMaxYear =
        filterOptions.years[filterOptions.years.length - 1];
      const selectedMinYear = filter[0];
      const selectedMaxYear = filter[1];

      if (
        selectedMinYear === defaultMinYear &&
        selectedMaxYear === defaultMaxYear
      ) {
        newSelectedFilters.years = [];
      } else {
        newSelectedFilters.years = filter;
      }
    } else {
      if (!isSelected(filter, filterType)) {
        newSelectedFilters[filterType] = [
          ...selectedFilters[filterType],
          filter,
        ];
      } else {
        newSelectedFilters[filterType] = selectedFilters[filterType].filter(
          f => {
            return f !== filter;
          }
        );
      }
    }

    setSelectedFilters(newSelectedFilters);
  };

  const handleClose = setOpen => {
    setSelectedFilters(filters);

    setOpen(false);
  };

  const renderYearSlider = () => {
    const defaultMinYear = filterOptions.years[0];
    const defaultMaxYear = filterOptions.years[filterOptions.years.length - 1];

    let minYear = null;
    let maxYear = null;
    if (selectedFilters.years.length) {
      minYear = selectedFilters.years[0];
      maxYear = selectedFilters.years[1];
    } else {
      minYear = defaultMinYear;
      maxYear = defaultMaxYear;
    }

    if (defaultMaxYear - defaultMinYear > minYearSliderRange)
      return (
        <>
          <div className="year-range">
            {minYear} - {maxYear}
          </div>

          <Range
            className="year-slider"
            min={defaultMinYear}
            max={defaultMaxYear}
            // Handle being set with CSS
            handle={() => null}
            value={[minYear, maxYear]}
            onChange={e => handleFilterSelect(e, FILTER_TYPES.YEAR)}
            pushable={1}
          />
        </>
      );
    return null;
  };

  return (
    <>
      <Responsive minWidth={bigTabletBreakpoint}>
        <FiltersDropdowns
          filters={filters}
          filterOptions={filterOptions}
          applyFilters={applyFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          isSelected={isSelected}
          handleFilterSelect={handleFilterSelect}
          handleClose={handleClose}
          renderYearSlider={renderYearSlider}
        />
      </Responsive>

      <Responsive maxWidth={bigTabletBreakpoint - 1}>
        <FiltersModal
          filters={filters}
          filterOptions={filterOptions}
          bulkApplyFilters={bulkApplyFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          isSelected={isSelected}
          handleFilterSelect={handleFilterSelect}
          handleClose={handleClose}
          renderYearSlider={renderYearSlider}
        />
      </Responsive>
    </>
  );
};

FiltersContainer.propTypes = {
  filters: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  applyFilters: PropTypes.func.isRequired,
  bulkApplyFilters: PropTypes.func.isRequired,
};

export default FiltersContainer;
