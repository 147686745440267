export default class Availability {
  constructor(options) {
    this._provider_name = options.provider_name;
    this._provider_format_name = options.provider_format_name;
    this._provider_format_id = options.provider_format_id;
    this._watch_now_url = options.watch_now_url;
    this._presale = options.presale;
  }

  get providerName() {
    return this._provider_name;
  }

  get providerFormatName() {
    return this._provider_format_name;
  }

  get providerFormatId() {
    return this._provider_format_id;
  }

  get url() {
    return this._watch_now_url;
  }

  get preorder() {
    return this._presale;
  }
}
