export default class Amenity {
  constructor(options) {
    this._id = options.id;
    this._amenity_type = options.amenity_type;
    this._system_name = options.system_name;
    this._display_name = options.display_name;
    this._description = options.description;
    this._position = options.position;
  }

  get id() {
    return this._id;
  }

  get amenityType() {
    return this._amenity_type;
  }

  get systemName() {
    return this._system_name;
  }

  get displayName() {
    return this._display_name;
  }

  get description() {
    return this._description;
  }

  get position() {
    return this._position;
  }
}
