import React, { Fragment, useState, useContext } from 'react';
import { Input, Responsive, Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import SVG from '../../utils/svg';
import { tabletBreakpoint } from '../../utils/responsive-breakpoints';
import ENV from '../../config/env';
import NavbarContext from '../navigation/navbar-context';

const iconDark = '#000000';
const searchIconDefaultHeight = 36;
const searchIconMobileHeight = 24;
const searchIconDefaultColor = '#ffffff';
const searchIconMobileColor = '#ffffff';
const closeIconHeight = 21;
const closeIconDefaultColor = '#ffffff';

const SearchBar = ({ hideSearch, isSearching }) => {
  const [term, setTerm] = useState('');
  const { isDarkTheme } = useContext(NavbarContext);

  const handleKeyPress = event => {
    if (term && event.key === 'Enter') {
      window.location.href = `${ENV.nNoovie}/search?term=${term}`;
    }
  };

  const handleSearchChange = (event, { value: inputTerm }) => {
    setTerm(inputTerm);
  };

  return (
    <Fragment>
      <Responsive
        className="mobile-search-bar-container"
        maxWidth={tabletBreakpoint - 1}
      >
        <Modal
          className={
            isDarkTheme ? 'mobile-search-modal' : 'mobile-search-modal-light'
          }
          open={isSearching}
          basic
          dimmer="blurring"
          closeIcon={SVG.closeIcon(
            closeIconHeight,
            isDarkTheme ? closeIconDefaultColor : iconDark
          )}
          onClose={hideSearch}
        >
          <Modal.Content>
            <Input
              className="search-bar-input"
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              placeholder="Enter a Movie Title Here..."
              icon={
                <Icon className="search-icon">
                  {SVG.navbarSearchIcon(
                    searchIconMobileHeight,
                    isDarkTheme ? searchIconMobileColor : iconDark
                  )}
                </Icon>
              }
              iconPosition="left"
              value={decodeURIComponent(term)}
              autoFocus
            />
          </Modal.Content>
        </Modal>
      </Responsive>
      <Responsive
        className="default-search-bar-container"
        minWidth={tabletBreakpoint}
      >
        {SVG.navbarSearchIcon(
          searchIconDefaultHeight,
          isDarkTheme ? searchIconDefaultColor : iconDark
        )}
        <Input
          className="search-bar-input"
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          placeholder="Find a Movie..."
          value={decodeURIComponent(term)}
          autoFocus
        />
      </Responsive>
    </Fragment>
  );
};

SearchBar.propTypes = {
  hideSearch: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
};

export default SearchBar;
